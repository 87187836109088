import React from 'react';
import { Helmet } from "react-helmet-async";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { useGetData } from "../../axiosHook/useRequestData";
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import AddBoxRoundedIcon from '@mui/icons-material/AddBoxRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import Logo from "../../images/newsDefaultThumb.jpg";
import {
  Grid,
  Typography,
  CircularProgress,
  Button,
  Alert,
  IconButton
} from '@mui/material';
import {
  // AFFILIATE_LIST_TEST_DATA,
} from "../../test-data";
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import { useNavigate } from 'react-router-dom';

export default function AffiliateList(props) {
  const { helmetContext } = props;

  let navigate = useNavigate();

  const [data = {}, loading, { error }] = useGetData('/associacoes');

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  const title = "Núcleos Regionais";

  return (
    <>
      <Helmet>
        <title>{`${title} | ${helmetContext.title}`}</title>
        <meta name="description" content={title} />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
      > <Grid item key="main" xs={12}><Typography variant="h2">{title}</Typography></Grid>

        {data["hydra:member"]?.length > 0
          ? <>


            {data["hydra:member"].map(nucleo =>

              <Grid key={nucleo.id} item xs={12} md={4}>
                <Card
                  sx={{
                    maxWidth: '100%',
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    alt="Logotipo Núcleo"
                    image={nucleo.logotipoUrl ?? Logo}
                    sx={{
                      aspectRatio: "1/1",
                      objectFit: "contain"
                    }}
                  />
                  <CardContent>
                    <React.Fragment>
                      <Typography gutterBottom variant="h2" component="div">
                        {`${nucleo.sigla} - ${nucleo.nome}`}
                      </Typography>

                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        Presidente:
                      </Typography>
                      &nbsp;{nucleo.presidente}<br />
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        Email:
                      </Typography>
                      &nbsp;{nucleo.email}<br />
                      <Typography
                        component="span"
                        variant="body2"
                        color="textPrimary"
                      >
                        Telefone:
                      </Typography>
                      &nbsp;{nucleo.telefone}
                      <Typography
                        component="span"
                        variant="body1"
                      ><br /></Typography>
                      {(nucleo.facebook)
                        ? <IconButton aria-label="Facebook" size='small' onClick={() => window.open(nucleo.facebook, '_blank')}>
                          <FacebookRoundedIcon />
                        </IconButton>
                        : ""
                      }
                      {(nucleo.instagram)
                        ? <IconButton aria-label="Instagram" size='small' onClick={() => window.open(`https://instagram.com/${nucleo.instagram}`, '_blank')}>
                          <InstagramIcon />
                        </IconButton>
                        : ""
                      }
                    </React.Fragment>
                  </CardContent>
                  <CardActions
                    sx={{
                      mt: "auto",
                    }}
                  >
                    {nucleo.site && <Button variant='contained' color="primary" component='a' sx={{ mt: 2, mb: 2 }} startIcon={<AddBoxRoundedIcon />} href={nucleo.site} target="_blank">Sobre</Button>}&nbsp;
                    <Button variant='contained' color="primary" sx={{ mt: 2, mb: 2 }} startIcon={<EmojiEventsRoundedIcon />} onClick={() => navigate(`/eventos?campeonato=${nucleo.campeonato?.id}&ancr=false&futuros=false&page=1&nucleos=true&resultados=false&videos=false&fotos=false&order=desc`)}>Resultados</Button>
                  </CardActions>
                </Card>

              </Grid>

            )}

          </>
          : <CircularProgress />
        }

      </Grid>
    </>
  )

}