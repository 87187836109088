import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getIntParam,
} from '../functions';

const RedirectRankingTemporada = () => {
  let location = useLocation();
  const params = new URLSearchParams(location.search);

  const temporada = getIntParam(params, 'temporada');
  const competidor = getIntParam(params, 'competidor');
  const aberta = getIntParam(params, 'aberta');
  const animal = getIntParam(params, 'animal');
  const criador = getIntParam(params, 'criador');
  const pai = getIntParam(params, 'pai');
  const mae = getIntParam(params, 'mae');
  const proprietario = getIntParam(params, 'proprietario');
  const competidorprincipiante = getIntParam(params, 'competidorprincipiante');
  const acumulado = getIntParam(params, 'acumulado');

  const navigate = useNavigate();

  useEffect(() => {

    if (temporada) {
      navigate({ pathname: `/ranking?temporada=${temporada}&competidor=${competidor !== null ? competidor : ''}&aberta=${aberta !== null ? aberta : ''}&animal=${animal !== null ? animal : ''}&criador=${criador !== null ? criador : ''}&pai=${pai !== null ? pai : ''}&mae=${mae !== null ? mae : ''}&proprietario=${proprietario !== null ? proprietario : ''}&competidorprincipiante=${competidorprincipiante !== null ? competidorprincipiante : ''}&acumulado=${acumulado !== null ? acumulado : ''}` });
    } else {
      navigate({ pathname: `/ranking/` });
    }
  }, [navigate, temporada, competidor, aberta, animal, criador, pai, mae, proprietario, competidorprincipiante, acumulado])

  return (
    <Alert>Aguarde, estamos redirecionando</Alert>
  )
};
export default RedirectRankingTemporada;
