import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getIntParam,
} from '../functions';

const RedirectCampaign = (props) => {
  const { horse } = props;

  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const idAnimal = getIntParam(params, 'IDAnimal');
  const idAfiliado = getIntParam(params, 'IDAfiliado');

  const navigate = useNavigate();

  useEffect(() => {

    if (horse) {
      if (idAnimal) {
        navigate({ pathname: `/animais/${idAnimal}` });
      } else {
        navigate({ pathname: `/campanhas?animais=1` });
      }
    } else {
      if (idAfiliado) {
        navigate({ pathname: `/competidores/${idAfiliado}` });
      } else {
        navigate({ pathname: `/campanhas?animais=0` });
      }
    }
  }, [navigate, idAnimal, idAfiliado, horse])

  return (
    <Alert>Aguarde, estamos redirecionando</Alert>
  )
};
export default RedirectCampaign;
