import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { useGetData } from "../../axiosHook/useRequestData";
import { Grid, CircularProgress } from '@mui/material';
import {
  // EVENT_PHOTO_DATA,
} from '../../test-data';
import EventLinks from './EventLinks';
import EventHeader from './EventHeader';
import { Alert } from '@mui/material';
import MasonryImageList from './MasonryImageList';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const photoData = (photoData) => {
  if (!photoData?.["hydra:member"]) return [];

  return photoData["hydra:member"].map(data => {
    return {
      img: data.urlImagem,
      title: data.nome,
    };
  });
};

export default function EventPhotos(props) {
  const { helmetContext } = props;
  const params = useParams()
  const id = parseInt(params.id);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('md'));

  const [event = {}, loadingEvent, { error: eventError }] = useGetData(`eventos/${id}`);
  const [eventPhotos = {}, loadingEventPhotos, { error: eventPhotosError }] = useGetData(`eventos/fotos?idEvento=${id}`);

  if (!id || eventError?.response?.status === 404) return <Alert severity="error">Evento não localizado!</Alert>
  if (eventError) return <Alert severity="error">{`${eventError}`}</Alert>
  if (eventPhotosError) return <Alert severity="error">{`${eventPhotosError}`}</Alert>
  if (loadingEvent && loadingEventPhotos) return <CircularProgress />

  return (
    <>
      <Helmet>
        <title>{`${event?.nome} | ${helmetContext.title}`}</title>
        <meta name="description" content={event?.descricao} />
        <meta name="keywords" content="fotos" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 2 }}
      >
        <Grid item key="main" md={7}>
          {event.nome?.length > 0
            ? <>
              <EventHeader event={event} />
              {eventPhotos["hydra:member"]?.length > 0 ?
                <MasonryImageList
                  sm={sm}
                  itemData={photoData(eventPhotos)}
                />
                : <CircularProgress />
              }

            </>
            : <CircularProgress />
          }
        </Grid>
        <Grid item key="links" md={3} >

          <EventLinks event={event} withHeader={true} />

        </Grid>
      </Grid>
    </>
  )

}