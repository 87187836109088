import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { useGetData } from "../../axiosHook/useRequestData";
import { Grid, CircularProgress, IconButton, Link } from '@mui/material';
import {
  YOUTUBE_VIDEO_ADDRESS_ROOT,
} from '../../constants';
import EventLinks from './EventLinks';
import EventHeader from './EventHeader';
import { Alert } from '@mui/material';
import VideoDialog from '../VideoDialog';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import BasicDataTable from '../BasicDataTable';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const videoTableHeaders = [
  'Categoria',
  'Animal',
  'Competidor',
  '',
];

export default function EventVideos(props) {
  const { helmetContext } = props;
  const params = useParams()
  const id = parseInt(params.id);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalVideoLink, setModalVideoLink] = useState("");

  const mostrarVideo = (title, videoLink) => {
    setModalTitle(title);
    setModalVideoLink(videoLink);
    setModalOpen(true);
  };

  const videoTableRows = (videoData) => {
    if (!videoData?.["hydra:member"]) return [];

    return videoData["hydra:member"].map(data => {

      let row = [];
      row.push(data.categoria + (data.idClassificatoria !== null ? ' - ' + data.classificatoria : ''));
      row.push(<Link href={'/animais/' + data.idAnimal} color="primary">{data.animal}</Link>);
      row.push(<Link href={'/competidores/' + data.idCompetidor} color="primary">{data.competidor}</Link>);
      row.push(<IconButton key={data.idYouTube} size="small" onClick={() => mostrarVideo(data.animal, YOUTUBE_VIDEO_ADDRESS_ROOT + data.idYouTube)}>
        <PlayCircleOutlineIcon sx={{ fontSize: { xs: '0.9em', sm: '1.0em', md: '1.2em' } }} />
      </IconButton>);
      return row;
    });
  };

  const [event = {}, loadingEvent, { error: eventError }] = useGetData(`eventos/${id}`);
  const [eventVideos = {}, loadingEventVideos, { error: eventVideosError }] = useGetData(`eventos/videos?idEvento=${id}`);

  if (!id || eventError?.response?.status === 404) return <Alert severity="error">Evento não localizado!</Alert>
  if (eventError) return <Alert severity="error">{`${eventError}`}</Alert>
  if (eventVideosError) return <Alert severity="error">{`${eventVideosError}`}</Alert>
  if (loadingEvent && loadingEventVideos) return <CircularProgress />

  return (
    <>
      <Helmet>
        <title>{`${event?.nome} | ${helmetContext.title}`}</title>
        <meta name="description" content={event?.descricao} />
        <meta name="keywords" content="Videos" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 2 }}
      >
        <Grid item key="main" md={7}>
          {event.nome?.length > 0
            ? <>
              <EventHeader event={event} />
              {eventVideos["hydra:member"]?.length > 0 ?
                <BasicDataTable
                  title="Vídeos"
                  paper={true}
                  headers={videoTableHeaders}
                  rows={videoTableRows(eventVideos)}
                  striped={true}
                />
                : <CircularProgress />
              }

            </>
            : <CircularProgress />
          }
        </Grid>
        <Grid item key="links" md={3} >

          <EventLinks event={event} withHeader={true} />

        </Grid>
      </Grid>
      <VideoDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} videoLink={modalVideoLink} />

    </>
  )

}