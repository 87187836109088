import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Pagination from '@mui/material/Pagination';
import PaginationItem from "@mui/material/PaginationItem";
import { Helmet } from "react-helmet-async";
import { Box, CircularProgress, LinearProgress } from "@mui/material";
import {
  NEWS_ITENS_POR_PAGINA,
} from "../../constants";
import {
  formatDateStringWithSlashes, getAttrFromString,
  redirectRelativeImageLinks,
} from "../../functions";
import { useGetData } from "../../axiosHook/useRequestData";
import SearchField from "../SearchField";
import { Alert, Card, CardMedia } from '@mui/material';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import DefaultThumb from "../../images/newsDefaultThumb.jpg";


function setParams({ pesquisa, page }) {
  const searchParams = new URLSearchParams();
  if (pesquisa) {
    searchParams.set("search", pesquisa);
  }
  if (page) {
    searchParams.set("page", page);
  }
  searchParams.set("itemsPerPage", NEWS_ITENS_POR_PAGINA);
  return searchParams.toString();
}


function findThumb(text) {
  const listOfImages = getAttrFromString(text, "img", "src");
  const thumbUrl =
    listOfImages.length > 0
      ? redirectRelativeImageLinks(listOfImages[0])
      : DefaultThumb;

  return thumbUrl;
}

const getNextUrl = (pesquisa, page) => `/noticias?${setParams({ pesquisa: pesquisa, page: page })}`;

export default function NewsList(props) {
  const { helmetContext } = props;

  let location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const page = parseInt(params.get('page') ?? 1); // Default to page 1
  const ultimaPesquisa = params.get('search') ?? "";

  const [campoPesquisa, setCampoPesquisa] = useState(ultimaPesquisa ?? "");

  // Puxar os dados da API quando monta o component, ou se mudar a pagina
  const [listaNoticias = [], updating, { error }] = useGetData(getNextUrl(ultimaPesquisa, page), [page, ultimaPesquisa]);

  const news = listaNoticias["hydra:member"];
  const totalItens = listaNoticias["hydra:totalItems"];
  const totalPaginas = Math.ceil(listaNoticias["hydra:totalItems"] / NEWS_ITENS_POR_PAGINA);

  if (error) return <Alert severity="error">{`${error}`}</Alert>

  return (
    <>
      <Helmet>
        <title>{`Notícias | ${helmetContext.title}`}</title>
        <meta name="description" content="Confira todas as notícias da ANCR" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, notícias, informativos, informações" />
      </Helmet>
      <ScrollToTopOnMount />

      <Grid container direction="row" alignItems="stretch" spacing={5} sx={{ pt: 2, mb: 0 }}>
        <Grid item md={12}>
          <Typography variant="h2" component="h1">
            Notícias
          </Typography>

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
          >

            <Grid item xs={12} sm={6} >
              <Typography component="p" sx={{ pt: 2, mb: 0 }}>
                Confira as notícias recentes.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <SearchField searchField={campoPesquisa} setSearchField={setCampoPesquisa} getNextUrl={getNextUrl} />
            </Grid>
          </Grid>


          <Divider sx={{ pt: 2, mb: 0 }} />{" "}

          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
          >
            {
              news?.length > 0
                ? (
                  <>
                    {
                      news.map((article, index) =>
                      (
                        <React.Fragment key={index}>
                          <Grid item xs={5} lg={3}>
                            <Link href={`/noticias/${article.id}`}>
                              <Card
                                key={index}
                                sx={{
                                  width: "80%",
                                  aspectRatio: "4/3",
                                  mt: '5%',
                                  ml: "10%"
                                }}
                              >
                                <CardMedia
                                  sx={{
                                    width: "100%",
                                    aspectRatio: "4/3",
                                  }}
                                  component="img"
                                  alt={article?.title}
                                  image={findThumb(article?.texto)}
                                  title={article?.title}
                                />
                              </Card>
                            </Link>
                          </Grid>
                          <Grid item xs={7} lg={9}>
                            <Link href={`/noticias/${article.id}`}>
                              <Typography
                                variant="h3"
                                sx={{
                                  pt: {
                                    xs: 0.75,
                                    sm: 2,
                                  },
                                  pb: {
                                    xs: 0.75,
                                    sm: 1,
                                  }
                                }}
                              >
                                {article.titulo}
                              </Typography>
                              <Box
                                component="span"
                                sx={{
                                  fontSize: "0.75em",
                                  color: "#b5b5b5",
                                }}
                              >
                                {formatDateStringWithSlashes(article.dataNoticia)}
                              </Box>
                              <Typography variant="body2" sx={{ display: { xs: "none", sm: "block" } }}>
                                <Box component="span">{article.chamada}<br /></Box>
                              </Typography>
                            </Link>
                          </Grid>
                          <Grid item xs={'12'}><Divider variant="middle" sx={{ pt: 2, mb: 0 }} /></Grid>
                        </React.Fragment>
                      ))
                    }
                    <LinearProgress className={
                      updating
                        ? ""
                        : "hidden"
                    } />
                    <Typography component="p" sx={{ pt: 2, mb: 0 }}>
                      {
                        `Você está visualizando as notícias de ${page * NEWS_ITENS_POR_PAGINA - (NEWS_ITENS_POR_PAGINA - 1)} até ${page * NEWS_ITENS_POR_PAGINA - (
                          NEWS_ITENS_POR_PAGINA - news
                            ?.length)} em um total de ${totalItens}.`
                      }
                    </Typography>
                    <Pagination
                      variant="outlined"
                      shape="rounded"
                      sx={{
                        pt: 2,
                        textAlign: "center",
                      }}
                      page={page}
                      count={totalPaginas}
                      renderItem={item => (
                        <PaginationItem
                          component={RouterLink}
                          to={getNextUrl(ultimaPesquisa, item.page)}
                          {...item}
                        />
                      )}
                    />
                  </>
                )
                : listaNoticias['hydra:totalItems'] === 0 && ultimaPesquisa !== "" ?
                  <Typography component="p" sx={{ pt: 2, mb: 0 }}>
                    Nenhuma notícia localizada. Tente outra pesquisa.
                  </Typography>
                  :
                  <CircularProgress />
            }</Grid>
        </Grid>
      </Grid> </>);
}