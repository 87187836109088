import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { Grid, Typography, CircularProgress, Button } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { useGetData } from "../../axiosHook/useRequestData";
// import {
//   EVENT_JUDGING_SHEET_GO_ROUND_TEST_DATA,
//   EVENTO_TEST_DATA,
//   EVENT_JUDGING_SHEET_TEST_DATA,
// } from "../test-data";
import EventLinks from './EventLinks';
import EventHeader from './EventHeader';
import { Alert } from '@mui/material';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const CustomButton = (props) => (
  <Button
    {...props}
    sx={{ flexDirection: 'column', mb: 1, height: "100%", width: "100%", backgroundColor: "#f4f4f4", fontSize: "0.8em", lineHeight: "1em", textTransform: "capitalize", color: "rgba(0, 0, 0, 0.6)" }}
  >
    <AssignmentIcon size="large" sx={{ mb: 1 }} />
    {props.label}
  </Button>
)


const GoRoundSheets = (props) => {
  const { sheets } = props;

  let maxBunchNumber = sheets.reduce(function (a, b) {
    return Math.max(a, b.bateria);
  }, 0);

  let maxJudgeNumber = sheets.reduce(function (a, b) {
    return Math.max(a, b.numeroJuiz);
  }, 0);

  let rows = [];
  for (let bunchNumber = 1; bunchNumber <= maxBunchNumber; bunchNumber++) {
    let row = [];
    row.push(
      <Grid key={`bateria${bunchNumber}`} item xs={12} sm={12} md={12} lg={2}>
        <Typography variant='h3' display='block' sx={{ fontSize: "0.65em !important", color: "#bbb" }}>{`BATERIA ${bunchNumber}`}</Typography>
      </Grid >
    );
    for (let judgeNumber = 1; judgeNumber <= maxJudgeNumber; judgeNumber++) {
      const sheet = sheets.find(sheet => sheet.bateria === bunchNumber && sheet.numeroJuiz === judgeNumber);
      row.push(
        <Grid item xs={6} sm={4} md={3} lg={2} key={`bateria${bunchNumber}_sheet${judgeNumber}`} sx={{ paddingBottom: '8px' }}>
          <CustomButton label={sheet ? sheet.juiz : ''} onClick={() => sheet ? window.open(sheet.urlImagem) : null} />
        </Grid>
      );
    }
    rows.push(<Grid key={`row${bunchNumber}`} container direction="row" columnSpacing={1}>{row}</Grid>);
  }

  return rows ?? null;
};

export default function EventJudgingSheets(props) {
  const { helmetContext } = props;
  const params = useParams()
  const id = parseInt(params.id);

  const [event = {}, loadingEvent, { error: eventError }] = useGetData(`eventos/${id}`);
  const [eventGoRounds = {}, loadingEventGoRounds, { error: eventGoRoundsError }] = useGetData(`fichas_julgamentos/categorias?evento=${id}`);
  const [eventJudgesSheets = {}, loadingEventJudgesSheets, { error: eventJudgesSheetsError }] = useGetData(`fichas_julgamentos?evento.id=${id}`);

  if (!id || eventError?.response?.status === 404) return <Alert severity="error">Evento não localizado!</Alert>
  if (eventError) return <Alert severity="error">{`${eventError}`}</Alert>
  if (eventGoRoundsError) return <Alert severity="error">{`${eventGoRoundsError}`}</Alert>
  if (eventJudgesSheetsError) return <Alert severity="error">{`${eventJudgesSheetsError}`}</Alert>
  if (loadingEvent && loadingEventGoRounds && loadingEventJudgesSheets) return <CircularProgress />

  return (
    <>
      <Helmet>
        <title>{`${event?.nome} | ${helmetContext.title}`}</title>
        <meta name="description" content={event?.descricao} />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 1, mb: 0 }}
      >
        <Grid item key="main" md={7}>
          {event.nome?.length > 0
            ? <>
              <EventHeader event={event} />
              {eventGoRounds["hydra:member"]?.length > 0 ?
                <>
                  <br />
                  <Typography variant="h2">Fichas de Julgamento</Typography>
                  {eventGoRounds["hydra:member"].map((goRound) =>
                    <React.Fragment key={`${goRound.idCat}-${goRound.idClassificatoria}`}>
                      <Typography variant="h3">{`${goRound.classificatoria ? goRound.categoria + ' - ' + goRound.classificatoria : goRound.categoria}`}</Typography>

                      <Grid
                        container
                        direction="row"
                      >
                        {eventJudgesSheets["hydra:member"]?.length > 0 ?

                          <GoRoundSheets sheets={eventJudgesSheets["hydra:member"].filter(sheet => sheet.idCat === goRound.idCat && sheet.idClassificatoria === goRound.idClassificatoria)} />
                          :
                          <CircularProgress />
                        } </Grid>

                    </React.Fragment>
                  )}
                </>
                : <CircularProgress />
              }

            </>
            : <CircularProgress />
          }
        </Grid>
        <Grid item key="links" md={3} >

          <EventLinks event={event} withHeader={true} />

        </Grid>
      </Grid>
    </>
  )

}