import React, { useState, useEffect } from 'react'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { useForm, FormProvider } from "react-hook-form";
import {
  DEBUG_FORM_DATA,
} from "../../constants";
import { axiosInstance as axios } from "../../axiosHook/axiosInstance";
import {
  // AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import { CircularProgress, LinearProgress } from '@mui/material';
import RHookFormTextField from '../../form/RHookFormTextField';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import {
  // jwtToObject,
} from '../../functions';


const defaultValues = {
  documento: "",
};


const ResetarSenha = (props) => {
  const { setModalMessage, setModalTitle, setModalOpen, setModalNavigateUrlOnClose, setModalClearSessionOnClose, resetToken } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });


  const {
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmittingRHF },
    watch,
    reset,
    control,
  } = methods;

  const onSubmit = async data => {
    setIsSubmitting(true);
    // console.log(data);
    axios
      .post(
        `/reset-password`,
        data,
      )
      .then((response) => {
        // debugging:
        if (process.env.NODE_ENV !== 'production') {
          console.error(response.data);
        }
        setModalTitle("Sucesso");
        setModalMessage("Se seu documento foi encontrado você receberá um link no seu e-mail cadastrado para resetar sua senha.");
        setModalClearSessionOnClose(true);
        setModalNavigateUrlOnClose("")
        setModalOpen(true);
        reset();
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setModalTitle("Error");
          if (error.response.data?.message === "Too many failed login attempts, please try again in 1 minute.") {
            setModalMessage({
              type: "error",
              message: "Número de tentativas esgotado. Tente novamente em 1 minuto.",
            });
          } else {
            setModalMessage({
              type: "error",
              message: "A combinação de nome e senha não está correta. Tente novamente.",
            });
          }
        } else {
          console.log("error", error);
          setModalTitle("Error");
          setModalMessage({
            type: "error",
            message: error.response?.data?.detail ?? error.message,
          });
        }
        setModalNavigateUrlOnClose("");
        setModalClearSessionOnClose(false);
        setModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  useEffect(() => {

    if (resetToken) {

      setIsSubmitting(true);
      // console.log(data);
      axios
        .post(
          `/reset-password/reset/${resetToken}`,
        )
        .then((response) => {
          // debugging:
          if (process.env.NODE_ENV !== 'production') {
            console.error(response.data);
          }
          setModalTitle("Sucesso");
          setModalMessage("Sua senha foi resetada e você receberá um email com seu novo login e senha.");
          setModalClearSessionOnClose(true);
          setModalNavigateUrlOnClose("/login?type=0&rt=")
          setModalOpen(true);
          reset();
        })
        .catch((error) => {
          if (error.response?.status === 401) {
            setModalTitle("Error");
            if (error.response.data?.message === "Too many failed login attempts, please try again in 1 minute.") {
              setModalMessage({
                type: "error",
                message: "Número de tentativas esgotado. Tente novamente em 1 minuto.",
              });
            } else {
              setModalMessage({
                type: "error",
                message: "A combinação de nome e senha não está correta. Tente novamente.",
              });
            }
          } else {
            console.log("error", error);
            setModalTitle("Error");
            setModalMessage({
              type: "error",
              message: error.response?.data?.detail ?? error.message,
            });
          }
          setModalNavigateUrlOnClose("/login?type=2&rt=")
          setModalClearSessionOnClose(false);
          setModalOpen(true);
        })
        .finally(() => setIsSubmitting(false));

      return <CircularProgress />;
    };
  }, [resetToken, reset, setModalClearSessionOnClose, setModalMessage, setModalNavigateUrlOnClose, setModalOpen, setModalTitle]);

  return (
    <>
      <Typography variant="h2">
        Digite seu documento
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RHookFormTextField
            name="documento"
            label="CPF"
            sx={{ mt: 1 }}
            fullWidth
            inputProps={{ maxLength: 18 }}
            rules={{ required: true, minLength: 5, maxLength: 18 }}
            errorMessages={
              [{
                type: "minLength",
                message: `O número do documento deve ter no mínimo 5 caracteres!`,
              }, {
                type: "maxLength",
                message: `O nome de usuário deve ter no maximo 18 caracteres!`,
              }]}
            helperText="Digite o número do seu CPF, ou se for estrangeiro o número do seu passaporte conforme consta em seu cadastro, para receber um link no email para resetar sua senha"
          />

          <LinearProgress sx={{
            pt: 1,
            opacity: isSubmittingRHF ? "1" : "0",
          }} />

          <Button variant="contained" fullWidth color="secondary" sx={{ px: 4 }} type="submit" disabled={isSubmitting}>Enviar</Button>
        </form>
        {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
      </FormProvider>
    </>
  );
};

export default ResetarSenha;