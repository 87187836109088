import React, { useState, useEffect, useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import Typography from '@mui/material/Typography';
import '../../styles/general.css';
import { Box, useMediaQuery } from '@mui/material';
import { Alert } from '@mui/material';
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';
import {
  // PARTNERS_TEST_DATA,
} from "../../test-data";
import {
  PARTNER_START_NUMBER,
  NUMBER_OF_PARTNERS_ON_SMALL_SCREENS,
  NUMBER_OF_PARTNERS_ON_LARGER_SCREENS,
  PARTNER_SWAP_INTERVAL,
  PARTNER_MOVE_STEP,
} from "../../constants";
import {
  arrayShuffle,
} from "../../functions";
import useInterval from '../../useInterval';

const handlePartnerClick = (partner) => {
  if (!partner.linkTo || partner.linkTo === '' || partner.linkTo === 'http://' || partner.linkTo === 'https://') return;
  window.open(partner.linkTo, "_blank")
};

const showPartners = (partners, partnerStartNumber) => partners.map((partner, index) =>
  index >= partnerStartNumber &&
  <React.Fragment key={partner.descricao}>
    <Box
      component="li"
      sx={{
        textAlign: 'center',
        display: 'inline-block !important',
        marginRight: '1%',
        marginLeft: '1%',
        width: {
          xs: '95%',
          sm: '18%',
        },
        height: {
          xs: '33.335vw',
          sm: '6.6667vw',
        },
      }}
    >
      <Box
        component="img"
        src={partner.urlImagem}
        alt={partner.descricao}
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          cursor: (partner.linkTo !== '' && partner.linkTo !== 'https://' && partner.linkTo !== 'http://') ? 'pointer' : 'default',
        }}
        onClick={() => handlePartnerClick(partner)}
      />
    </Box>
  </React.Fragment>
);

export default function Partners() {

  const [partners, setPartners] = useState([]);
  const [manualMovement, setManualMovement] = useState(false);

  const getData = useCallback(
    () => {
      axios
        .get(`/banners`)
        .then((response) => {
          const data = response.data;
          let listToShuffle = data["hydra:member"];
          setPartners(arrayShuffle(listToShuffle));
        })
        .catch((error) => {
          // Obs: We check if the error has already been set on the object, to avoid setting it again and causing an unnecessary state change
          if (!partners.hasOwnProperty(error)) {
            setPartners({
              "error": error,
            });
          }
          console.log("error", error);
        });
    },
    [partners],
  )

  // Puxar os dados da API quando monta o component
  useEffect(() => {
    getData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps


  const [partnerStartNumber, setPartnerStartNumber] = useState(PARTNER_START_NUMBER);

  const theme = useTheme();
  const numberOfPartnersOnScreen = useMediaQuery(theme.breakpoints.down('md')) ? NUMBER_OF_PARTNERS_ON_SMALL_SCREENS : NUMBER_OF_PARTNERS_ON_LARGER_SCREENS;

  useInterval(async () => {
    !manualMovement && setPartnerStartNumber(partnerStartNumber => (partnerStartNumber >= partners.length - numberOfPartnersOnScreen) ? 0 : partnerStartNumber + numberOfPartnersOnScreen);
  }, PARTNER_SWAP_INTERVAL);


  const handleClick = (positive) => {
    setManualMovement(true);
    setPartnerStartNumber(partnerStartNumber => partnerStartNumber + (positive ? 1 : -1) * PARTNER_MOVE_STEP)
  }

  return partners.hasOwnProperty('error')
    ? <Alert severity="error">{`${partners.error} `}</Alert>
    : partners.length
      ? <>
        <Grid item xs={12}>
          <Typography variant="h2" sx={{ mt: 8 }} align='center'>
            Parceiros
          </Typography>
        </Grid>

        <Grid item xs={1}>
          <IconButton
            aria-label="Anterior"
            onClick={() => handleClick(false)}
            disabled={partnerStartNumber < 1}
            size="large">
            <NavigateBeforeIcon />
          </IconButton>
        </Grid>


        <Grid item xs={10}>
          <Box
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              width: '100%',
              margin: 0,
            }}
          >
            <Box
              component="ul"
              sx={{
                position: 'relative',
                width: '100%',
                listStyleType: 'none',
                m: 0,
                p: 0,
                height: {
                  xs: '33.335vw',
                  sm: '6.6667vw',
                },
              }}
            >
              {showPartners(partners, partnerStartNumber)}
            </Box>
          </Box>
        </Grid>


        <Grid item xs={1} align='right'>
          <IconButton
            aria-label="Próximo"
            onClick={() => handleClick(true)}
            disabled={partnerStartNumber >= partners.length - numberOfPartnersOnScreen}
            size="large">
            <NavigateNextIcon />
          </IconButton>

        </Grid>

      </>
      : null;
}