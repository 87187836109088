import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../styles/general.css';
// import { useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';


export default function MainMenu(props) {
  const { menus } = props;

  // const theme = useTheme();
  // const xs = useMediaQuery(theme.breakpoints.down('sm'));

  let navigate = useNavigate();

  const CustomButton = (props) => (
    <Button
      {...props}
      size='large'
      color='secondary'
      sx={{
        fontFamily: "Foco Regular",
        fontSize: "16px",

        "&:hover": {
          backgroundColor: "#FFF !important",
          color: "primary.main"
        }
      }}
    >
      {props.label}
    </Button>
  )


  return (
    <AppBar
      position="static"
      sx={{
        display: {
          xs: 'none',
          sm: 'none',
          md: "block",
          lg: "block",
          xl: "block",
        },
        mb: { md: 5 }
      }}
    >
      <Box sx={{ overflowX: 'auto', margin: "0 auto" }}>
        <Toolbar sx={{
          ml: { md: "8.5%" }
        }}>
          {menus.map(item =>
            <Box
              key={item.titulo}
              mr={2}
              ml={2}
            >
              <CustomButton
                label={item.titulo}
                onClick={() => navigate(item.url)}
              />
            </Box>
          )}
        </Toolbar>
      </Box>
    </AppBar>
  );
}