import React from 'react'
import { Alert, CircularProgress, Typography } from '@mui/material';
import { useGetData } from "../../axiosHook/useRequestData";
import {
  formatMoney,
} from '../../functions';

export const EventTotalPrizeMoney = (props) => {
  const { event } = props;
  const { id } = event;

  const [eventPrizeTotal = {}, loading, { error }] = useGetData(`/totalpremiacao?idEvento=${id}`);

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />
  if (!eventPrizeTotal || !eventPrizeTotal.valor) return null;

  return (
    <div>
      <Typography align="center" sx={{ mt: 2 }}>Total premiado em dinheiro no evento {formatMoney(eventPrizeTotal.valor)}{eventPrizeTotal.avisoNaoMoeda ? '*' : ''}</Typography>
      {eventPrizeTotal.avisoNaoMoeda &&
        <Typography>*Estes valores incluem premiação que não foi paga em moeda.</Typography>
      }

    </div>
  )
}
