import {
  NUMBER_FORMAT_LOCALE,
  CURRENCY_FORMAT,
  PLACEHOLDER_IMAGE_URL,
} from './constants';
import moment from 'moment';

const formatDateStringWithSlashes = data => (data === null || data === undefined) ? '' : `${data.toString().substring(6, 8)}/${data.toString().substring(4, 6)}/${data.toString().substring(0, 4)}`;

const dateFromString = (yyyyDashMmDashDd) => {
  if (!yyyyDashMmDashDd) return yyyyDashMmDashDd;
  const ymd = yyyyDashMmDashDd.split('-');
  return new Date(ymd[0], ymd[1] - 1, ymd[2]);
}

const isValidDate = (date) => {
  const m = moment(date);
  return m.isValid();
};

const isValidDateAndNotFuture = (date) => {
  if (!date) {
    return false;
  }
  const dateFromString = new Date(date);
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  return dateFromString < today;
};

const idadeEmAnosHipicos = (nascimento, temporadaEvento) => {
  if (nascimento === null
    || nascimento === undefined
    || temporadaEvento === null
    || temporadaEvento === undefined
    || isNaN(temporadaEvento)
  ) return null;
  const temporadaNascimento = nascimento.getMonth() >= 6 ? nascimento.getFullYear() : nascimento.getFullYear() - 1;
  return temporadaEvento - temporadaNascimento;
};

function getAgeAtDate(competitorBirthDateString, showOrSeasonStartDateString) {
  var showOrSeasonStartDate = new Date(showOrSeasonStartDateString);
  var competitorBirthDate = new Date(competitorBirthDateString);
  var age = showOrSeasonStartDate.getFullYear() - competitorBirthDate.getFullYear();
  var m = showOrSeasonStartDate.getMonth() - competitorBirthDate.getMonth();
  if (m < 0 || (m === 0 && showOrSeasonStartDate.getDate() < competitorBirthDate.getDate())) {
    age--;
  }
  return age;
}

const getAttrFromString = (str, node, attr) => {
  var regex = new RegExp('<' + node + ' .*?' + attr + '="(.*?)"', "gi"), result, res = [];
  while ((result = regex.exec(str))) {
    res.push(result[1]);
  }
  return res;
};

const redirectRelativeImageLinks = (html) => {
  if (typeof html !== "string") return html;
  if (html.startsWith("/")) {
    // if this is just a link, not a full element
    return html
      .replaceAll("/UserFiles/", "https://userfiles.ancr.org.br/")
  }
  return html
    .replaceAll(" src=\"/UserFiles/", " src=\"https://userfiles.ancr.org.br/")
    .replaceAll(" src=\"/admin/", " src=\"https://admin.ancr.org.br/")
    .replaceAll(" src=\"images/", " src=\"https://images.ancr.org.br/");
};

const extractDestaqueSource = (destaque) => {
  let data = {
    id: destaque.id,
    titulo: destaque.titulo,
  };

  // if the type is an image or iframe, and we find a source link in the html
  if (destaque.tipo === 'img' || destaque.tipo === 'iframe') {
    if (getAttrFromString(destaque.chamada, destaque.tipo, 'src')) {
      return {
        ...data,
        tipo: destaque.tipo,
        src: getAttrFromString(destaque.chamada, destaque.tipo, 'src')[0],
      };
    }
  }

  // else mantain as plain html
  return {
    ...data,
    tipo: 'html',
    html: destaque.chamada,
  };

};

const formatarDadosNominacao = (nominacao) => {
  return {
    numero: nominacao.numero,
    nome: nominacao.animal === null ? 'A Confirmar' : nominacao.animal.nome,
    raca: nominacao.descricaoRaca,
    pai: nominacao.nomePai,
    mae: nominacao.nomeMae,
    filiacao: `${nominacao.nomePai} x ${nominacao.nomeMae}`,
    nominador: nominacao.nominador?.nome,
    dataNominacao: nominacao.dataNominacao,
    anoNominacao: nominacao.anoNominacao,
    sexo: nominacao.descricaoSexo,
    nascimento: nominacao.nascimento,
  };
}

const nivelCompetidorEmTexto = nivel => `Nível ${nivel === 0 ? "Principiante" : nivel}`;

const temporadaAtual = () => {
  const ano = (new Date()).getFullYear();
  const mes = (new Date()).getMonth();
  return mes >= 6 ? ano : ano - 1;
}

const getBoolSearchParameterOrNull = (UrlSearchParams, parameterName) => UrlSearchParams.has(parameterName) ? parseInt(UrlSearchParams.get(parameterName)) === 1 : null;

const formatMoney = (amount) => new Intl.NumberFormat(NUMBER_FORMAT_LOCALE, { style: 'currency', currency: CURRENCY_FORMAT }).format(amount);
const formatScores = (score) => new Intl.NumberFormat(NUMBER_FORMAT_LOCALE).format(score);
const formatYesNo = (boolean) => boolean ? "Sim" : "Não";

const bootstrap3ResponsiveTableWrapper = (content) => content.replace(/<table.*?<\/table>/gis,
  function (table) { return '<div class="table-responsive">' + table + '</div>' }
);

function checkIfValidUUID(str) {
  // Regular expression to check if string is a valid UUID
  const regexExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

  return regexExp.test(str);
};

function convertGuid(str) {
  if (typeof str !== "string") return str;
  if (str) {
    str = str.replace(/[{}]/g, '');
    str = str.toUpperCase();
  }
  return str;
};

function arrayShuffle(array) {
  let currentIndex = array.length, randomIndex;

  // While there remain elements to shuffle...
  while (currentIndex !== 0) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex], array[currentIndex]];
  }

  return array;
}

const getIntParam = (params, name) => {
  if (!params.has(name)) return null;
  if (isNaN(params.get(name)) || params.get(name) === '') return null;

  return parseInt(params.get(name));
};

const errorResponseMessage = (error) => {
  if (!error.response) return error.message;
  if (error.response.data?.violations) {
    return error.response.data.violations.reduce((a, b) => a ? `${b.message}\n${a}` : b.message, '');
  }
  return error.response.data?.["hydra:description"] ?? error.response.data?.detail;
};

const jwtToObject = jwt => {
  try {
    const base64Url = jwt.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    return JSON.parse(window.atob(base64));
  } catch (ex) {
    throw new Error("Could not parse provided Json Web Token: " + ex);
  }
};

const urlImagemCampanha = (baseProfileData, usePlaceHolder = true) => {
  if (baseProfileData?.urlImagemGrande) return baseProfileData.urlImagemGrande;
  if (baseProfileData?.urlImagem) return baseProfileData.urlImagem;
  return usePlaceHolder ? PLACEHOLDER_IMAGE_URL : null;
}

export {
  formatDateStringWithSlashes,
  isValidDate,
  isValidDateAndNotFuture,
  idadeEmAnosHipicos,
  getAgeAtDate,
  getAttrFromString,
  redirectRelativeImageLinks,
  extractDestaqueSource,
  formatarDadosNominacao,
  nivelCompetidorEmTexto,
  dateFromString,
  temporadaAtual,
  getBoolSearchParameterOrNull,
  formatMoney,
  formatScores,
  formatYesNo,
  bootstrap3ResponsiveTableWrapper,
  checkIfValidUUID,
  convertGuid,
  arrayShuffle,
  getIntParam,
  errorResponseMessage,
  jwtToObject,
  urlImagemCampanha,
};