import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { useForm, FormProvider } from "react-hook-form";
import { useGetData } from "../../axiosHook/useRequestData";
import {
  DEBUG_FORM_DATA,
  MIN_LENGTH_NOME_USUARIO,
  MAX_LENGTH_NOME_USUARIO,
  MIN_LENGTH_SENHA_USUARIO,
  MAX_LENGTH_SENHA_USUARIO,
} from "../../constants";
import { axiosInstance as axios } from "../../axiosHook/axiosInstance";
import {
  // AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import { Alert, CircularProgress, IconButton, InputAdornment, LinearProgress } from '@mui/material';
import RHookFormTextField from '../../form/RHookFormTextField';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import {
  // jwtToObject,
} from '../../functions';
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const defaultValues = {
  userIdentifier: "",
  apiPassword: "",
  apiPassword2: "",
};


const SetLoginAndPasswordForm = (props) => {
  const { session, clear, setModalMessage, setModalTitle, setModalOpen, setModalNavigateUrlOnClose, setModalClearSessionOnClose } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showPassword, setShowPassword] = useState([false, false]);

  const handleClickShowPassword = (event) => {
    setShowPassword(showPassword =>
      document.getElementById("apiPassword2").parentNode.contains(event.target)
        ? [showPassword[0], !showPassword[1]]
        : [!showPassword[0], showPassword[1]]);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [baseProfileData, loadingUserId, { error }] = useGetData({
    url: `/afiliados/${session.id}`,
    headers: {
      'Authorization': `Bearer ${session.token}`
    },
  });

  const [listOfUserIdentifiers = [], loadingUserIdentifiers] = useGetData(`/getuseridentifiers`);

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });


  const {
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmittingRHF },
    watch,
    reset,
    control,
  } = methods;

  const onSubmit = async data => {
    setIsSubmitting(true);
    // console.log(data);
    axios
      .patch(
        `${baseProfileData.authUser}`,
        data,
        {
          headers: {
            'Authorization': `Bearer ${session.token}`,
            'Content-Type': 'application/merge-patch+json',
          },
        })
      .then((response) => {
        reset(); // reset form
        // redirect to user logged in page
        // console.log(response);
        setModalTitle("Sucesso");
        setModalMessage("Seu novo login foi criado. Efetue o login agora com seu novo login e senha.");
        setModalClearSessionOnClose(true);
        setModalNavigateUrlOnClose("/login?type=0")
        setModalOpen(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setModalTitle("Erro");
          setModalMessage({
            type: "error",
            message: "Seu login já expirou. Efetue o login novamente.",
          });
        } else {
          console.log("error", error);
          setModalTitle("Erro");
          setModalMessage({
            type: "error",
            message: error.response?.data?.detail ?? error.message,
          });
        }
        setModalClearSessionOnClose(true);
        setModalNavigateUrlOnClose("");
        setModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const apiPassword = watch("apiPassword");

  const validarNomeUsuario = (nome) => listOfUserIdentifiers && !listOfUserIdentifiers?.includes(nome);
  const validarSenhas = (password) => password === apiPassword;

  if (error) return (
    <Alert
      severity="error"
      action={error?.response?.status === 401 ?
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={clear}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        : null
      }
    >
      {`${error?.response?.status === 401 ? 'Seu login expirou.' : error}`}
    </Alert>
  );

  if (loadingUserId || loadingUserIdentifiers) return <CircularProgress />;

  return (
    <>
      <Typography variant="h2">
        Agora define seu novo login e senha
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RHookFormTextField
            name="userIdentifier"
            label="Login"
            id="userIdentifier"
            sx={{ mt: 1 }}
            fullWidth
            inputProps={{ maxLength: MAX_LENGTH_NOME_USUARIO }}
            rules={{ minLength: MIN_LENGTH_NOME_USUARIO, validate: validarNomeUsuario }}
            errorMessages={
              [{
                type: "validate",
                message: "Este usuário já existe!",
              }, {
                type: "minLength",
                message: `O nome de usuário deve ter no mínimo ${MIN_LENGTH_NOME_USUARIO} caracteres!`,
              }, {
                type: "maxLength",
                message: `O nome de usuário deve ter no maximo ${MAX_LENGTH_NOME_USUARIO} caracteres!`,
              }]}
            helperText=" "
          />

          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <RHookFormTextField
                name="apiPassword"
                label="Senha"
                type={showPassword[0] ? 'text' : 'password'}
                sx={{ mt: 1 }}
                fullWidth
                inputProps={{ maxLength: MAX_LENGTH_SENHA_USUARIO }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size='small'
                      >
                        {showPassword[0] ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                    </InputAdornment>
                }}
                rules={{ required: true, minLength: MIN_LENGTH_SENHA_USUARIO }}
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário digitar a senha!",
                  }, {
                    type: "minLength",
                    message: `A senha deve ter no mínimo ${MIN_LENGTH_SENHA_USUARIO} caracteres!`,
                  }]}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <RHookFormTextField
                name="apiPassword2"
                id="apiPassword2"
                label="Confirmar a Senha"
                type={showPassword[1] ? 'text' : 'password'}
                sx={{ mt: 1 }}
                fullWidth
                inputProps={{ maxLength: MAX_LENGTH_SENHA_USUARIO }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size='small'
                      >
                        {showPassword[1] ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                    </InputAdornment>
                }}
                rules={{ required: true, minLength: MIN_LENGTH_SENHA_USUARIO, validate: validarSenhas }}
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário digitar a senha!",
                  }, {
                    type: "minLength",
                    message: `A senha deve ter no mínimo ${MIN_LENGTH_SENHA_USUARIO} caracteres!`,
                  }, {
                    type: "validate",
                    message: "A senha está diferente!",
                  }]}
              />
            </Grid>
          </Grid>

          <LinearProgress sx={{
            pt: 1,
            opacity: isSubmittingRHF ? "1" : "0",
          }} />

          <Button variant="contained" color="secondary" sx={{ px: 2 }} type="submit" disabled={isSubmitting}>Enviar</Button>

        </form>
        {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
      </FormProvider>
    </>
  );
};

export default SetLoginAndPasswordForm;