import React from 'react';
import Box from '@mui/material/Box';

export default function Endereco(props) {

  const { endereco, linear, narrow } = props;
  if (endereco === undefined || endereco === null) return '';
  return (
    linear ?
      <Box component="span">
        {`${endereco.endereco}, ${endereco.numero} ${endereco.complemento}${endereco.bairro ? ', ' + endereco.bairro : ''}${', ' + endereco.cidade} - ${endereco.estado}${endereco.cep ? ' | CEP: ' + endereco.cep : ''}`}
      </Box>
      :
      narrow ?
        <>
          {`${endereco.endereco}, ${endereco.numero} ${endereco.complemento}`}<br />
          {endereco.bairro &&
            <>
              {endereco.bairro}
              <br />
            </>
          }
          {`${endereco.cidade} - ${endereco.estado}`}<br />
          {`${endereco.cep}`}
        </>
        :
        <Box
          component="span"
          sx={{
            display: "block",
            fontSize: {
              xs: '0.5rem',
              md: '0.8rem',
            },
            lineHeight: {
              xs: "0.8",
              sm: "1.3",
              lg: "1.5",
            }
          }}
        >
          {`${endereco.endereco}, ${endereco.numero} ${endereco.complemento} ${endereco.bairro ? '- ' + endereco.bairro : ''}`}<br />
          {`${endereco.cidade} - ${endereco.estado}`}<br />
          {`${endereco.cep}`}
        </Box>
  )
}
