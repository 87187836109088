import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Logo from "../../images/newsDefaultThumb.jpg";
import {
  Typography,
} from '@mui/material';


export default function PatternCard({ pattern, language }) {

  const instrucaoAntesDasManobras = () => language === 'en-US' ? pattern?.instrucaoAntesDasManobrasIngles : pattern?.instrucaoAntesDasManobras;
  const instrucaoDepoisDasManobras = () => language === 'en-US' ? pattern?.instrucaoDepoisDasManobrasIngles : pattern?.instrucaoDepoisDasManobras;

  return (


    <Card
      sx={{
        maxWidth: '100%',
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <CardMedia
        component="img"
        alt="Desenho do Percurso"
        image={pattern.urlImagem ?? Logo}
        sx={{
          aspectRatio: "1/1",
          objectFit: "contain"
        }} />
      <CardContent>
        <React.Fragment>
          <Typography gutterBottom variant="h2" component="div">
            {language === 'en-US' ? pattern.nomeIngles : pattern.nome}
          </Typography>
          {instrucaoAntesDasManobras()
            ? (
              <Typography
                component="span"
                variant="body2"
                color="textPrimary"
              >
                {instrucaoAntesDasManobras()}
              </Typography>
            ) : null}
          {pattern.manobras?.length > 0
            ? (
              <Typography
                component="span"
                variant="body2"
                color="textPrimary"
              >
                <ol>
                  {pattern.manobras?.map((manobra, index) => (
                    <li key={index}>{language === 'en-US' ? manobra.descricaoIngles : manobra.descricao}</li>
                  ))}
                </ol>
              </Typography>
            )
            : null}
          {instrucaoDepoisDasManobras()
            ? (
              <Typography
                component="span"
                variant="body2"
                color="textPrimary"
              >
                {instrucaoDepoisDasManobras()}
              </Typography>
            ) : null}
        </React.Fragment>
      </CardContent>
    </Card >

  );
};
