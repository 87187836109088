
import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Drawer } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Typography, Stack } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import IconButton from '@mui/material/IconButton';

const DrawerMenu = (props) => {
  let { active, toggle, menus } = props;
  const [drawer, setDrawer] = React.useState(active);

  let navigate = useNavigate();

  useEffect(() => {
    setDrawer(active);
  }, [active]);

  const closeMenu = () => {
    toggle(false);
    setDrawer(false);
  };

  const itemClick = (url) => {
    closeMenu();
    navigate(url);
  };

  return (
    <>
      {/* Menu Drawer */}

      <Drawer
        anchor='left'
        variant="temporary"
        elevation={3}
        PaperProps={{
          sx: {
            p: 1,
            minWidth: {
              xs: "45%",
              md: 'auto'
            },
            borderTopWidth: "0.4rem",
            borderTopColor: 'primary.main',
            borderTopStyle: "solid",

          },
        }}
        onBackdropClick={() => {
          closeMenu();
        }}
        open={drawer}
      >
        <List
          subheader={
            <ListSubheader component="div">
              <Stack direction="row" alignItems="right" spacing={0}>

                <Typography variant='h5' sx={{ flexGrow: 1 }}>MENU</Typography>
                <IconButton aria-label="Fechar Menu" size='small' onClick={() => closeMenu()}>
                  <ArrowBackIosIcon fontSize="inherit" />
                </IconButton>
              </Stack>
              <Divider />
            </ListSubheader>
          }
        >
          {menus.map(item =>
            <ListItemButton
              key={item.titulo}
              onClick={() => itemClick(item.url)}
            >
              <ListItemText primary={item.titulo} />
            </ListItemButton>
          )}
        </List>
      </Drawer >
    </>
  )
}

export default DrawerMenu;