import React from 'react';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { Box, CardActionArea, Grid, Skeleton, Typography } from '@mui/material';
import { Button } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import {
  LINK_INSTAGRAM,
  // GET_INSTAGRAM_POSTS_VIA_DEV_SERVER,
  // API_DEVELOPMENT_SERVER_ROOT_ADDRESS,
} from '../../constants';
import {
  INSTAGRAM_POSTS_TEST_DATA,
} from '../../test-data';
// import { useGetData } from "../../axiosHook/useRequestData";

function Midia(props) {

  let { post } = props;

  const typeOfMedia = post.src.slice(post.src.length - 3);


  if (typeOfMedia !== 'mp4') {
    return (<CardMedia
      sx={{
        width: '100%',
        aspectRatio: '1/1'
      }}
      component="img"
      image={post.src}
      alt={'Post'}
    />)

  } else {
    return (<CardMedia
      sx={{
        width: '100%',
        aspectRatio: '1/1'
      }}
      component="video"
      src={post.src}
      alt={'Post'}
    />)


  }



}

const LatestInstagramPosts = () => {

  // const [latestInstagramPosts = [], loading] = useGetData(`${GET_INSTAGRAM_POSTS_VIA_DEV_SERVER ? API_DEVELOPMENT_SERVER_ROOT_ADDRESS : ''}/instagram`);
  const latestInstagramPosts = [];
  const loading = false;

  // test data still used to get the right number of posts for skeleton
  const posts = latestInstagramPosts.length ? latestInstagramPosts : INSTAGRAM_POSTS_TEST_DATA;



  return (
    <>

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={2}
        sx={{ mt: 10 }}
      >
        <Grid item xs={12}><Typography variant='h2'>
          <InstagramIcon fontSize="large" sx={{ verticalAlign: 'middle' }} /> Nosso Instagram</Typography>
        </Grid>
        {

          posts.map((post, index) => (



            <Grid item xs={6} sm={4} lg={2} key={`List${index}`}>
              <Box sx={{
                width: '100%',
                aspectRatio: '1/1',
              }}>



                {
                  loading
                    ?


                    <Skeleton variant='rectangular' sx={{ height: "100%", m: 0 }} />
                    :


                    <Card sx={{
                      width: '100%',
                      aspectRatio: '1/1',
                      cursor: 'pointer'
                    }}
                    >
                      <CardActionArea onClick={() => window.open(post.link, '_blank', 'noopener')}>
                        <Midia post={post} />
                      </CardActionArea>
                    </Card>

                }


              </Box>
            </Grid>
          ))
        }

        <Grid item xl={12}>
          <Typography variant='body1' align='right'>
            <Button
              variant='contained'
              startIcon={<InstagramIcon />}
              onClick={() => window.open(LINK_INSTAGRAM, '_blank', 'noopener')}
              target="_blank"
              rel="noreferrer"
            >
              + em nosso Instagram
            </Button>
          </Typography>
        </Grid>

      </Grid>
    </>)
}

export default LatestInstagramPosts;