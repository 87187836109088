import React from 'react'
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Typography } from '@mui/material';
import {
  formatDateStringWithSlashes,
} from "../../functions";
import EventLogo from './EventLogo';
import Link from "@mui/material/Link";


export default function EventHeader(props) {
  const { event } = props;

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box mb={1}>
      {event.imagemLogotipo
        ? <Link href={`/eventos/${event.id}`} color="primary">
          <EventLogo alt={event.nome} src={event.imagemLogotipo} />
        </Link>
        : null
      }
      <Link href={`/eventos/${event.id}`} color="primary">
        <Typography
          variant="h3"
          align={xs ? 'center' : 'inherit'}
        >
          {event?.nome}
        </Typography>
      </Link>
      <Typography
        variant="body2"
        sx={{
          fontSize: "1rem",
          textAlign: {
            xs: 'center',
            sm: 'left'
          },
          lineHeight: {
            xs: "1.2rem",
            sm: "1.5rem",
          },
        }}
      >
        {`${xs ? '' : 'Data: '}${formatDateStringWithSlashes(event.dataIni)} - ${formatDateStringWithSlashes(event.dataFim)}`}
        {event.local !== "" && <>
          <br />
          {event.local}
          <br />
          {`${event.cidade ? event.cidade + ', ' : ''}${event.estado}`}
        </>
        }
      </Typography>
    </Box>
  )
}
