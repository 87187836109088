import React from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography, Alert } from '@mui/material';
import {
  checkIfValidUUID,
} from "../../functions";
import NewsSideList from './NewsSideList';
import NewsArticle from './NewsArticle';

const News = (props) => {
  const { helmetContext } = props;
  const params = useParams()
  const id = params.id;

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
        sx={{
          pt: 2,
          mb: 0,
        }}
      >
        <Grid item md={7}>
          {checkIfValidUUID(id)
            ? <NewsArticle helmetContext={helmetContext} id={id} />
            : <Alert severity="error">A notícia não foi localizada!</Alert>
          }
        </Grid>
        <Grid item md={3}>
          <Typography variant="h2">Últimas notícias</Typography>
          <NewsSideList id={id} />
        </Grid>
      </Grid>
    </>
  )
};

export default News;
