import axios from 'axios';
import {
  API_SERVER_ROOT_ADDRESS,
} from '../constants';

let axiosInstance = axios.create({
  baseURL: API_SERVER_ROOT_ADDRESS,
  timeout: 12000,
});

function provideAxiosInstance(instance) {
  axiosInstance = instance;
}

export { axiosInstance, provideAxiosInstance };