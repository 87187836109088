import * as React from 'react';
import _ from "lodash";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import TableFooter from '@mui/material/TableFooter';
import { Box } from '@mui/material';

const styles = {
  headerCell: {
    fontWeight: "bold",
  },
  cell: {
    py: 1,
    px: {
      xs: 0.3,
      sm: 0.5,
      md: 1,
    },
    fontSize: {
      xs: '0.8rem',
      sm: '0.9rem',
    },
  },
};

const align = (alignColumns, index) => alignColumns ? alignColumns[index] : (index === 0 ? "left" : "right");

export default function BasicDataTable(props) {
  const { title, paper, minWidth, headers, rows, footers, striped, alignColumns, sx } = props;

  return (
    <Box sx={sx}>
      {title && <Typography align="center" variant="h2">{title}</Typography>}
      <TableContainer component={paper && Paper}>
        <Table sx={{ minWidth: minWidth }} size="small" aria-label="data table">
          {headers &&
            <TableHead>
              <TableRow>
                {headers.map((header, index) => (
                  <TableCell key={index} align={align(alignColumns, index)} sx={_.assign({}, styles.cell, styles.headerCell)}>{header}</TableCell>
                ))}
              </TableRow>
            </TableHead>
          }
          <TableBody>
            {Array.isArray(rows) && rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                  '&:nth-of-type(odd)': {
                    backgroundColor: striped && 'action.hover',
                  },
                }}
              >
                {row && row.map((column, index) =>
                  <TableCell key={index} component={0 === index ? "th" : "td"} scope="row" align={align(alignColumns, index)} sx={styles.cell}>
                    {column}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          {footers &&
            <TableFooter>
              <TableRow>
                {footers.map((footer, index) => (
                  <TableCell key={index} align={align(alignColumns, index)} style={{ fontSize: "16px", fontWeight: "bold" }}>{footer}</TableCell>
                ))}
              </TableRow>
            </TableFooter>
          }
        </Table>
      </TableContainer >
    </Box>
  );

}