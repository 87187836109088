import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import { useUrlSearchParams } from "use-url-search-params";
import { useGetData } from "../../axiosHook/useRequestData";
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet-async";
import {
  NOME_ASSOCIACAO,
} from "../../constants";
import Endereco from '../Endereco';
import {
  // AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import AlertDialog from '../AlertDialog';
import {
  // jwtToObject,
} from '../../functions';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import AvatarAssociado from './AvatarAssociado';
import LoginTypeTabBar from './LoginTypeTabBar';
import { Alert, CircularProgress, Box } from '@mui/material';

const loginTypes = [
  0, // "login"
  1, // "primeiro"
  2, // "resetar senha"
];

const types = {
  type: loginTypes,
  rt: String, // Reset token
};

const initial = {
  type: loginTypes[0],
  rt: "",
};


const LoginPage = (props) => {
  const { helmetContext, enderecoAssociacao, session, save, clear } = props;

  const [params, setParams] = useUrlSearchParams(initial, types);

  let navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalNavigateUrlOnClose, setModalNavigateUrlOnClose] = useState("");
  const [modalClearSessionOnClose, setModalClearSessionOnClose] = useState(false);


  const [eventosComInscricoesAbertas, loading, { error }] = useGetData(`/eventos/com_inscricoes_abertas`);

  return <>
    <Helmet>
      <title>{`${session ? session.nome : 'Entrar'} | ${helmetContext.title}`}</title>
      <meta name="description" content="Efetuar Log In à ANCR" />
      <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, contato, fale com, telefone, fale com a gente" />
    </Helmet>
    <ScrollToTopOnMount />

    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={5}
      sx={{ pt: 1, mb: 0 }}
    >
      <Grid item xs={12} md={7}>

        {session && params.type === loginTypes[0]
          ? (
            <>
              <Typography variant="h2">
                {session.nome}
              </Typography>
              <Box mt={2}>
                <AvatarAssociado alt={session.nome} session={session} clear={clear} />
              </Box>
              <Button variant="contained" color="secondary" sx={{ mt: 4, px: 2 }} type="button" onClick={() => navigate('/atualizar')}>Atualizar dados</Button>
              <br />
              <br />
              <small>
                Eventos com inscrições abertas:
              </small>
              <br />
              {loading
                ? <CircularProgress />
                : error
                  ? <Alert severity='error'>{`${error}`}</Alert>
                  : <>
                    {eventosComInscricoesAbertas["hydra:member"]
                      ? eventosComInscricoesAbertas["hydra:member"].map(evento =>
                        <React.Fragment key={evento.id}>
                          {evento.nome}<br />
                        </React.Fragment>
                      )
                      : 'Nenhum evento disponível no momento'
                    }
                    <Button variant="contained" color="secondary" sx={{ mt: 2, px: 2 }} type="button" disabled={!eventosComInscricoesAbertas["hydra:member"]} onClick={() => navigate('/inscricoes')}>Inscreve-se</Button>
                  </>
              }
              <br />
              <Button variant="contained" color="secondary" sx={{ mt: 2, px: 2 }} type="button" onClick={() => navigate('/login?type=1')}>Mudar login e senha</Button>
              <br />
              <Button variant="contained" color="secondary" sx={{ mt: 2, px: 2 }} type="button" onClick={clear}>Sair</Button>
            </>
          )
          : (
            <LoginTypeTabBar session={session} save={save} clear={clear} loginTypes={loginTypes} loginType={params.type} setLoginType={setParams} setModalTitle={setModalTitle} setModalMessage={setModalMessage} setModalOpen={setModalOpen} setModalNavigateUrlOnClose={setModalNavigateUrlOnClose} setModalClearSessionOnClose={setModalClearSessionOnClose} resetToken={params.rt} />
          )}


      </Grid>
      <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Typography variant="h2">{NOME_ASSOCIACAO}</Typography>
        <Typography component='p'>
          <Endereco endereco={enderecoAssociacao} linear={false} narrow={true} />
        </Typography>
      </Grid>
    </Grid>
    <AlertDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} message={modalMessage} navigateUrlOnClose={modalNavigateUrlOnClose} clearSessionOnClose={modalClearSessionOnClose} session={session} clear={clear} />
  </>;

};

export default LoginPage;