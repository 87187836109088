import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { Helmet } from "react-helmet-async";
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import YouTube from 'react-youtube';
import { makeStyles } from '@mui/styles';
import { Pagination, Stack } from '@mui/material';
import {
  MUNDO_REDEAS_DEFAULT_DATA,
} from '../../default-data';

const useStyles = makeStyles({
  iframe: { width: "100%", height: "auto", aspectRatio: "16/9", maxWidth: "850px" }
});

export default function MundoRedeas(props) {
  const { helmetContext } = props;
  const classes = useStyles();

  return (
    <>
      <Helmet>
        <title>{`Programa Mundo Rédeas | ${helmetContext.title}`}</title>
        <meta name="description" content="Programas Mundo Rédeas" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, Programa Mundo Rédeas, YouTube" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={5}
        sx={{ mt: 1, mb: 0, }}
      >

        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography component='h1' variant='h1'>Programa Mundo Rédeas</Typography>
          <Typography component='p' sx={{ mb: 4 }}>
            Programa oficial da Associação Nacional do Cavalo de Rédeas
          </Typography>
          <YouTube
            videoId={MUNDO_REDEAS_DEFAULT_DATA[0].videoId}
            title={MUNDO_REDEAS_DEFAULT_DATA[0].titulo}
            // style={{ width: "100%", height: "auto", aspectRatio: "16/9", maxWidth: "850px" }}
            iframeClassName={classes.iframe}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography component='h2' variant='h2'>Outras Edições</Typography>
        </Grid>

        {MUNDO_REDEAS_DEFAULT_DATA.map((edicao, index) =>
          index === 0
            ? null
            : <Grid key={edicao.videoId} item xs={12} md={6}>
              <YouTube
                videoId={edicao.videoId}
                iframeClassName={classes.iframe}
                title={edicao.titulo}
              />
              <Typography component='p'>
                {edicao.titulo}
              </Typography>
            </Grid>
        )}

        <Grid item xs={12}><Stack spacing={2} alignItems='center'><Pagination count={1} shape="rounded" /></Stack></Grid>
      </Grid>
    </>
  );
};