import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getIntParam,
} from '../functions';

const RedirectRankingMenuTemporada = () => {
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const temporada = getIntParam(params, 'temporada');

  const navigate = useNavigate();

  useEffect(() => {

    if (temporada) {
      navigate({ pathname: `/ranking?temporada=${temporada}` });
    } else {
      navigate({ pathname: `/ranking/` });
    }
  }, [navigate, temporada])

  return (
    <Alert>Aguarde, estamos redirecionando</Alert>
  )
};
export default RedirectRankingMenuTemporada;
