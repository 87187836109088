import React from 'react';
import Typography from '@mui/material/Typography';
import {
  // MOST_VIEWED_HORSES_TEST_DATA,
} from "../../test-data";
import { useGetData } from "../../axiosHook/useRequestData";
import {
  // formatDateStringWithSlashes,
} from "../../functions";
import { Alert } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Link from '@mui/material/Link';
import BasicDataTable from '../BasicDataTable';
import moment from 'moment';

const alignColumns = [
  'left',
  'right',
  'right',
];

export default function MostViewedHorseAndRiderTable(props) {
  const { horses } = props;

  const [tableData = [], loading, { error }] = useGetData(`/campanhas/${horses ? 'top_animals' : 'top_competitors'}`);

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  const basicTableColumns = horses
    ? [
      'Nome',
      'Registro',
      'Nascimento',
    ]
    : [
      'Nome',
      'Cidade',
      'Estado',
    ];

  const tableRows = (horses, tableData) => {
    if (!tableData['hydra:member']) return [];

    return tableData['hydra:member'].map(data => {
      const link = (horses ? '/animais/' : '/competidores/') + data.id;

      let row = [];
      row.push(<Link href={link} color="primary">{data.nome}</Link>);
      row.push(<Link href={link} color="primary">{(horses ? data.registro : data.cidade) ?? ''}</Link>);
      row.push(<Link href={link} color="primary">{(horses ? moment(data.nascimento, "YYYY-MM-DD[T]HH:mm:ss").format('DD/MM/YYYY') : data.estado) ?? ''}</Link>);
      return row;
    });
  };

  return (
    tableData['hydra:member']?.length
      ? <BasicDataTable
        title={`Os ${horses ? 'animais' : 'competidores'} mais pesquisados ultimamente`}
        paper={true}
        headers={basicTableColumns}
        alignColumns={alignColumns}
        rows={tableRows(horses, tableData)}
        striped={true}
      />
      : <Typography variant="p" align="center">{`Nenhum ${horses ? 'animal' : 'competidor'} localizado`}</Typography>
  );
}