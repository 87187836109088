import React from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const RHookFormCheckbox = (props) => {
  const {
    name,
    rules,
    label,
    className,
    helperText,
    inputProps,
    disabled,
    onChange,
    sx,
  } = props;

  const {
    control,
  } = useFormContext();

  return (
    <React.Fragment>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field: { value, onChange: controllerOnChange } }) =>
          <FormControlLabel
            control={<Checkbox
              checked={value}
              onChange={onChange ? onChange : controllerOnChange}
              disabled={disabled}
              className={className}
              sx={sx}
              inputProps={inputProps}
            />}
            label={label}
          />}
      />
      <FormHelperText>{helperText}</FormHelperText>
    </React.Fragment>

  );
}

export default RHookFormCheckbox;
