import React, { useState } from 'react'
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack'
import { Divider, FormHelperText } from '@mui/material';
import { useForm, FormProvider } from "react-hook-form";
import {
  DEBUG_FORM_DATA,
  MIN_LENGTH_NOME_USUARIO,
  MAX_LENGTH_NOME_USUARIO,
  MIN_LENGTH_SENHA_USUARIO,
  MAX_LENGTH_SENHA_USUARIO,
} from "../../constants";
import { axiosInstance as axios } from "../../axiosHook/axiosInstance";
import {
  // AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import { IconButton, InputAdornment, LinearProgress, Link } from '@mui/material';
import RHookFormTextField from '../../form/RHookFormTextField';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import {
  jwtToObject,
} from '../../functions';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const defaultValues = {
  username: "",
  password: "",
};


const NormalLoginForm = (props) => {
  const { save, setModalMessage, setModalTitle, setModalOpen, setModalNavigateUrlOnClose, setModalClearSessionOnClose } = props;

  let navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });


  const {
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmittingRHF },
    watch,
    reset,
    control,
  } = methods;

  const onSubmit = async data => {
    setIsSubmitting(true);
    // console.log(data);
    axios
      .post(
        `/login_check`,
        data,
      )
      .then((response) => {
        const responseData = response.data;
        if (responseData.token) {
          reset(); // reset form
          // get member full name and id
          axios
            .get(
              `/users?userIdentifier=${data.username}`,
              {
                headers: {
                  'Authorization': `Bearer ${responseData.token}`
                },
              })
            .then((response) => {
              const memberData = response.data;
              if (memberData["hydra:member"]) {
                // save to local storage with token
                save({
                  ...jwtToObject(responseData.token),
                  nome: memberData["hydra:member"][0]?.afiliado?.nome,
                  id: memberData["hydra:member"][0]?.afiliado?.id,
                  token: responseData.token,
                })
              } else {
                console.log(memberData);
                setModalTitle("Error");
                setModalMessage({
                  type: "error",
                  message: memberData,
                });
              }
            })
            .catch((error) => {
              console.log("error", error);
              setModalTitle("Error");
              setModalMessage({
                type: "error",
                message: error.response?.data?.detail ?? error.message,
              });
              setModalNavigateUrlOnClose("");
              setModalClearSessionOnClose(true); // remove session, so can try logging in again
              setModalOpen(true);
            });
        } else {
          console.log(responseData);
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setModalTitle("Error");
          if (error.response.data?.message === "Too many failed login attempts, please try again in 1 minute.") {
            setModalMessage({
              type: "error",
              message: "Número de tentativas esgotado. Tente novamente em 1 minuto.",
            });
          } else {
            setModalMessage({
              type: "error",
              message: "A combinação de nome e senha não está correta. Tente novamente.",
            });
          }
        } else {
          console.log("error", error);
          setModalTitle("Error");
          setModalMessage({
            type: "error",
            message: error.response?.data?.detail ?? error.message,
          });
        }
        setModalNavigateUrlOnClose("");
        setModalClearSessionOnClose(false);
        setModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  return (
    <>
      <Typography variant="h2">
        Digite seu login e senha
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RHookFormTextField
            name="username"
            label="Login"
            sx={{ mt: 1 }}
            fullWidth
            inputProps={{ maxLength: MAX_LENGTH_NOME_USUARIO }}
            rules={{ minLength: MIN_LENGTH_NOME_USUARIO, maxLength: MAX_LENGTH_NOME_USUARIO }}
            errorMessages={
              [{
                type: "minLength",
                message: `O nome de usuário deve ter no mínimo ${MIN_LENGTH_NOME_USUARIO} caracteres!`,
              }, {
                type: "maxLength",
                message: `O nome de usuário deve ter no maximo ${MAX_LENGTH_NOME_USUARIO} caracteres!`,
              }]}
            helperText=" "
          />

          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <RHookFormTextField
                name="password"
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                sx={{ mt: 1 }}
                fullWidth
                inputProps={{ maxLength: MAX_LENGTH_SENHA_USUARIO }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size='small'
                      >
                        {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                    </InputAdornment>
                }}
                rules={{ required: true, minLength: MIN_LENGTH_SENHA_USUARIO, maxLength: MAX_LENGTH_SENHA_USUARIO }}
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário digitar a senha!",
                  }, {
                    type: "minLength",
                    message: `A senha deve ter no mínimo ${MIN_LENGTH_SENHA_USUARIO} caracteres!`,
                  }, {
                    type: "maxLength",
                    message: `A senha deve ter no maximo ${MAX_LENGTH_SENHA_USUARIO} caracteres!`,
                  }]}
              />
            </Grid>
          </Grid>

          <LinearProgress sx={{
            pt: 1,
            opacity: isSubmittingRHF ? "1" : "0",
          }} />

          <Stack
            direction="row"
            justifyContent="flex-start"
            divider={<Divider orientation="vertical" flexItem />}
            alignItems="center"
            spacing={2}
          ><Button variant="contained" color="secondary" sx={{ px: 4 }} type="submit" disabled={isSubmitting}>Enviar</Button>
            <Typography element="p" variant="body1"><Link href="/afiliar">Ainda não é sócio? Clique aqui</Link></Typography>
          </Stack>
          <Button variant="outlined" fullWidth sx={{ mt: 3 }} onClick={() => navigate('/login?type=2&rt=')}>Esqueci minha senha</Button>
          <FormHelperText>Caso não lembrar seu login ou sua senha</FormHelperText>
        </form>
        {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
      </FormProvider>
    </>
  );
};

export default NormalLoginForm;