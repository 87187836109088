import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RedirectMemberServices = () => {
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const listar = params.has('listar') ? params.get('listar') : null;

  const navigate = useNavigate();

  useEffect(() => {

    if (listar === 'treinadores') {
      navigate({ pathname: `/treinadores` });
    } else {
      navigate({ pathname: `/juizes` });
    }
  }, [navigate, listar])

  return (
    <Alert>Aguarde, estamos redirecionando</Alert>
  )
};
export default RedirectMemberServices;
