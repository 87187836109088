import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { useForm, FormProvider } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import {
  NOME_ASSOCIACAO,
  MAX_LENGTH_NOME_ASSOCIADO,
  DEBUG_FORM_DATA,
} from "../../constants";
import { useGetData } from "../../axiosHook/useRequestData";
import { axiosInstance as axios } from "../../axiosHook/axiosInstance";
import Endereco from '../Endereco';
import {
  ESTADOS_DEFAULT_DATA,
} from '../../default-data';
import {
  errorResponseMessage,
} from '../../functions';
import { LinearProgress } from '@mui/material';
import AlertDialog from '../AlertDialog';
import RHookFormTextField from '../../form/RHookFormTextField';
import RHookFormSelect from '../../form/RHookFormSelect';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const defaultValues = {
  nome: "",
  telefone: "",
  email: "",
  estado: "",
  mensagem: "",
};


export default function Contato(props) {
  const { helmetContext, enderecoAssociacao } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmittingRHF },
    watch,
    reset,
    control,
  } = methods;

  const onSubmit = async data => {
    setIsSubmitting(true);
    axios
      .post(`/contatos`, data)
      .then((response) => {
        const data = response.data;
        if (data.id) {
          setModalTitle("Mensagem enviada com sucesso");
          setModalMessage("Obrigado por enviar sua mensagem. Responderemos em breve.");
          setModalOpen(true);
          reset(); // reset form
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setModalTitle("Error");
        setModalMessage({
          type: "error",
          message: errorResponseMessage(error),
        });
        setModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const [estados = ESTADOS_DEFAULT_DATA] = useGetData('/estados');

  return <>
    <Helmet>
      <title>{`Contate-nos | ${helmetContext.title}`}</title>
      <meta name="description" content="Entre em contato com a ANCR" />
      <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, contato, fale com, telefone, fale com a gente" />
    </Helmet>
    <ScrollToTopOnMount />

    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={5}
      sx={{ pt: 1, mb: 0 }}
    >
      <Grid item md={7}>

        <Typography variant="h2">Entre em contato com a gente</Typography>
        <Typography component='p'>Utilize o formulário abaixo para entrar em contato conosco. Responderemos o mais rápido possível.</Typography>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RHookFormTextField
              name="nome"
              label="Nome"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: MAX_LENGTH_NOME_ASSOCIADO }}
              rules={{ required: true, minLength: 3, pattern: /^[\sA-Za-z\u00C0-\u017F.-]+$/i }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar seu nome!",
                }, {
                  type: "minLength",
                  message: "Favor informar seu nome completo!",
                }, {
                  type: "pattern",
                  message: "Seu nome não pode incluir números ou characteres especiais!",
                }]}
            />

            <RHookFormTextField
              name="telefone"
              label="Telefone"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 40 }}
              rules={{ required: true, minLength: 3, pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/ }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar um telefone válido!",
                }, {
                  type: "minLength",
                  message: "Favor informar um telefone válido!",
                }, {
                  type: "pattern",
                  message: "Favor, digite seu telefone no formato (12) 98765-4321!",
                }]}
            />

            <RHookFormTextField
              name="email"
              label="Email"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 80 }}
              rules={{ required: true, minLength: 3, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar um email válido!",
                }, {
                  type: "minLength",
                  message: "É necessário informar um email válido!",
                }, {
                  type: "pattern",
                  message: "É necessário informar um email válido!",
                }]}
            />

            <RHookFormSelect
              name="estado"
              label="Estado"
              sx={{ mt: 1 }}
              fullWidth
              formHelperTextErrorStyles={{
                color: "#f44336",
              }}
              rules={{ required: true }}
              options={estados["hydra:member"]?.map(estado => {
                return {
                  value: estado.uf,
                  label: `${estado.uf} - ${estado.nome}`,
                };
              })}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário selecionar um estado!",
                }]}
            />

            <RHookFormTextField
              name="mensagem"
              label="Mensagem"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 1100 }}
              multiline
              minRows={8}
              rules={{ required: true, maxLength: 1000 }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar sua mensagem!",
                }, {
                  type: "maxLength",
                  message: "Mensagem muito comprida! Favor resumir.",
                }]}
            />



            <LinearProgress
              sx={{
                pt: 1,
                opacity: isSubmittingRHF ? "1" : "0",
              }}
            />

            <Button variant="contained" color="secondary" sx={{ px: 2 }} type="submit" disabled={isSubmitting}>Enviar Mensagem</Button>

          </form>
          {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
        </FormProvider>



      </Grid>
      <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Typography variant="h2">{NOME_ASSOCIACAO}</Typography>
        <Typography component='p'>
          <Endereco endereco={enderecoAssociacao} linear={false} narrow={true} />
        </Typography>
      </Grid>
    </Grid>
    <AlertDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} message={modalMessage} />
  </>;

}