import React from 'react';
import {
  TextField
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const RHookFormTextField = (props) => {
  const {
    id,
    name,
    label,
    className,
    fullWidth,
    rules,
    helperText,
    errorMessages,
    inputProps,
    InputProps,
    disabled,
    multiline,
    maxRows,
    minRows,
    readOnly,
    sx,
    type,
  } = props;

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const helperTextMessage = () => {
    if (!errorMessages) return helperText ?? '';
    if (errors?.[name]) {
      const errorMessage = errorMessages.find(errorArray => errors[name].type === errorArray.type);
      if (!errorMessage) return helperText ?? '';
      return errorMessage.message;
    }

    // Default to a space character so the spacing does not change when an error message is shown 
    return helperText ?? ' ';
  }

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field }) => <TextField {...field}
        label={label}
        id={id}
        className={className}
        variant="standard"
        fullWidth={fullWidth}
        inputProps={inputProps}
        InputProps={InputProps}
        error={!!errors?.[name]}
        helperText={helperTextMessage()}
        disabled={disabled}
        readOnly={readOnly}
        multiline={multiline}
        minRows={minRows}
        maxRows={maxRows}
        sx={sx}
        type={type}
      />}
    />
  );
}

export default RHookFormTextField;
