import React from 'react';
import { Grid, Typography, Stack, Button, Divider } from "@mui/material";

const EntryFormHeaderRow = ({ session, headerText, baiaExtra }) =>
  <Grid
    container
    direction="row"
  >
    <Grid item xs={6}>
      <Typography variant="h2">{headerText}</Typography>
    </Grid>
    <Grid item xs={6}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="flex-end"
        alignItems="flex-start"
        divider={<Divider sx={{ display: { xs: 'none', md: 'block' } }} orientation="vertical" flexItem />}
        spacing={2}
      >{(session?.inscricoes || session?.baiasExtras)
        ? <Button variant="outlined" fullWidth href="/efetuar">Pagamento</Button>
        : null}
        {baiaExtra
          ? <Button variant="outlined" fullWidth href="/inscricoes">Inscrição</Button>
          : <Button variant="outlined" fullWidth href="/baiaextra">Baia extra</Button>
        }
      </Stack>
    </Grid>
  </Grid>
  ;

export default EntryFormHeaderRow;