import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { useForm, FormProvider } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import {
  MAX_LENGTH_NOME_ASSOCIADO,
  MIN_LENGTH_NOME_ASSOCIADO,
  DEBUG_FORM_DATA,
} from "../../constants";
import { useGetData } from "../../axiosHook/useRequestData";
import { axiosInstance as axios } from "../../axiosHook/axiosInstance";
import {
  ESTADOS_DEFAULT_DATA,
  COUNTRIES_DEFAULT_DATA,
  COMPETITOR_CLASSES_DEFAULT_DATA,
  AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import { LinearProgress } from '@mui/material';
import AlertDialog from '../AlertDialog';
import RHookFormTextField from '../../form/RHookFormTextField';
import RHookFormSelect from '../../form/RHookFormSelect';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import RHookFormDateField from '../../form/RHookFormDateField';
import RHookFormRadio from '../../form/RHookFormRadio';
import {
  isValidDateAndNotFuture,
} from '../../functions';
import ScrollToTopOnMount from '../ScrollToTopOnMount';



const formaCadastro = [
  {
    id: 1,
    descricao: "Cadastrado",
  }, {
    id: 2,
    descricao: "Associado Proprietário",
  }, {
    id: 3,
    descricao: "Associado Competidor/Proprietário",
  }, {
    id: 4,
    descricao: "Associado Contribuinte",
  }
];

const defaultValues = {
  nome: "",
  endereco: "",
  bairro: "",
  cidade: "",
  estado: "",
  cep: "",
  pais: 1,
  telefone: "",
  celular: "",
  cpf: "",
  passaporte: "",
  rg: "",
  email: "",
  nascimento: null,
  formaCadastro: "",
  categoriaCompetidor: "",
  representa: "",
};

const styles = {
  formHelperTextError: {
    color: "#f44336",
  },

};

const NewMember = (props) => {
  const { helmetContext } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmittingRHF },
    watch,
    reset,
    control,
  } = methods;

  const onSubmit = async data => {
    // alert(JSON.stringify(data, null, 2));
    setIsSubmitting(true);
    axios
      .post(`/afiliar`, data)
      .then((response) => {
        const data = response.data;
        if (data.id) {
          setModalTitle("Cadastro recebido com sucesso");
          setModalMessage("Você receberá um email de confirmação.");
          setModalOpen(true);
          reset(); // reset form
        } else {
          console.log(data);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setModalTitle("Error");
        setModalMessage({
          type: "error",
          message: error.response?.data?.detail ?? error.message,
        });
        setModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const [estados = ESTADOS_DEFAULT_DATA] = useGetData('/estados');
  const [paises = COUNTRIES_DEFAULT_DATA] = useGetData('/paises');
  const idBrasil = paises["hydra:member"]?.find(pais => pais?.codigoAlfa3 === "BRA")?.id;

  const [categoriaCompetidor = COMPETITOR_CLASSES_DEFAULT_DATA] = useGetData('/categorias_competidores');
  const [campeonatos = AFFILIATE_LIST_DEFAULT_DATA] = useGetData('/campeonatos');

  const pais = watch("pais");

  return <>
    <Helmet>
      <title>{`Afilie-se | ${helmetContext.title}`}</title>
      <meta name="description" content="Afilie se à ANCR" />
      <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, contato, fale com, telefone, fale com a gente" />
    </Helmet>
    <ScrollToTopOnMount />

    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={5}
      sx={{ pt: 1, mb: 0 }}
    >
      <Grid item xs={12} md={7}>

        <Typography variant="h2">
          Seja bem-vindo a nossa associação!
        </Typography>
        <Typography variant="p">
          Preencha todos os campos abaixo
        </Typography>

        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <RHookFormTextField
              name="nome"
              label="Nome"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: MAX_LENGTH_NOME_ASSOCIADO }}
              rules={{ required: true, minLength: MIN_LENGTH_NOME_ASSOCIADO, pattern: /^[\sA-Za-z\u00C0-\u017F.'-]+$/i }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar seu nome!",
                }, {
                  type: "minLength",
                  message: "Favor informar seu nome completo!",
                }, {
                  type: "pattern",
                  message: "Seu nome não pode incluir números ou characteres especiais!",
                }]}
            />

            <RHookFormTextField
              name="endereco"
              label="Endereço"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 100 }}
              rules={{ required: true, minLength: 3, pattern: /^[\s0-9A-Za-z\u00C0-\u017F,./-]+$/i }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar seu endereço!",
                }, {
                  type: "minLength",
                  message: "Favor informar seu endereço completo!",
                }, {
                  type: "pattern",
                  message: "O endereço não pode incluir characteres especiais!",
                }]}
            />

            <RHookFormTextField
              name="bairro"
              label="Bairro"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 50 }}
              rules={{ required: false, pattern: /^[\s0-9A-Za-z\u00C0-\u017F,.-]+$/i }}
              errorMessages={
                [{
                  type: "pattern",
                  message: "O endereço não pode incluir characteres especiais!",
                }]}
            />

            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <RHookFormTextField
                  name="cidade"
                  label="Cidade"
                  sx={{ mt: 1 }}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  rules={{ required: true, minLength: 3, pattern: /^[\s0-9A-Za-z\u00C0-\u017F,.-]+$/i }}
                  errorMessages={
                    [{
                      type: "required",
                      message: "É necessário informar a cidade!",
                    }, {
                      type: "minLength",
                      message: "Favor informar o nome completo da cidade!",
                    }, {
                      type: "pattern",
                      message: "O nome da cidade não pode incluir characteres especiais!",
                    }]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHookFormSelect
                  sx={{ mt: 1 }}
                  fullWidth
                  formHelperTextErrorStyles={styles.formHelperTextError}
                  name="estado"
                  label="Estado"
                  rules={{ required: true }}
                  options={estados["hydra:member"]?.map(estado => {
                    return {
                      value: estado.uf,
                      label: `${estado.uf} - ${estado.nome}`,
                    };
                  })}
                  errorMessages={
                    [{
                      type: "required",
                      message: "É necessário selecionar um estado!",
                    }]}
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <RHookFormTextField
                  name="cep"
                  label="CEP"
                  sx={{ mt: 1 }}
                  fullWidth
                  inputProps={{ maxLength: 9 }}
                  rules={{ required: true, minLength: 3, pattern: /^[\s0-9A-Za-z\u00C0-\u017F.-]+$/i }}
                  errorMessages={
                    [{
                      type: "required",
                      message: "É necessário informar o CEP!",
                    }, {
                      type: "minLength",
                      message: "Favor informar o CEP completo!",
                    }, {
                      type: "pattern",
                      message: "O CEP não pode incluir characteres especiais!",
                    }]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHookFormSelect
                  sx={{ mt: 1 }}
                  fullWidth
                  formHelperTextErrorStyles={styles.formHelperTextError}
                  name="pais"
                  label="País"
                  rules={{ required: true }}
                  options={paises["hydra:member"]?.map(pais => {
                    return {
                      value: pais.id,
                      label: pais.nome,
                    };
                  })}
                  errorMessages={
                    [{
                      type: "required",
                      message: "É necessário selecionar um país!",
                    }]}
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <RHookFormTextField
                  name="telefone"
                  label="Telefone"
                  sx={{ mt: 1 }}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  rules={{ required: false, pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/ }}
                  errorMessages={
                    [{
                      type: "pattern",
                      message: "Favor, digite seu telefone no formato (12) 98765-4321!",
                    }]}
                  helperText=" "
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHookFormTextField
                  name="celular"
                  label="Celular"
                  sx={{ mt: 1 }}
                  fullWidth
                  inputProps={{ maxLength: 50 }}
                  rules={{ required: true, minLength: 3, pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/ }}
                  errorMessages={
                    [{
                      type: "required",
                      message: "É necessário informar um celular válido!",
                    }, {
                      type: "minLength",
                      message: "Favor informar um celular válido!",
                    }, {
                      type: "pattern",
                      message: "Favor, digite seu celular no formato (12) 98765-4321!",
                    }]}
                  helperText=" "
                />
              </Grid>
            </Grid>

            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                {pais !== idBrasil
                  ? <RHookFormTextField
                    name="passaporte"
                    label="Número do passaporte"
                    sx={{ mt: 1 }}
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                    rules={{ required: true, minLength: 7, pattern: /^[\s0-9A-Za-z\u00C0-\u017F.-]+$/i }}
                    errorMessages={
                      [{
                        type: "required",
                        message: "É necessário informar o número do passaporte!",
                      }, {
                        type: "minLength",
                        message: "Favor informar o número do passaporte completo!",
                      }, {
                        type: "pattern",
                        message: "O número não pode incluir caracteres especiais!",
                      }]}
                  />
                  : <RHookFormTextField
                    name="cpf"
                    label="CPF"
                    sx={{ mt: 1 }}
                    fullWidth
                    inputProps={{ maxLength: 20 }}
                    rules={{ required: true, minLength: 11, pattern: /^[\s0-9A-Za-z\u00C0-\u017F.-]+$/i }}
                    errorMessages={
                      [{
                        type: "required",
                        message: "É necessário informar seu CPF!",
                      }, {
                        type: "minLength",
                        message: "Favor informar o CPF completo!",
                      }, {
                        type: "pattern",
                        message: "O CPF não pode incluir characteres especiais!",
                      }]}
                  />
                }
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHookFormTextField
                  name="rg"
                  label={pais === idBrasil ? "RG" : "Documento nacional"}
                  sx={{ mt: 1 }}
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                  rules={{ required: true, minLength: 5, pattern: /^[\s0-9A-Za-z\u00C0-\u017F/.-]+$/i }}
                  errorMessages={
                    [{
                      type: "required",
                      message: `É necessário informar seu ${pais === idBrasil ? "RG" : "documento nacional"}!`,
                    }, {
                      type: "minLength",
                      message: `Favor informar o ${pais === idBrasil ? "RG" : "documento nacional"} completo!`,
                    }, {
                      type: "pattern",
                      message: `O ${pais === idBrasil ? "RG" : "documento nacional"} não pode incluir caracteres especiais!`,
                    }]}
                />
              </Grid>
            </Grid>

            <RHookFormTextField
              name="email"
              label="Email"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 80 }}
              rules={{ required: true, minLength: 3, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar um email válido!",
                }, {
                  type: "minLength",
                  message: "É necessário informar um email válido!",
                }, {
                  type: "pattern",
                  message: "É necessário informar um email válido!",
                }]}
            />

            <Grid container columnSpacing={2}>
              <Grid item xs={12} sm={6}>
                <RHookFormDateField
                  name="nascimento"
                  sx={{ mt: 1 }}
                  fullWidth
                  label="Nascimento"
                  rules={{ required: true, validate: isValidDateAndNotFuture }}
                  error={!!errors?.nascimento}
                  formHelperTextErrorClassName="formError"
                  errorMessages={
                    [{
                      type: "required",
                      message: "É necessário informar seu nascimento!",
                    }, {
                      type: "validate",
                      message: `É necessário informar um nascimento válido!`,
                    }]}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <RHookFormSelect
                  sx={{ mt: 1 }}
                  fullWidth
                  formHelperTextErrorStyles={styles.formHelperTextError}
                  name="categoriaCompetidor"
                  label="Competidor"
                  rules={{ required: true }}
                  options={categoriaCompetidor["hydra:member"]?.map(opcao => {
                    return {
                      value: opcao.id,
                      label: opcao.descricao,
                    };
                  })}
                  errorMessages={
                    [{
                      type: "required",
                      message: "É necessário selecionar uma opção!",
                    }]}
                />
              </Grid>
            </Grid>

            <RHookFormRadio
              sx={{ mt: 1 }}
              fullWidth
              formHelperTextErrorClassName="formSelectErrorMessage"
              name="formaCadastro"
              label="Forma de Cadastro"
              rules={{ required: true }}
              options={formaCadastro.map(forma => {
                return {
                  value: forma.id.toString(),
                  label: forma.descricao,
                };
              })}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário selecionar uma opção!",
                }]}
            />
            <Typography component='p'>
              <small>
                Selecione 'Cadastrado' para receber notícias e informações ou 'Associado'
                para tornar-se parte de nossa associação e participar em eventos oficiais.
              </small>
            </Typography>

            <RHookFormSelect
              sx={{ mt: 1 }}
              fullWidth
              formHelperTextErrorStyles={styles.formHelperTextError}
              name="representa"
              label="Representa"
              rules={{ required: true }}
              options={campeonatos["hydra:member"]?.map(opcao => {
                return {
                  value: opcao.id,
                  label: opcao.nucleo,
                };
              })}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário selecionar um núcleo!",
                }]}
            />

            <LinearProgress sx={{
              pt: 1,
              opacity: isSubmittingRHF ? "1" : "0",
            }} />

            <Button variant="contained" color="secondary" sx={{ px: 2 }} type="submit" disabled={isSubmitting}>Enviar</Button>

          </form>
          {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
        </FormProvider>



      </Grid>
      <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
      </Grid>
    </Grid>
    <AlertDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} message={modalMessage} />
  </>;

};

export default NewMember;