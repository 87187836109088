import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const RedirectEventos = (props) => {
  const { ordemEntrada, resultados, fichasJulgamento, videos, fotos } = props;
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.has('IDEvento') ? parseInt(params.get('IDEvento')) : null;

  let idCategoria = null;
  const hash = window.location.hash;
  if (hash) {
    if (hash.includes("IDCat")) {
      if (hash.includes("IDClassificatoria")) {
        idCategoria = parseInt(hash.split("IDClassificatoria")[0].replace(/#IDCat/g, ''));
      } else {
        if (hash.includes("IDSubCategoria")) {
          idCategoria = parseInt(hash.split("IDSubCategoria")[0].replace(/#IDCat/g, ''));
        } else {
          idCategoria = parseInt(hash.replace(/#IDCat/g, ''));
        }
      }
    }
  }
  const navigate = useNavigate();

  useEffect(() => {
    const pagina = () => {
      if (resultados) return 'resultados';
      if (fichasJulgamento) return 'fichasjulgamento';
      if (ordemEntrada) return 'ordementrada';
      if (videos) return 'videos';
      if (fotos) return 'fotos';
      return null;
    }

    if (id) {
      if (pagina()) {
        if (idCategoria) {
          navigate({ pathname: `/eventos/${id}/${pagina()}/${idCategoria}` });
        } else {
          navigate({ pathname: `/eventos/${id}/${pagina()}` });
        }
      } else {
        navigate({ pathname: `/eventos/${id}` });
      }
    } else {
      if (!id) {
        if (resultados) {
          navigate({ pathname: `/eventos?resultados=true&order=desc` });
        } else {
          if (fotos) {
            navigate({ pathname: `/eventos?fotos=true&order=desc` });
          } else {
            if (videos) {
              navigate({ pathname: `/eventos?videos=true&order=desc` });
            } else {
              navigate({ pathname: `/eventos/` });
            }
          }
        }
      }
    }
  }, [navigate, id, resultados, idCategoria, fichasJulgamento, ordemEntrada, videos, fotos])

  return (
    <Alert>Aguarde, estamos redirecionando</Alert>
  )
};
export default RedirectEventos;
