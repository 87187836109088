import React from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DefaultThumb from "../../images/newsDefaultThumb.jpg";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import { Box, CircularProgress } from "@mui/material";
import { Alert } from "@mui/material";
import { NEWS_ITENS_NA_PAGINA_INICIAL } from "../../constants";
import {
  formatDateStringWithSlashes,
  getAttrFromString,
  redirectRelativeImageLinks,
} from "../../functions";
import { useGetData } from "../../axiosHook/useRequestData";

const topSpace = {
  pt: 2,
  mb: 0,
};

function findThumb(text) {
  const listOfImages = getAttrFromString(text, "img", "src");
  const thumbUrl =
    listOfImages.length > 0
      ? redirectRelativeImageLinks(listOfImages[0])
      : DefaultThumb;

  return thumbUrl;
}

export default function HomeNews() {
  let navigate = useNavigate();

  const [data = [], loading, { error }] = useGetData(
    `/noticias?page=1&itemsPerPage=${NEWS_ITENS_NA_PAGINA_INICIAL}`
  );
  const news = data["hydra:member"] ? data["hydra:member"] : data;

  if (error) return <Alert severity="error">{`${error}`}</Alert>;
  if (loading) return <CircularProgress />;
  if (!news.length)
    return <Alert severity="error">Nenhuma notícia localizada!</Alert>;

  return (
    <>
      {news.length > 0 ? (
        <>
          <Grid item md={4}>
            <Link href={`noticias/${news[0].id}`}>
              <Card>
                <CardMedia
                  sx={{
                    width: "100%",
                    aspectRatio: "4/3",
                  }}
                  component="img"
                  alt={news[0]?.title}
                  image={findThumb(news[0]?.texto)}
                  title={news[0]?.title}
                />
              </Card>
            </Link>
          </Grid>
          <Grid item sm={12} md={4}>
            <Link href={`noticias/${news[0].id}`}>
              <Typography variant="h2" sx={topSpace}>
                {news[0]?.titulo}
              </Typography>
              <Box
                component="span"
                sx={{
                  fontSize: "0.75em",
                  color: "#b5b5b5",
                  p: "0",
                }}
              >{`${formatDateStringWithSlashes(news[0]?.dataNoticia)}`}</Box>
              {news[0].chamada !== "" ? (
                <Typography variant="body1">
                  {news[0].chamada}
                </Typography>
              ) : (
                ""
              )}
            </Link>
          </Grid>

          <Grid item sm={12} md={4}>
            {news.length > 0 ? (
              <>
                {news.map((article, index) =>
                  index > 0 ? (
                    index !== news.length - 1 ? (
                      <React.Fragment key={index}>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "repeat(3, 1fr)",
                            gridGap: "8px",
                            paddingBottom: "8px",
                          }}
                        >
                          <Link href={`noticias/${article.id}`}>
                            <Card
                              key={index}
                              sx={{
                                width: "100%",
                                aspectRatio: "4/3",
                              }}
                            >
                              <CardMedia
                                sx={{
                                  width: "100%",
                                  aspectRatio: "4/3",
                                }}
                                component="img"
                                alt={article?.title}
                                image={findThumb(article?.texto)}
                                title={article?.title}
                              />
                            </Card>
                          </Link>
                          <div style={{ gridColumn: "span 2" }}>
                            <Link
                              href={`noticias/${article.id}`}
                            >
                              <Typography variant="h3" sx={{ pb: 0, pt: 0 }}>
                                {article.titulo}
                              </Typography>

                              <Box
                                component="span"
                                sx={{
                                  fontSize: "0.75em",
                                  color: "#b5b5b5",
                                  p: "0",
                                }}
                              >
                                {`${formatDateStringWithSlashes(
                                  article.dataNoticia
                                )}`}
                              </Box>
                              <Box
                                component="span"
                                sx={{
                                  fontSize: "0.75em",
                                  color: "#b5b5b5",
                                  p: "0",
                                  display: {
                                    xs: 'block',
                                    md: 'none',
                                  },
                                }}
                              >
                                {article.chamada}
                              </Box>
                            </Link>
                          </div>
                        </div>
                        <Divider sx={{ m: "8px !important" }} />
                      </React.Fragment>
                    ) : (
                      <div
                        style={{
                          display: "grid",
                          gridTemplateColumns: "repeat(3, 1fr)",
                          gridGap: "8px",
                          paddingBottom: "8px",
                        }}
                        key={index}
                      >
                        <Card
                          sx={{
                            width: "100%",
                            aspectRatio: "4/3",
                          }}
                        >
                          <CardMedia
                            sx={{
                              width: "100%",
                              aspectRatio: "4/3",
                            }}
                            component="img"
                            alt={article?.title}
                            image={findThumb(article?.texto)}
                            title={article?.title}
                          />
                        </Card>

                        <div style={{ gridColumn: "span 2" }}>
                          <Link
                            key={article.id}
                            href={`noticias/${article.id}`}
                          >
                            <Typography
                              key={index}
                              variant="h3"
                              sx={{ pb: 0, pt: 0 }}
                            >
                              {article.titulo}
                            </Typography>
                            <Box
                              component="span"
                              sx={{
                                fontSize: "0.75em",
                                color: "#b5b5b5",
                                p: "0",
                              }}
                            >
                              {`${formatDateStringWithSlashes(
                                article.dataNoticia
                              )}`}
                            </Box>
                          </Link>
                        </div>
                      </div>
                    )
                  ) : (
                    ""
                  )
                )}

                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => navigate(`noticias/`)}
                >
                  + notícias
                </Button>
              </>
            ) : (
              <Alert severity="error">Nenhuma notícia localizada!</Alert>
            )}
          </Grid>
        </>
      ) : (
        <Grid item sm={12} md={3}>
          <CircularProgress />
        </Grid>
      )}
    </>
  );
}
