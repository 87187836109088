import React from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { Grid, CircularProgress, Box, Alert } from '@mui/material';
import { useGetData } from "../../axiosHook/useRequestData";
import {
  redirectRelativeImageLinks,
  bootstrap3ResponsiveTableWrapper,
} from "../../functions";
import EventLinks from './EventLinks';
import EventHeader from './EventHeader';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const alternatingFloatedImageStyles = {
  "& img:nth-of-type(odd)": {
    width: {
      xs: "98%",
      sm: "65%",
    },
    height: 'auto',
    margin: '2%',
    float: {
      xs: undefined,
      sm: 'left',
    }
  },
  "& img:nth-of-type(even)": {
    width: {
      xs: "98%",
      sm: "65%",
    },
    height: 'auto',
    margin: '2%',
    float: {
      xs: undefined,
      sm: 'right',
    }
  },
};

export default function Event(props) {
  const { helmetContext } = props;
  const params = useParams()
  const id = parseInt(params.id);

  const [event = {}, loading, { error }] = useGetData(`eventos/${id}`);

  if (!id || error?.response?.status === 404) return <Alert severity="error">Evento não localizado!</Alert>
  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  return (
    <>
      <Helmet>
        <title>{`${event?.nome} | ${helmetContext.title}`}</title>
        <meta name="description" content={event?.descricao} />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 1, mb: 0 }}
      >
        <Grid item xs={12} md={7}>
          {event.nome?.length > 0 ?
            <>
              <EventHeader event={event} />
              <EventLinks event={event} withHeader={false} sx={{ display: { xs: 'block', md: 'none' } }} />
              <Box
                dangerouslySetInnerHTML={{ __html: bootstrap3ResponsiveTableWrapper(redirectRelativeImageLinks(event?.texto)) }}
                sx={alternatingFloatedImageStyles}
              />
            </>
            :
            <CircularProgress />
          }
        </Grid>
        <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>

          <EventLinks event={event} withHeader={true} />

        </Grid>
      </Grid>
    </>
  )

}