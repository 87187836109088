import React from 'react';
import { useNavigate } from 'react-router-dom';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';

export default function SearchField(props) {
  const { searchField, setSearchField, getNextUrl } = props;

  let navigate = useNavigate();

  const handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    };
    navigate(getNextUrl(searchField));
  };

  return (
    <Paper component="form" sx={{
      padding: '2px 4px',
      display: 'flex',
      alignItems: 'center',
      minWidth: "250px",
    }}>
      <InputBase
        sx={{
          ml: 1,
          flex: 1,
        }}
        placeholder="Pesquisa"
        inputProps={{ 'aria-label': 'pesquisa' }}
        value={searchField}
        onChange={(e) => setSearchField(e.target.value)}
        onKeyPress={(e) => { return e.key === 'Enter' ? handleSubmit(e) : null }}
      />
      <IconButton
        type="submit"
        onClick={(e) => handleSubmit(e)}
        sx={{ p: 1 }}
        aria-label="search"
        size="large">
        <SearchIcon />
      </IconButton>
    </Paper>
  );
}
