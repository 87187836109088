import React from 'react'
import { DevTool } from "@hookform/devtools";

export const RHookFormDataDebugger = (props) => {
  const { watch, errors, isDirty, isValid, control } = props;

  return (
    <div>
      <pre>
        Form data:
        {JSON.stringify(watch, null, 2)}
        <br />
        Form errors:
        {JSON.stringify(errors, null, 2)}
        <br />
        Form isDirty:
        {JSON.stringify(isDirty, null, 2)}
        <br />
        Form isValid:
        {JSON.stringify(isValid, null, 2)}
      </pre>
      <DevTool control={control} />
    </div>
  )
}
