import React from 'react';
import RankingOptionsTable from './RankingOptionsTable';

const rows = [
  {
    name: 'Competidor',
    parametro: 'competidor=1',
    geral: true,
    aberta: true,
    amador: true,
  }, {
    name: 'Animal',
    parametro: 'animal=1',
    geral: true,
    aberta: true,
    amador: true,
  }, {
    name: 'Criador',
    parametro: 'criador=1',
    geral: true,
    aberta: true,
    amador: true,
  }, {
    name: 'Reprodutor',
    parametro: 'pai=1',
    geral: true,
    aberta: true,
    amador: true,
  }, {
    name: 'Reprodutora',
    parametro: 'mae=1',
    geral: true,
    aberta: true,
    amador: true,
  }, {
    name: 'Proprietário',
    parametro: 'proprietario=1',
    geral: true,
    aberta: true,
    amador: true,
  }, {
    name: 'Competidor Principiante',
    parametro: 'competidorprincipiante=1',
    geral: true,
    aberta: true,
    amador: true,
  }
];

export default function RankingTypeForSeasonSelector(props) {
  const { temporada } = props;

  const title = `RANKING - ANO HÍPICO ${temporada}/${temporada + 1}`;

  return (
    <RankingOptionsTable key={title} title={title} rows={rows} temporada={temporada} />
  );
}