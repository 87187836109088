import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getIntParam,
} from '../functions';

const RedirectCompetitorLevelList = () => {
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const aberta = getIntParam(params, 'aberta');

  const navigate = useNavigate();

  useEffect(() => {

    if (aberta !== null) {
      navigate({ pathname: `/niveis?aberta=${aberta}` });
    } else {
      navigate({ pathname: `/niveis/` });
    }
  }, [navigate, aberta])

  return (
    <Alert>Aguarde, estamos redirecionando</Alert>
  )
};
export default RedirectCompetitorLevelList;
