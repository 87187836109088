import React from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

export default function RankingOptionsTable(props) {
  const { title, rows, temporada, acumulado } = props;

  return (
    <>
      <ScrollToTopOnMount />
      <Typography variant="h2" align="center">{title}</Typography>

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: { xs: "100%", md: 650 } }} aria-label="simple table">
          <TableBody>
            {rows.map((row) => {
              const linkBase = `/ranking?${row.parametro}${temporada ? '&temporada=' + temporada : ''}${acumulado ? '&acumulado=1' : ''}`
              return (
                <TableRow
                  key={row.name}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell align="center">
                    {row.geral &&
                      <Link href={linkBase} color="primary">
                        GERAL
                      </Link>}
                    {row.aberta &&
                      <Link sx={{ display: { xs: 'block', sm: 'none' } }} href={linkBase + '&aberta=1'} color="primary">
                        ABERTA
                      </Link>}
                    {row.amador &&
                      <Link sx={{ display: { xs: 'block', sm: 'none' } }} href={linkBase + '&aberta=0'} color="primary">
                        AMADOR
                      </Link>}
                  </TableCell>
                  <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align="center">
                    {row.aberta &&
                      <Link href={linkBase + '&aberta=1'} color="primary">
                        ABERTA
                      </Link>}
                  </TableCell>
                  <TableCell sx={{ display: { xs: 'none', sm: 'table-cell' } }} align="center">
                    {row.amador &&
                      <Link href={linkBase + '&aberta=0'} color="primary">
                        AMADOR
                      </Link>}
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer >
      <br />
    </>
  );
}