import React from 'react'
import ReactDOM from 'react-dom'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider, StyledEngineProvider } from '@mui/material';
import Theme from './config/theme'
import "./styles/general.css"
import App from './layout/App'
import { HelmetProvider } from "react-helmet-async";
import {
  HELMET_CONTEXT,
} from './constants'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { BrowserRouter } from 'react-router-dom'



ReactDOM.render(
  <React.StrictMode>

    <StyledEngineProvider injectFirst>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={Theme}>
          <CssBaseline />
          <HelmetProvider context={HELMET_CONTEXT}>
            <BrowserRouter>
              <App helmetContext={HELMET_CONTEXT} />
            </BrowserRouter>
          </HelmetProvider>
        </ThemeProvider>
      </LocalizationProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
  document.getElementById('root')
);