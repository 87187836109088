import { CircularProgress, Typography } from '@mui/material';
import React, { useMemo, useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import {
  YOUTUBE_VIDEO_ADDRESS_ROOT,
} from '../../constants';
import _ from "lodash";
import {
  formatMoney,
  formatScores,
} from '../../functions';
// import {
//   RESULTADOS_NIVEL_4_TEST_DATA,
// } from "../test-data";
import IconButton from '@mui/material/IconButton';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoDialog from '../VideoDialog';
import { Alert } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from "@mui/material/Link";
import Box from '@mui/material/Box';

const headerCellStyles = {
  fontWeight: "bold",
};

const bunchHeaderStyle = {
  fontWeight: "bold",
};

const cellStyles = {
  py: 1,
  px: {
    xs: 0.3,
    sm: 0.5,
    md: 1,
  },
  fontSize: {
    xs: '0.8rem',
    sm: '0.9rem',
    md: '1.0em',
  },
};

function setParams({ ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais }) {
  const searchParams = new URLSearchParams();
  searchParams.set("idEvento", evento.id);
  searchParams.set("idCategoria", categoria.id);
  if (classificatoria) {
    searchParams.set("idClassificatoria", classificatoria.id);
  }
  if (subCategoria) {
    searchParams.set("idSubCategoria", subCategoria.id);
  }
  if (divisao) {
    searchParams.set("idDivisao", divisao.id);
  }
  if (light !== undefined) {
    searchParams.set("light", Number(light));
  }
  searchParams.set("resultados", Number(!ordemEntrada));
  if (naoIncluirResultadosExtraOficiais !== undefined) {
    searchParams.set("naoIncluirResultadosExtraOficiais", Number(naoIncluirResultadosExtraOficiais));
  }

  return searchParams.toString();
}

const formatarOrdemEntrada = props => `${props.inscricao.ordemEntrada}${props.inscricao.complemento ? props.inscricao.complemento : ''}`;
const formatarColocacao = props => props.inscricao.colocacao ? props.inscricao.colocacao + 'º' : '-';
const mostrarConjunto = results => results.inscricoes?.reduce((a, b) => a || b.conjunto?.length || (Array.isArray(b) && b.reduce((c, d) => c || d.conjunto?.length, false)), false);
const mostrarSubCategorias = results => results.inscricoes?.reduce((a, b) => a || b.subCategorias !== undefined || (Array.isArray(b) && b.reduce((c, d) => c || d.subCategorias !== undefined, false)), false);
const formatarNomeCompetidor = props => `${props.inscricao.competidor.nome}${(undefined === props.light && props.inscricao.light) ? '*' : ''}`;
const formatarNotaAnterior = props => props.inscricao.naoCompareceuAnterior ? 'N/C' : formatScores(props.inscricao.notaAnterior);
const formatarNota = props =>
  props.inscricao?.naoCompareceu
    ? 'N/C'
    : props.inscricao?.desclassificadoSemNota
      ? 'S/N'
      : props.inscricao.nota !== null
        ? formatScores(props.inscricao.nota)
        : null;
const formatarNotaFinal = props => props.inscricao.notaFinal !== undefined && props.inscricao.notaFinal !== null ? formatScores(props.inscricao.notaFinal) : '';
const mostrarPremiacao = results => results.inscricoes?.reduce((a, b) => a || b.premiacao?.length, false);
const formatarPremiacao = props => props.inscricao.premiacao?.length ? formatMoney(props.inscricao.premiacao.reduce((a, b) => a + parseFloat(b.valor), 0)) : ''
const mostrarNotaTotal = results => results.inscricoes?.reduce((a, b) => a || b.notaAnterior !== undefined || b.naoCompareceuAnterior !== undefined || (Array.isArray(b) && b.reduce((c, d) => c || d.notaAnterior !== undefined || d.naoCompareceuAnterior !== undefined, false)), false);
const mostrarNota = results => results.inscricoes?.reduce((a, b) => a || b.nota !== undefined || b.naoCompareceu !== undefined || (Array.isArray(b) && b.reduce((c, d) => c || d.nota !== undefined || d.naoCompareceu !== undefined, false)), false) && !results.inscricoes?.reduce((a, b) => a || b.notaAnterior !== undefined || b.naoCompareceuAnterior !== undefined || (Array.isArray(b) && b.reduce((c, d) => c || d.notaAnterior !== undefined || d.naoCompareceuAnterior !== undefined, false)), false);
const formatarNomeProprietario = props => props.inscricao.proprietario.id ? `${props.inscricao.proprietario.nome}${undefined !== props.inscricao.proprietario2 ? ' / ' + props.inscricao.proprietario2.nome : ''}` : '';
const formatarNomeCidade = props => props.inscricao.proprietario.id ? `${props.inscricao.proprietario.cidade}${props.inscricao.proprietario.cidade ? ', ' + props.inscricao.proprietario.estado : props.inscricao.proprietario.estado}${(undefined !== props.inscricao.proprietario2) && (props.inscricao.proprietario2.cidade !== props.inscricao.proprietario.cidade) ? ' / ' + props.inscricao.proprietario2.cidade + ', ' + props.inscricao.proprietario2.estado : ''}` : '';
const mostrarRepresenta = results => results.inscricoes?.reduce((a, b) => a || b.representa?.id || (Array.isArray(b) && b.reduce((c, d) => c || d.representa?.id, false)), false);
const mostrarCidade = results => !mostrarRepresenta(results); // don't show city name if we are showing the affiliate the competitor is representing
const temVideos = results => results?.inscricoes?.reduce((a, b) => a || b.videos?.length || (Array.isArray(b) && b.reduce((c, d) => c || d.videos?.length, false)), false);

const columns = [
  {
    id: 'ordemEntrada',
    label: 'OE',
    minWidth: '5%',
    align: 'center',
    function: formatarOrdemEntrada,
    mostrar: {
      ordemEntrada: true,
      resultados: false,
    },
    esconder: {
      finalizado: true,
    },
    prioridade: {
      esquerda: 9,
    },
  },
  {
    id: 'colocacao',
    label: 'Col.',
    minWidth: '5%',
    align: 'center',
    function: formatarColocacao,
    mostrar: {
      ordemEntrada: false,
      finalizado: true,
      resultados: true,
    },
    prioridade: {
      esquerda: 10,
    },
  },
  {
    id: 'conjunto',
    property: 'conjunto',
    label: 'Cj.',
    minWidth: '5%',
    align: 'center',
    mostrar: {
      function: mostrarConjunto,
    },
  },
  {
    id: 'subCategorias',
    property: 'subCategorias',
    label: 'Níveis',
    minWidth: '5%',
    align: 'center',
    mostrar: {
      function: mostrarSubCategorias,
    },
  },
  {
    id: 'animal',
    property: 'animal.nome',
    link: '/animais/',
    linkId: 'animal.id',
    semLink: 'animal.aConfirmar',
    label: 'Animal',
    minWidth: 'auto',
    align: 'left',
  },
  {
    id: 'competidor',
    property: 'competidor.nome',
    link: '/competidores/',
    linkId: 'competidor.id',
    semLink: 'competidor.aConfirmar',
    label: 'Competidor',
    minWidth: 'auto',
    function: formatarNomeCompetidor,
    align: 'left',
  },
  {
    id: 'proprietario',
    property: 'proprietario.nome',
    label: 'Proprietário',
    minWidth: 'auto',
    function: formatarNomeProprietario,
    align: 'left',
    display: {
      xs: 'none',
      sm: 'table-cell',
    },
  },
  {
    id: 'cidade',
    property: 'proprietario.cidade',
    label: 'Cidade, UF',
    minWidth: 'auto',
    function: formatarNomeCidade,
    align: 'left',
    mostrar: {
      function: mostrarCidade,
    },
    display: {
      xs: 'none',
      lg: 'table-cell',
    },
  },
  {
    id: 'representa',
    property: 'representa.lugar',
    label: 'Representa',
    minWidth: 'auto',
    align: 'left',
    mostrar: {
      function: mostrarRepresenta,
    },
    display: {
      xs: 'none',
      lg: 'table-cell',
    },
  },
  {
    id: 'notaAnterior',
    label: 'Nota 1',
    minWidth: 'auto',
    align: 'center',
    function: formatarNotaAnterior,
    mostrar: {
      function: mostrarNotaTotal,
    },
  },
  {
    id: 'notaAtual',
    label: 'Nota 2',
    minWidth: 'auto',
    align: 'center',
    function: formatarNota,
    mostrar: {
      function: mostrarNotaTotal,
    },
  },
  {
    id: 'notaFinal',
    label: 'Final',
    minWidth: 'auto',
    align: 'center',
    function: formatarNotaFinal,
    mostrar: {
      function: mostrarNotaTotal,
    },
    prioridade: {
      direita: 10,
    },
  },
  {
    id: 'nota',
    label: 'Nota',
    minWidth: 'auto',
    align: 'center',
    function: formatarNota,
    mostrar: {
      function: mostrarNota,
    },
    prioridade: {
      direita: 9,
    },
  },
  {
    id: 'premiacao',
    label: 'Premiação',
    minWidth: 'auto',
    align: 'right',
    function: formatarPremiacao,
    mostrar: {
      function: mostrarPremiacao,
    }
  },

];

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const styles = {
  tableLabel: {
    // fontWeight: "bold",
    textAlign: "right",
  },
  centeredTableLabel: {
    fontWeight: "bold",
    textAlign: "center",
  },
  leftTableLabel: {
    fontWeight: "bold",
    textAlign: "left",
  },
  tableData: {
    fontWeight: "bold",
  },
  contents: {
    marginTop: "2em",
    padding: "0 0.2em",
  }

}


const Inscricao = (props) => {
  const { inscricao, columnList, mostrarVideo, includeVideosColumn } = props;
  return (
    <>
      <StyledTableRow
        key={inscricao.id}
        tabIndex={-1}
        id={inscricao.ordemEntrada}
        sx={{
          '&:nth-of-type(odd)': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        {columns.map((column) => {
          const columnData = column.function ? column.function(props) : _.get(inscricao, column.property);
          return (
            columnList.includes(column.id) ?
              <StyledTableCell key={column.id} align={column.align} sx={_.assign({}, cellStyles, { display: { ...column.display } })}>
                {column.link && (column.semLink && !_.get(inscricao, column.semLink)) ?
                  <Link href={column.link + _.get(inscricao, column.linkId)} color="primary">{columnData}</Link>
                  : columnData
                }
              </StyledTableCell>
              : null
          );
        })}
        {includeVideosColumn
          ? inscricao.videos?.length ?
            <StyledTableCell align="center" sx={cellStyles}>
              {inscricao.videos.map((video, index) =>
                <IconButton key={`${index}-${video.idYouTube}`} size="small" onClick={() => video ? mostrarVideo(inscricao.animal.nome, YOUTUBE_VIDEO_ADDRESS_ROOT + video.idYouTube) : null}>
                  <PlayCircleOutlineIcon sx={{ fontSize: { xs: '0.9em', sm: '1.0em', md: '1.2em' } }} />
                </IconButton>
              )}
            </StyledTableCell>
            : <StyledTableCell align="center" sx={cellStyles} />
          : null
        }
      </StyledTableRow>
    </>
  )
};

const EntryData = (props) => {
  const { inscricao, index, verticalDataRows, columnList, leftColumn, rightColumn, mostrarVideo, includeVideosColumn } = props;

  const leftColumnData = leftColumn.function ? leftColumn.function(props) : _.get(inscricao, leftColumn.property);
  const rightColumnData = (null === rightColumn)
    ? null
    : rightColumn.function ? rightColumn.function(props) : _.get(inscricao, rightColumn.property);

  let firstRow = true;
  return (
    <>
      {columnList.map((dataColumn, dataColumnIndex) => {
        const column = columns.find(column => column.id === dataColumn);
        const columnData = column.function ? column.function(props) : _.get(inscricao, column.property);

        if (dataColumn === leftColumn.id || dataColumn === rightColumn?.id) return null;

        const data = column.link && (column.semLink && !_.get(inscricao, column.semLink)) ?
          <Link href={column.link + _.get(inscricao, column.linkId)} color="primary">{columnData}</Link>
          : columnData;

        const row =
          <TableRow key={dataColumnIndex} sx={(index % 2 === 1) ? testStyles.odd : null}>
            {firstRow && <StyledTableCellTest rowSpan={verticalDataRows}><Typography variant='h3' textAlign='center'>{leftColumnData}</Typography></StyledTableCellTest>}
            <StyledTableCellTest sx={styles.tableLabel}>{column.label}</StyledTableCellTest>
            <StyledTableCellTest sx={styles.tableData}>{data}</StyledTableCellTest>
            {firstRow && rightColumn && <StyledTableCellTest rowSpan={verticalDataRows}><Typography variant='h3' textAlign='center'>{rightColumnData}</Typography></StyledTableCellTest>}
          </TableRow>
        firstRow = false;
        return row;
      }
      )}

      {includeVideosColumn && <>
        <TableRow key={99} sx={(index % 2 === 1) ? testStyles.odd : null}>
          <StyledTableCellTest sx={styles.tableLabel}>Vídeo</StyledTableCellTest>
          <StyledTableCellTest sx={styles.tableData}>{
            inscricao.videos.map((video, index) =>
              <IconButton key={`${index}-${video.idYouTube}`} size="small" onClick={() => video ? mostrarVideo(inscricao.animal.nome, YOUTUBE_VIDEO_ADDRESS_ROOT + video.idYouTube) : null}>
                <PlayCircleOutlineIcon sx={{ fontSize: { xs: '1.4em', sm: '1.3em', md: '1.2em' } }} />
              </IconButton>
            )
          }
          </StyledTableCellTest>
        </TableRow>
      </>}
    </>
  )
};

const StyledTableCellTest = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
    padding: "8px",
    border: "none",
  },
}));

const testStyles = {
  odd: {
    background: "#eee",
  },
  even: {
    background: "#fff",
  },
}

const CompactTable = (props) => {
  const { ordemEntrada, finalizado, title, categoria, classificatoria, subCategoria, columnList, includeVideosColumn, results, mostrarVideo, modalOpen, setModalOpen, modalTitle, modalVideoLink } = props;
  const leftColumn = columns.reduce((a, b) => (b.prioridade?.esquerda > (a?.prioridade?.esquerda ?? 0)) && columnList.includes(b.id) ? b : a, null);
  const rightColumn = columns.reduce((a, b) => (b.prioridade?.direita > (a?.prioridade?.direita ?? 0)) && columnList.includes(b.id) ? b : a, null);
  const verticalDataRows = columnList.length - (leftColumn ? 1 : 0) - (rightColumn ? 1 : 0) + (includeVideosColumn ? 1 : 0);
  // console.log(columnList)

  return (

    <Box id={`IDCat${categoria.id}${classificatoria ? 'IDClassificatoria' + classificatoria.id : ''}${subCategoria ? 'IDSubCategoria' + subCategoria.id : ''}`} sx={styles.contents}>
      <Typography variant="h3">{title}</Typography>
      {results.inscricoes
        ? results.inscricoes.length > 0
          ? <TableContainer>
            <Table stickyHeader aria-label="data table" sx={{ width: "100%", }}>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCellTest sx={styles.centeredTableLabel}>{ordemEntrada ? 'OE' : 'Col.'}</StyledTableCellTest>
                  <StyledTableCellTest colSpan={2} sx={styles.leftTableLabel}>Conjunto</StyledTableCellTest>
                  {rightColumn && <StyledTableCellTest sx={styles.centeredTableLabel}>Nota</StyledTableCellTest>}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {ordemEntrada && !finalizado
                  ? results.inscricoes.map((bateria, bunchNumber) =>
                    <React.Fragment key={bunchNumber}>
                      <StyledTableRow>
                        <StyledTableCell colSpan={15} sx={_.assign({}, cellStyles, bunchHeaderStyle)}>{`${bunchNumber + 1}ª bateria`}</StyledTableCell>
                      </StyledTableRow>
                      {Array.isArray(bateria) && bateria.map((inscricao, index) => inscricao.animal && <EntryData key={`${inscricao.animal.id}-${inscricao.competidor.id}-${index}`} inscricao={inscricao} index={index} verticalDataRows={verticalDataRows} columnList={columnList} leftColumn={leftColumn} rightColumn={rightColumn} mostrarVideo={mostrarVideo} includeVideosColumn={includeVideosColumn} />)}
                    </React.Fragment>
                  )
                  : results.inscricoes.map((inscricao, index) => inscricao.animal && <EntryData key={`${inscricao.animal.id}-${inscricao.competidor.id}-${index}`} inscricao={inscricao} index={index} verticalDataRows={verticalDataRows} columnList={columnList} leftColumn={leftColumn} rightColumn={rightColumn} mostrarVideo={mostrarVideo} includeVideosColumn={includeVideosColumn} />)
                }
              </TableBody>
            </Table>
          </TableContainer>
          : `A ordem de entrada${ordemEntrada ? '' : '/o resultado'} desta categoria não está disponível ainda.`
        : results.error
          ? <Alert severity="error">{`${results.error} `}</Alert>
          : <CircularProgress />
      }
      {includeVideosColumn && <VideoDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} videoLink={modalVideoLink} />}
    </Box>
  )
};

export default function WorkingOrderAndResultTable(props) {
  const { ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais } = props;

  // const [ results, setResults] = useState(RESULTADOS_NIVEL_4_TEST_DATA);
  const [results, setResults] = useState({});

  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('sm'));

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalVideoLink, setModalVideoLink] = useState("");

  // Puxar os dados da API quando monta o component, ou se mudar o id do evento
  useEffect(() => {
    let unmounting = false;
    if (evento && categoria) {
      axios
        .get(`/ordementrada?${setParams({ ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais })}`)
        .then((response) => {
          if (!unmounting) {
            const data = response.data;
            setResults(data);
          }
        })
        .catch((error) => {
          // Obs: We check if the error has already been set on the object, to avoid setting it again and causing an unnecessary state change
          if (!unmounting) {
            if (!results.hasOwnProperty(error)) {
              setResults({
                "error": error,
              });
            }
            console.log("error", error);
          }
        });
    }
    return function () {
      unmounting = true;
    };
  }, [ordemEntrada, evento, categoria, classificatoria, subCategoria, divisao, light, naoIncluirResultadosExtraOficiais]); // eslint-disable-line react-hooks/exhaustive-deps

  // console.log(results)

  const mostrarVideo = (title, videoLink) => {
    setModalTitle(title);
    setModalVideoLink(videoLink);
    setModalOpen(true);
  };

  const finalizado = (classificatoria && classificatoria.finalizado) || categoria.finalizado || false;
  const columnList = useMemo(
    () => columns.reduce((columnList, column) => (
      !(column.esconder?.finalizado && finalizado)
      && (column.mostrar === undefined
        || (column.mostrar.ordemEntrada && ordemEntrada)
        || (column.mostrar.finalizado && finalizado)
        || (column.mostrar.resultados && !ordemEntrada)
        || (column.mostrar?.function && column.mostrar.function(results))
      )
    )
      ? [...columnList, column.id]
      : columnList, []
    ), [ordemEntrada, finalizado, results]);

  const includeVideosColumn = useMemo(
    () => temVideos(results)
    , [results]
  );
  // console.log(columnList);
  // console.log(results);
  // console.log({finalizado});

  const title = `${categoria.nome}${classificatoria ? ' - ' + classificatoria.nome : ''}${light ? ' Light' : ''}${subCategoria ? ' - Nível ' + subCategoria.nome : ''}${divisao ? ` (${divisao.titulo})` : ''}${finalizado ? ' (Resultados Extra Oficiais)' : ''}`;

  if (xs) return <CompactTable ordemEntrada={ordemEntrada} finalizado={finalizado} title={title} categoria={categoria} classificatoria={classificatoria} subCategoria={subCategoria} columnList={columnList} includeVideosColumn={includeVideosColumn} results={results} light={light} mostrarVideo={mostrarVideo} modalOpen={modalOpen} setModalOpen={setModalOpen} modalTitle={modalTitle} modalVideoLink={modalVideoLink} />

  return (
    <Box id={`IDCat${categoria.id}${classificatoria ? 'IDClassificatoria' + classificatoria.id : ''}${subCategoria ? 'IDSubCategoria' + subCategoria.id : ''}`}>
      <Typography variant="h3">{title}</Typography>
      {results.inscricoes
        ? results.inscricoes.length > 0
          ? <>
            <Paper>
              <TableContainer>
                <Table stickyHeader aria-label="data table" sx={{ width: "100%", }}>
                  <TableHead>
                    <StyledTableRow>
                      {columns.map((column) => columnList.includes(column.id) ?
                        <StyledTableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                          sx={_.assign({}, headerCellStyles, cellStyles, { display: { ...column.display } })}
                        >
                          {column.label}
                        </StyledTableCell>
                        : null
                      )}
                      {includeVideosColumn && <StyledTableCell />}
                    </StyledTableRow>
                  </TableHead>


                  <TableBody>
                    {ordemEntrada && !finalizado
                      ? results.inscricoes.map((bateria, bunchNumber) =>
                        <React.Fragment key={bunchNumber}>
                          <StyledTableRow>
                            <StyledTableCell colSpan={15} sx={_.assign({}, cellStyles, bunchHeaderStyle)}>{`${bunchNumber + 1}ª bateria`}</StyledTableCell>
                          </StyledTableRow>
                          {Array.isArray(bateria) && bateria.map(inscricao => <Inscricao key={`${inscricao.animal.id}-${inscricao.competidor.id}`} inscricao={inscricao} mostrarVideo={mostrarVideo} columnList={columnList} light={light} includeVideosColumn={includeVideosColumn} />
                          )}
                        </React.Fragment>
                      )
                      : results.inscricoes.map(inscricao => inscricao.animal && <Inscricao key={`${inscricao.animal.id}-${inscricao.competidor.id}`} inscricao={inscricao} mostrarVideo={mostrarVideo} columnList={columnList} light={light} includeVideosColumn={includeVideosColumn} />
                      )
                    }
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </>
          : `A ordem de entrada${ordemEntrada ? '' : '/o resultado'} desta categoria não está disponível ainda.`
        : results.error
          ? <Alert severity="error">{`${results.error} `}</Alert>
          : <CircularProgress />
      }
      {includeVideosColumn && <VideoDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} videoLink={modalVideoLink} />}
    </Box>
  )
}
