import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkIfValidUUID,
  convertGuid,
} from "../functions";

const RedirectDestaques = (props) => {
  const { menus } = props;
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.has('IDDestaque') ? convertGuid(params.get('IDDestaque')) : null;
  // find friendly url to destaque in the menu items 
  const menuItem = checkIfValidUUID(id) && menus && menus.find(item => item.itens && item.itens.find(subMenuItem => subMenuItem.id === id));
  const url = menuItem && menuItem.itens?.find(subMenuItem => subMenuItem.id === id)?.url;

  const navigate = useNavigate();

  useEffect(() => {
    if (url) {
      navigate({ pathname: url });
    } else {
      if (!id) {
        navigate({ pathname: `/destaques` });
      }
    }
  }, [navigate, id, url])

  return (
    <Alert>Aguarde, estamos redirecionando</Alert>
  )
};
export default RedirectDestaques;
