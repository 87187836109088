import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const RHookFormRadio = (props) => {
  const {
    id,
    name,
    label,
    className,
    formHelperTextStyles,
    formHelperTextErrorStyles,
    formHelperTextClassName,
    formHelperTextErrorClassName,
    fullWidth,
    rules,
    helperText,
    errorMessages,
    inputProps,
    disabled,
    options,
    onChange,
    sx,
  } = props;

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const helperTextMessage = () => {
    if (!errorMessages) return helperText ?? '';
    if (errors?.[name]) {
      const errorMessage = errorMessages.find(errorArray => errors[name].type === errorArray.type);
      if (!errorMessage) return helperText ?? '';
      return errorMessage.message;
    }

    return helperText ?? ' ';
  }

  return (
    <React.Fragment>
      <FormControl component="fieldset" variant="standard" fullWidth={fullWidth} error={!!errors?.[name]} className={className} sx={sx}>
        <FormLabel component="legend">{label}</FormLabel>
        <Controller
          id={id}
          name={name}
          control={control}
          rules={rules}
          inputProps={inputProps}
          render={({ field }) =>
            <RadioGroup
              aria-label={label}
              {...field}
              onChange={(e) => {
                if (onChange) {
                  onChange(e);
                }
                return field.onChange(e);
              }}
              value={field.value}
            >
              {options?.map((option, index) => (
                <FormControlLabel
                  key={`${index}-${option.value}`}
                  value={option.value}
                  control={<Radio color="secondary" />}
                  label={option.label}
                  disabled={disabled}
                />
              ))}
            </RadioGroup>
          }
        />
      </FormControl>
      <FormHelperText
        className={errors?.[name] ? formHelperTextErrorClassName : formHelperTextClassName}
        sx={errors?.[name] ? formHelperTextErrorStyles : formHelperTextStyles}
      >
        {helperTextMessage()}
      </FormHelperText>
    </React.Fragment>
  );
}

export default RHookFormRadio;
