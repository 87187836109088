import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useLocation } from "react-router-dom";
import { Typography, CircularProgress, LinearProgress, Box } from '@mui/material';
import { axiosInstance as axios } from '../axiosHook/axiosInstance';
import {
  CONTEUDO_ESTATICO_ITENS_POR_PAGINA,
} from "../constants";
import {
  redirectRelativeImageLinks,
  checkIfValidUUID,
} from "../functions";
import Pagination from '@mui/material/Pagination';
import PaginationItem from "@mui/material/PaginationItem";
import { Helmet } from "react-helmet-async";
import { Alert } from '@mui/material';
import ScrollToTopOnMount from './ScrollToTopOnMount';

export default function StaticContent(props) {
  const { helmetContext, id } = props;

  let location = useLocation();
  const search = location.search;
  const searchParams = new URLSearchParams(search);
  const page = parseInt(searchParams.get('page') ?? 1); // Default to page 1

  const [article, setArticle] = useState([]);
  const totalItens = article["hydra:totalItems"]
  const totalPaginas = Math.ceil(totalItens / CONTEUDO_ESTATICO_ITENS_POR_PAGINA);

  const [updating, setUpdating] = useState(false);

  const getNextUrl = (page) => window.location.pathname + `?page=${page}`;

  const getConteudo = (id, page) => {
    setUpdating(true);
    const url = `/destaque_conteudos?page=${page}&itemsPerPage=${CONTEUDO_ESTATICO_ITENS_POR_PAGINA}&destaque.id=${id}`;
    axios.get(url)
      .then(response => {
        const data = response.data;
        setArticle(data);
        setUpdating(false);
      }).catch(error => {
        setArticle({
          "error": error,
        });
        console.log("error", error);
      });
  };

  useEffect(() => {
    if (checkIfValidUUID(id)) {
      getConteudo(id, page);
    }
  }, [id, page]);

  return (
    <>
      <Helmet>
        <title>{`${article["hydra:member"] ? article["hydra:member"][0]?.titulo + ' | ' : ''}${helmetContext.title}`}</title>
        <meta name="description" content="Conteúdo da ANCR" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas" />
      </Helmet>
      <ScrollToTopOnMount />

      {checkIfValidUUID(id)
        ? article["hydra:member"] ?
          <>
            <Typography key="titulo" variant="h2">{article["hydra:member"][0]?.titulo}</Typography>
            <Box
              key="conteudo"
              dangerouslySetInnerHTML={{ __html: redirectRelativeImageLinks(article["hydra:member"][0]?.conteudo) }}
            />
            <LinearProgress className={
              updating
                ? ""
                : "hidden"
            } />
            {totalItens > 1 &&
              <div>
                <Typography component="p" sx={{ pt: 2, mb: 0 }}>
                  {CONTEUDO_ESTATICO_ITENS_POR_PAGINA > 1 ?
                    `Você está visualizando ítem ${page * CONTEUDO_ESTATICO_ITENS_POR_PAGINA - (CONTEUDO_ESTATICO_ITENS_POR_PAGINA - 1)} até ${page * CONTEUDO_ESTATICO_ITENS_POR_PAGINA - (
                      CONTEUDO_ESTATICO_ITENS_POR_PAGINA - article["hydra:member"]?.length)} em um total de ${totalItens}.`
                    : ''
                  }
                </Typography>
                <Pagination
                  variant="outlined"
                  shape="rounded"
                  page={page}
                  count={totalPaginas}
                  renderItem={item => (
                    <PaginationItem
                      component={RouterLink}
                      to={getNextUrl(item.page)}
                      {...item}
                    />
                  )}
                />
              </div>
            }
          </>
          : article.error ?
            <Alert severity="error">{`${article.error}`}</Alert>
            : <CircularProgress />
        : <Alert severity="error">Conteúdo não localizado!</Alert>
      }
    </>
  )

}