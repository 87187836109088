import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Box, Tab, Tabs } from '@mui/material';
import FirstLoginForm from './FirstLoginForm';
import SetLoginAndPasswordForm from './SetLoginAndPasswordForm';
import NormalLoginForm from './NormalLoginForm';
import ResetarSenha from './ResetarSenha';

const styles = {
  tab: {
    width: {
      xs: "100vw",
      sm: "250px",
    }
  }
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const LoginTypeTabBar = (props) => {
  const { session, save, clear, loginTypes, loginType, setLoginType, setModalMessage, setModalTitle, setModalOpen, setModalNavigateUrlOnClose, setModalClearSessionOnClose, resetToken } = props;

  const handleChange = (event, newValue) => {
    setLoginType({ type: newValue });
  };


  const StyledTab = (props) => {
    return (
      <Tab
        wrapped
        sx={styles.tab}
        {...props}
      />
    );
  }

  return (
    <Box sx={{ bgcolor: '#f9f9f9' }}>
      <AppBar position="static">
        <Tabs
          value={loginType}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="Categorias"
          sx={{ maxWidth: "100vw" }}
          onChange={handleChange}
        >
          <StyledTab value={0} label="Efetuar Login" />
          <StyledTab value={1} label="Primeiro Acesso" />
          <StyledTab value={2} label="Resetar" />
        </Tabs>
      </AppBar>
      <TabPanel value={loginType} index={loginTypes[0]}>
        <NormalLoginForm save={save} setModalTitle={setModalTitle} setModalMessage={setModalMessage} setModalOpen={setModalOpen} setModalNavigateUrlOnClose={setModalNavigateUrlOnClose} setModalClearSessionOnClose={setModalClearSessionOnClose} />
      </TabPanel>
      <TabPanel value={loginType} index={loginTypes[1]}>
        {session
          ? <SetLoginAndPasswordForm session={session} clear={clear} setModalTitle={setModalTitle} setModalMessage={setModalMessage} setModalOpen={setModalOpen} setModalNavigateUrlOnClose={setModalNavigateUrlOnClose} setModalClearSessionOnClose={setModalClearSessionOnClose} />
          : <FirstLoginForm save={save} setModalTitle={setModalTitle} setModalMessage={setModalMessage} setModalOpen={setModalOpen} setModalNavigateUrlOnClose={setModalNavigateUrlOnClose} setModalClearSessionOnClose={setModalClearSessionOnClose} />
        }
      </TabPanel>
      <TabPanel value={loginType} index={loginTypes[2]}>
        <ResetarSenha setModalTitle={setModalTitle} setModalMessage={setModalMessage} setModalOpen={setModalOpen} setModalNavigateUrlOnClose={setModalNavigateUrlOnClose} setModalClearSessionOnClose={setModalClearSessionOnClose} resetToken={resetToken} />
      </TabPanel>
    </Box>
  )
};

export default LoginTypeTabBar;