import { Alert } from '@mui/material';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  checkIfValidUUID,
  convertGuid,
} from "../functions";

const RedirectNoticias = () => {
  let location = useLocation();
  const params = new URLSearchParams(location.search);
  const id = params.has('IDNoticia') ? convertGuid(params.get('IDNoticia')) : null;

  const navigate = useNavigate();

  useEffect(() => {
    if (checkIfValidUUID(id)) {
      navigate({ pathname: `/noticias/${id}` });
    } else {
      if (!id) {
        navigate({ pathname: `/noticias/` });
      }
    }
  }, [navigate, id])

  return (
    <Alert>Aguarde, estamos redirecionando</Alert>
  )
};
export default RedirectNoticias;
