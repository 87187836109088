import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, FormHelperText, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { FormProvider, useForm } from 'react-hook-form';
import RHookFormRadio from '../../form/RHookFormRadio';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import { useGetData } from "../../axiosHook/useRequestData";
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';
import {
  DEBUG_FORM_DATA,
  CHECK_VALID_SESSION_INTERVAL,
  MAX_LENGTH_NOME_ANIMAL,
  MIN_LENGTH_NOME_ANIMAL,
  DEFAULT_STALL_TYPE_DESCRIPTION_IF_BLANK,
} from "../../constants";
import AlertDialog from "../AlertDialog";
import useInterval from '../../useInterval';
import RHookFormTextField from '../../form/RHookFormTextField';
import EntryFormHeaderRow from './EntryFormHeaderRow';

const defaultValues = {
  evento: null,
  apoio: null,
  baia: "",
  nomeAnimalPasseio: "",
};

const onSubmit = true;

const ExtraStallForm = (props) => {
  const { session, save, clear } = props;

  let navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [checkingStallExists, setCheckingStallExists] = useState(false);

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // save extra stall data to session (in case the page is reloaded before payment is made)
      const baiaExtra = { evento, apoio, baia, nomeAnimalPasseio, categoriasBaias: categoriasBaias["hydra:member"] };
      // debugging:
      if (process.env.NODE_ENV !== 'production') {
        console.log("session", { ...session, baiasExtras: session.baiasExtras ? [...session.baiasExtras, baiaExtra] : [baiaExtra] });
      }
      save({ ...session, baiasExtras: session.baiasExtras ? [...session.baiasExtras, baiaExtra] : [baiaExtra] });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
  };

  const methods = useForm({
    defaultValues,
    mode: "onTouched",
    // mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
    // getValues,
    setValue,
    // setError,
    // trigger,
    control,
  } = methods;

  const evento = watch('evento', null);
  const [eventosComInscricoesAbertas = {}] = useGetData('/eventos/com_inscricoes_abertas');
  const dadosEvento = eventosComInscricoesAbertas["hydra:member"]?.find(option => option.id.toString() === evento) ?? null;
  const [categoriasBaias = {}] = useGetData(`/categoria_baia_eventos?evento.id=${evento}&consta=true`);
  const apoio = watch('apoio', null);
  const baia = watch('baia', null);
  const nomeAnimalPasseio = watch('nomeAnimalPasseio', null);

  useEffect(() => {
    // check if only one event available or all events occur at the same place and time (if so, it's a group of events, 
    // and it doesn't matter which event the extra stall is assigned to, 
    // so it will just be assigned to the first one.)
    if (eventosComInscricoesAbertas["hydra:member"]?.length === 1 || eventosComInscricoesAbertas["hydra:member"]?.every((o, i, a) => o.dataIni === a[0].dataIni && o.dataFim === a[0].dataFim)) {
      setValue("evento", eventosComInscricoesAbertas["hydra:member"][0].id.toString());
    }
  }, [eventosComInscricoesAbertas]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // check if only one type of stall is available for the event selected
    // if so, it will be automatically selected
    if (evento !== defaultValues.evento && categoriasBaias["hydra:member"]?.length === 1) {
      setValue("baia", categoriasBaias["hydra:member"][0].categoriaBaia?.id.toString());
    }
  }, [evento, categoriasBaias, defaultValues]); // eslint-disable-line react-hooks/exhaustive-deps

  // debugging:
  if (process.env.NODE_ENV !== 'production') {
    console.log({ eventosComInscricoesAbertas });
    console.log({ evento });
    console.log("dadosEvento", dadosEvento);
    console.log("categoriasBaias", categoriasBaias);
    console.log("session", session);
  }


  const checkNoStallExistsInThatName = async (nomeAnimalPasseio) => {
    setCheckingStallExists(true);
    const url = `/baias/confimacao?idEvento=${evento}&nomeAnimalPasseio=${nomeAnimalPasseio}`;
    try {
      const response = await axios.get(url);
      const data = response.data;
      setCheckingStallExists(false);
      if (data?.animal) {
        return `O animal ${data?.animal?.nome} está inscrito para competir no evento ${dadosEvento?.nome} e já tem baia reservada!`;
      }
      if (data?.nomeAnimalPasseio) {
        return `O animal ${data?.nomeAnimalPasseio} já tem baia extra reservada por ${data?.afiliado?.nome}!`;
      }
      return true; // no stall reservation found
    } catch (error) {
      setCheckingStallExists(false);
      if (error?.response?.status === 404) return true; // no stall reservation found
      console.error(error);
      return `Erro conectando ao servidor! ${error}`;
    }
  };

  let steps = [
    {
      label: 'Evento',
      caption: 'Selecione o evento para qual deseja reservar a baia extra',
      content:
        eventosComInscricoesAbertas
          ? <RHookFormRadio
            sx={{ mt: 1 }}
            fullWidth
            formHelperTextErrorClassName="formSelectErrorMessage"
            name="evento"
            rules={{ required: true }}
            options={eventosComInscricoesAbertas["hydra:member"]?.map(option => {
              return {
                value: option.id.toString(),
                label: option.nome,
              };
            })}
            helperText=" "
            errorMessages={
              [{
                type: "required",
                message: "É necessário informar o evento!",
              }]}
          />
          : <CircularProgress />
      ,
    },
    {
      label: 'Apoio',
      caption: 'É baia de apoio, ou para animal que não vai competir?',
      content:
        <RHookFormRadio
          sx={{ mt: 1 }}
          fullWidth
          formHelperTextErrorClassName="formSelectErrorMessage"
          name="apoio"
          rules={{ required: true }}
          options={[
            { value: true, label: 'Apoio' },
            { value: false, label: 'Animal a passeio' },
          ]}
          helperText=" "
          errorMessages={
            [{
              type: "required",
              message: "É necessário selecionar uma opção!",
            }]}
        />
      ,
    },
    {
      label: 'Baia',
      caption: `Qual tipo de baia gostaria de reservar?`,
      content:
        <RHookFormRadio
          sx={{ mt: 1 }}
          fullWidth
          formHelperTextErrorClassName="formSelectErrorMessage"
          name="baia"
          rules={{ required: true }}
          options={categoriasBaias["hydra:member"]?.map(option => {
            return {
              value: option.categoriaBaia.id.toString(),
              label: option.categoriaBaia?.descricao.trim() ? option.categoriaBaia.descricao : DEFAULT_STALL_TYPE_DESCRIPTION_IF_BLANK,
            };
          })
          }
          helperText=" "
          errorMessages={
            [{
              type: "required",
              message: "É necessário escolher!",
            }]}
        />
      ,
    },
  ];

  if (apoio === "false") {
    steps.splice(2, 0, {
      label: 'Nome do Animal',
      caption: 'Digite o nome do animal que ocupará a baia',
      content:
        <>
          <RHookFormTextField
            name="nomeAnimalPasseio"
            label="Nome"
            id="nomeAnimalPasseio"
            sx={{ mt: 1 }}
            fullWidth
            inputProps={{ maxLength: MAX_LENGTH_NOME_ANIMAL }}
            rules={{
              validate: {
                minLength: (value) => value?.length >= MIN_LENGTH_NOME_ANIMAL || "Digite o nome completo do animal!",
                maxLength: (value) => value?.length <= MAX_LENGTH_NOME_ANIMAL || `O nome completo do animal não pode ser mais que ${MAX_LENGTH_NOME_ANIMAL} caracteres!`,
                stallReservationExists: async (value) => await checkNoStallExistsInThatName(value),
              },
            }}
            disabled={apoio === "true"}
            error={!!errors?.nomeAnimalPasseio}
          />
          <FormHelperText className={errors?.nomeAnimalPasseio ? "formError" : null}>
            {errors?.nomeAnimalPasseio
              ? errors.nomeAnimalPasseio.message
              : " "}
          </FormHelperText>
        </>
      ,
    })
  }

  const disableContinueButton = (index) =>
    (index === 0 && evento === defaultValues.evento)
    || (index === 1 && apoio === defaultValues.apoio)
    || (index === 2 && (
      (apoio === "false" && (nomeAnimalPasseio === defaultValues.nomeAnimalPasseio || !!errors.nomeAnimalPasseio || checkingStallExists))
      ||
      (apoio === "true" && (baia === defaultValues.baia || !!errors.baia))
    ))
    || (index === 3 && (baia === defaultValues.baia || !!errors.baia))

  const checkUserIsAuthenticated = async () => {
    try {
      await axios.get('/checkuserisauthenticated',
        {
          headers: {
            'Authorization': `Bearer ${session.token}`
          },
        });
    } catch (error) {
      // debugging:
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      if (error?.response?.status === 401) {
        // if login has expired then show modal and redirect to login page
        setModalMessage({
          type: "error",
          message: "Seu login já expirou. Efetue o login novamente.",
        });
        setModalOpen(true);
      }
    }
  };

  // check on page mount 
  useEffect(() => {
    if (!session) return;
    checkUserIsAuthenticated();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // and check again every 5 minutes 
  useInterval(async () => {
    if (!session) return;
    checkUserIsAuthenticated();
  }, CHECK_VALID_SESSION_INTERVAL);

  // if not logged in then show modal and redirect to login page
  if (!session && !modalOpen) {
    setModalMessage("Favor efetuar o login");
    setModalOpen(true);
    // return;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 2, mb: 0 }}
      >
        <Grid item xs={12} md={9}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <EntryFormHeaderRow session={session} headerText="Adicionar baia extra" baiaExtra />
              <Typography component='p'>Baias extras são para animais que não vão competir, ou para colocar traia, etc. Siga os passos abaixo para efetuar sua reserva.</Typography>

              <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          step.caption ? (
                            <Typography variant="caption">{step.caption}</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        {step.content}
                        <Box sx={{ mb: 2 }}>
                          <div>
                            <LoadingButton
                              loading={index === 2 && checkingStallExists}
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                              disabled={disableContinueButton(index)}
                            >
                              {index === steps.length - 1 ? 'Adicionar Baia' : 'Continuar'}
                            </LoadingButton>
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Voltar
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>Proceder para o formulário de pagamento</Typography>
                    <Button
                      variant="outlined"
                      onClick={handleReset}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Adicionar outra reserva
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => navigate('/efetuar')}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Pagamento
                    </Button>
                  </Paper>
                )}
              </Box>


            </form>
          </FormProvider>
          {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
        </Grid>
      </Grid>
      <AlertDialog open={modalOpen} setOpen={setModalOpen} title="Erro" message={modalMessage} clearSessionOnClose session={session} clear={clear} navigateUrlOnClose="/login" />
    </>
  )
};

export default ExtraStallForm;
