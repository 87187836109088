import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

const RHookFormSelect = (props) => {
  const {
    id,
    name,
    label,
    className,
    sx,
    formHelperTextStyles,
    formHelperTextErrorStyles,
    formHelperTextClassName,
    formHelperTextErrorClassName,
    fullWidth,
    rules,
    helperText,
    errorMessages,
    inputProps,
    disabled,
    options,
    onChange,
  } = props;

  const {
    control,
    formState: { errors }
  } = useFormContext();

  // root name is used incase of any subresources in the name, like pais.id, for which RHF will use pais as the error name.
  const rootName = name.includes('.') ? name.split(".")[0] : name;

  const helperTextMessage = () => {
    if (!errorMessages) return helperText ?? '';
    if (errors?.[rootName]) {
      const errorMessage = name.includes('.')
        ? errorMessages.find(errorArray => errors[rootName][name.split(".")[1]]?.type === errorArray.type)
        : errorMessages.find(errorArray => errors[rootName].type === errorArray.type);
      if (!errorMessage) return helperText ?? '';
      return errorMessage.message;
    }

    return helperText ?? ' ';
  }

  return (
    <React.Fragment>
      <FormControl
        variant="standard"
        className={className}
        sx={sx}
        fullWidth={fullWidth}
        error={!!errors?.[name]}
      >
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          rules={rules}
          inputProps={inputProps}
          render={({ field, field: { onChange: controllerOnChange } }) =>
            <Select {...field}
              variant="standard"
              onChange={onChange ? onChange : controllerOnChange}
              id={id}
              disabled={disabled}
            >
              {options?.map(option => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>}
        />
      </FormControl>
      <FormHelperText
        className={errors?.[rootName] ? formHelperTextErrorClassName : formHelperTextClassName}
        sx={errors?.[rootName] ? formHelperTextErrorStyles : formHelperTextStyles}
      >
        {helperTextMessage()}
      </FormHelperText>
    </React.Fragment>
  );
}

export default RHookFormSelect;
