import React from 'react';
import { Alert, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Link from '@mui/material/Link';
import BasicDataTable from '../BasicDataTable';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const seasonTableRows = (seasons, acumulado) => {
  if (!seasons) return [];

  let rows = seasons.map(season => {
    let row = [];
    row.push(
      <Link href={`/ranking?temporada=${season.temporada}${acumulado ? '&acumulado=1' : ''}`} color="primary">
        {`${season.temporada}/${season.temporada + 1}`}
      </Link>
    );
    return row;
  });
  rows.push([
    <Link href={`/rankingacumulado`} color="primary">
      Geral
    </Link>
  ])
  return rows;
};

export default function RankingSeasonSelector(props) {
  const { seasons, acumulado } = props;

  if (seasons.error) return <Alert severity="error">{`${seasons.error} `}</Alert>

  if (!Array.isArray(seasons)) return <CircularProgress />

  return (
    <>
      <ScrollToTopOnMount />
      {acumulado ? null : <Typography variant="h2" align='center'>Ranking ANCR</Typography>}
      <BasicDataTable
        title="Selecione a Temporada ou Geral"
        paper={true}
        rows={seasonTableRows(seasons, acumulado)}
        alignColumns={['center']}
      />
    </>
  );
}