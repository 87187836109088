
import React from 'react';
import { createTheme, alpha, responsiveFontSizes } from '@mui/material/styles';
import { ptBR } from '@mui/material/locale';
import { Link as RouterLink } from 'react-router-dom';
import FocoRegular from '../fonts/focoRegular.ttf';
import FocoLight from '../fonts/focoLight.ttf';
import FocoBold from '../fonts/focoBold.ttf';

// Make all Mui Link components and buttons use react router Link component
const LinkBehavior = React.forwardRef((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

let theme = createTheme({

  palette: {
    primary: {
      main: '#24618E',
      light: '#b5b5b5'
    },
    secondary: {
      main: '#51B3E8',
    },
    grey: {
      main: "#B5B5B5",
      dark: "#333"
    }
  },
  typography: {
    fontFamily: [
      '"Foco Regular"',
      '"Foco Light"',
      '"Foco Bold"',
    ].join(','),
    h1: {
      fontSize: '1.8em',
      fontFamily: 'Foco Bold',
      paddingBottom: 16,
      fontWeight: 900,
      color: '#24618E'
    },
    h2: {
      fontSize: '1.6em',
      fontFamily: 'Foco Bold',
      paddingBottom: 16,
      fontWeight: 900,
      color: '#24618E'
    },
    h3: {
      fontSize: '1em',
      fontFamily: 'Foco Bold',
      paddingBottom: 16,
      paddingTop: 16,
      fontWeight: 900,
      color: '#24618E'
    },
    body1: {
      fontFamily: 'Foco Light',
      color: "#666"
    },
    body2: {
      fontFamily: 'Foco Regular',
      color: "#666"
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        component: LinkBehavior,
      },
    },
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Foco Regular';
          src: url(${FocoRegular}) format('truetype');
        }
              
        @font-face {
          font-family: 'Foco Bold';
          src: url(${FocoBold}) format('truetype');
        }

        @font-face {
          font-family: 'Foco Light';
          src: url(${FocoLight}) format('truetype');
        }
        `,
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
  }
}, ptBR);

// Add option color="grey" to MuiButton to simulate the Mui v4 option color="default" 
theme = createTheme(theme, {
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: "contained", color: "grey" },
          style: {
            color: theme.palette.getContrastText(theme.palette.grey[300])
          }
        },
        {
          props: { variant: "outlined", color: "grey" },
          style: {
            color: theme.palette.text.primary,
            borderColor:
              theme.palette.mode === "light"
                ? "rgba(0, 0, 0, 0.23)"
                : "rgba(255, 255, 255, 0.23)",
            "&.Mui-disabled": {
              border: `1px solid ${theme.palette.action.disabledBackground}`
            },
            "&:hover": {
              borderColor:
                theme.palette.mode === "light"
                  ? "rgba(0, 0, 0, 0.23)"
                  : "rgba(255, 255, 255, 0.23)",
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        },
        {
          props: { color: "grey", variant: "text" },
          style: {
            color: theme.palette.text.primary,
            "&:hover": {
              backgroundColor: alpha(
                theme.palette.text.primary,
                theme.palette.action.hoverOpacity
              )
            }
          }
        }
      ]
    }
  }
});

// Add responsive font sizes to theme
theme = responsiveFontSizes(theme);

export default theme
