import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress, FormHelperText, Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import { FormProvider, useForm } from 'react-hook-form';
import RHookFormRadio from '../../form/RHookFormRadio';
import RHookFormAutocompleteWithGet from '../../form/RHookFormAutocompleteWithGet';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import { useGetData } from "../../axiosHook/useRequestData";
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';
import {
  DEBUG_FORM_DATA,
  CHECK_VALID_SESSION_INTERVAL,
  DEFAULT_STALL_TYPE_DESCRIPTION_IF_BLANK,
} from "../../constants";
import {
  idadeEmAnosHipicos,
  getAgeAtDate,
} from "../../functions";
import AlertDialog from "../AlertDialog";
import useInterval from '../../useInterval';
import {
  AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import EntryFormHeaderRow from './EntryFormHeaderRow';

const optionsForPoints = [
  { id: true, descricao: 'Sim' },
  { id: false, descricao: 'Não' },
];

const defaultValues = {
  evento: null,
  categoria: null,
  animal: "",
  competidor: "",
  grupoSubCategoria: null,
  pontuarNrha: null,
  pontuarAbqm: null,
  baia: "",
  representa: "",
};

const onSubmit = true;

const EntryForm = (props) => {
  const { session, save, clear } = props;

  let navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // save entry data to session (in case the page is reloaded before payment is made)
      const inscricao = { evento, categoria, animal, competidor, grupoSubCategoria, pontuarNrha, pontuarAbqm, baia, representa, dadosEvento, dadosCategoria, niveisCompetidorCategoriaEvento: niveisCompetidorCategoriaEvento["hydra:member"], categoriasBaias: categoriasBaias["hydra:member"] };
      // debugging:
      if (process.env.NODE_ENV !== 'production') {
        console.log("session", { ...session, inscricoes: session.inscricoes ? [...session.inscricoes, inscricao] : [inscricao] });
      }
      save({ ...session, inscricoes: session.inscricoes ? [...session.inscricoes, inscricao] : [inscricao] });
    }
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
  };

  const methods = useForm({
    defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isValid, isDirty },
    watch,
    reset,
    // getValues,
    // setValue,
    // setError,
    // trigger,
    control,
  } = methods;

  const evento = watch('evento', null);
  const categoria = watch('categoria', null);
  const animal = watch('animal', null);
  const competidor = watch('competidor', null);
  const grupoSubCategoria = watch('grupoSubCategoria', null);
  const pontuarNrha = watch('pontuarNrha', null);
  const pontuarAbqm = watch('pontuarAbqm', null);
  const baia = watch('baia', null);
  const representa = watch('representa', null);
  const [eventosComInscricoesAbertas = {}] = useGetData('/eventos/com_inscricoes_abertas');
  const dadosEvento = eventosComInscricoesAbertas["hydra:member"]?.find(option => option.id.toString() === evento) ?? null;
  const [categoriasEvento = {}] = useGetData(`/categoria_eventos?evento.id=${evento}&order%5Bcategoria.ordem%5D=asc`);
  const dadosCategoria = categoriasEvento["hydra:member"]?.find(option => option.categoria.id.toString() === categoria) ?? null;
  const [niveisCompetidorCategoriaEvento = {}] = useGetData(`/grupo_sub_categorias/para_competidor?evento=${evento}&categoria=${categoria}&afiliado=${competidor?.id}`);
  const [limiteDeInscricoesPelaAbqm = {}] = useGetData(`/afiliados/${competidor?.id}/inscrito?idEvento=${evento}&idCategoria=${categoria}&abqm=true`);
  const [confirmacaoInscricaoAnimalExiste = {}] = useGetData(`/animais/${animal?.id}/inscrito?idEvento=${evento}&idCategoria=${categoria}`);
  const [categoriasBaias = {}] = useGetData(`/categoria_baia_eventos?evento.id=${evento}&consta=true`);
  const [campeonatos = AFFILIATE_LIST_DEFAULT_DATA] = useGetData('/campeonatos');

  // debugging:
  if (process.env.NODE_ENV !== 'production') {
    console.log({ evento }, { categoria }, { grupoSubCategoria }, { pontuarNrha }, { pontuarAbqm }, { baia });
    console.log("animal", animal);
    console.log("dadosEvento", dadosEvento);
    console.log("dadosCategoria", dadosCategoria);
    console.log("competidor", competidor);
    console.log("niveisCompetidorCategoriaEvento", niveisCompetidorCategoriaEvento);
    console.log("limiteDeInscricoesPelaAbqm", limiteDeInscricoesPelaAbqm);
    console.log("confirmacaoInscricaoAnimalExiste", confirmacaoInscricaoAnimalExiste);
    console.log("categoriasBaias", categoriasBaias);
    console.log("campeonatos", campeonatos);
  }

  const checkIfEntryExists = async (animal) => {
    if (animal.aConfirmar) return true; // More than one slot can be entered in a class
    const url = `/animais/${animal.id}/inscrito?idEvento=${dadosEvento.id}&idCategoria=${dadosCategoria.categoria.id}`;
    try {
      const response = await axios.get(url);
      const data = response.data;
      return !data.consta || `O animal ${animal.nome} já está inscrito na categoria ${dadosCategoria.categoria.nome}`;
    } catch (err) {
      console.error(err);
      return `Erro conectando ao servidor! ${err}`;
    }
  };

  const checkIfCompetitorAndHorseAreEnteredInTheMainClassWhenEnteringASubordinateClass = async (competidor) => {
    if (!dadosCategoria.categoria.categoriaPrimordial) return true; // If this is not a subordinate class, then the restriction does not apply
    // check current list of entries to be made first
    if (session?.inscricoes) {
      const inscricoes = session.inscricoes;
      const inscricao = inscricoes.find(inscricao => inscricao.dadosCategoria.evento.id === dadosEvento.id && inscricao.dadosCategoria.categoria.id === dadosCategoria.categoria.categoriaPrimordial.id && inscricao.competidor.id === competidor.id && inscricao.animal.id === animal.id);
      if (inscricao) return true; // If the main class already has the same entry to be made
    }
    // now check existing entries
    const url = `/categorias/${dadosCategoria.categoria.id}/conjunto_inscrito_na_categoria_primordial?idEvento=${dadosEvento.id}&idAfiliado=${competidor.id}&idAnimal=${animal.id}`;
    try {
      const response = await axios.get(url);
      const data = response.data;
      return data.consta || `A inscrição do animal ${animal.nome} e competidor ${competidor.nome} precisa ser efetuada na categoria ${dadosCategoria.categoria.categoriaPrimordial?.nome} antes de efetuar a inscrição na categoria ${dadosCategoria.categoria.nome}`;
    } catch (err) {
      console.error(err);
      return `Erro conectando ao servidor! ${err}`;
    }
  };

  const checkIfCompetitorHasHitTheLimitForEntriesInThisClass = async (competidor) => {
    const url = `/afiliados/${competidor.id}/inscrito?idEvento=${dadosEvento.id}&idCategoria=${dadosCategoria.categoria.id}`;
    try {
      const response = await axios.get(url);
      const data = response.data;
      return !data.atingiuLimite || `O competidor ${competidor.nome} já tem ${data.quantidadeDeInscricoes} inscrições na categoria ${dadosCategoria.categoria.nome}`;
    } catch (err) {
      console.error(err);
      return `Erro conectando ao servidor! ${err}`;
    }
  };

  const restricaoIdadeAnimalMensagemDeErro = () => {
    if (!dadosEvento || !dadosCategoria) return;
    const restrictionCoverage = dadosCategoria.restricaoIdade ? `A categoria ${dadosCategoria.categoria.nome}` : `O evento ${dadosEvento.nome}`;
    if ((dadosCategoria.restricaoIdade?.idadeMinima ?? dadosEvento.restricaoIdade?.idadeMinima) === (dadosCategoria.restricaoIdade?.idadeMaxima ?? dadosEvento.restricaoIdade?.idadeMaxima)) return `${restrictionCoverage} é para animais de ${dadosCategoria.restricaoIdade?.idadeMinima ?? dadosEvento.restricaoIdade?.idadeMinima} anos hípicos!`;
    if ((dadosCategoria.restricaoIdade?.idadeMinima ?? dadosEvento.restricaoIdade?.idadeMinima) === null || (dadosCategoria.restricaoIdade?.idadeMinima ?? dadosEvento.restricaoIdade?.idadeMinima) === undefined) return `${restrictionCoverage} é para animais de até ${dadosCategoria.restricaoIdade?.idadeMaxima ?? dadosEvento.restricaoIdade?.idadeMaxima} anos hípicos!`;
    if ((dadosCategoria.restricaoIdade?.idadeMaxima ?? dadosEvento.restricaoIdade?.idadeMaxima) === null || (dadosCategoria.restricaoIdade?.idadeMaxima ?? dadosEvento.restricaoIdade?.idadeMaxima) === undefined) return `${restrictionCoverage} é para animais com ${dadosCategoria.restricaoIdade?.idadeMinima ?? dadosEvento.restricaoIdade?.idadeMinima} anos hípicos ou mais!`;
    return `${restrictionCoverage} é para animais de ${dadosCategoria.restricaoIdade?.idadeMinima ?? dadosEvento.restricaoIdade?.idadeMinima} a ${dadosCategoria.restricaoIdade?.idadeMaxima ?? dadosEvento.restricaoIdade?.idadeMaxima} anos hípicos!`;
  };

  const restricaoIdadeCompetidorMensagemDeErro = () => {
    if (dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada !== null && dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada === dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada) return `A categoria ${dadosCategoria.categoria.nome} é para competidores que tem ${dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada} anos de idade no início da temporada!`;
    if (dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada !== null && dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada !== null) return `A categoria ${dadosCategoria.categoria.nome} é para competidores que tem de ${dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada} a ${dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada} anos de idade no início da temporada!`;
    if (dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada !== null) return `A categoria ${dadosCategoria.categoria.nome} é para competidores que tem pelo menos ${dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada} anos de idade no início da temporada!`;
    if (dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada !== null) return `A categoria ${dadosCategoria.categoria.nome} é para competidores que tem até ${dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada} anos de idade no início da temporada!`;
    if (dadosCategoria.categoria.competidorIdadeMinimoNoDia !== null) return `A categoria ${dadosCategoria.categoria.nome} é para competidores com pelo menos ${dadosCategoria.categoria.competidorIdadeMinimoNoDia} anos de idade!`;
    return `A categoria ${dadosCategoria.categoria.nome} é para competidores que tem ${dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada} a ${dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada} anos de idade no início da temporada!`;
  };

  const dadosAnimal = (animal) =>
    animal.aConfirmar
      ? ''
      : <>
        {animal.raca &&
          <>
            {`Raça: ${animal.raca}`}
            <br />
          </>}
        {animal.registro &&
          <>
            {`Registro: ${animal.registro}`}
            <br />
          </>}
        {idadeEmAnosHipicos(animal?.nascimento ? new Date(animal.nascimento) : null, dadosEvento?.temporada) &&
          <>
            {`Idade: ${idadeEmAnosHipicos(animal?.nascimento ? new Date(animal.nascimento) : null, dadosEvento?.temporada)} anos hípicos`}
            <br />
          </>}
        {animal.nomesECidadesProprietarios &&
          <>
            {`Proprietário: ${animal.nomesECidadesProprietarios}`}
            <br />
          </>}
        {animal.nrhaLicense &&
          <>
            {`Licença NRHA: ${animal.nrhaLicense}`}
            <br />
          </>}
        {animal.nrhaOwnerName &&
          <>
            {`Proprietário na Licença: ${animal.nrhaOwnerName}`}
            <br />
          </>}
        {animal.idNominacao &&
          <>
            {`Nominação: ${animal.idNominacao}`}
          </>}
      </>
    ;

  const mensagemPontuacaoAbqm = () => {
    let complemento;
    if (animal?.abqm === false) {
      complemento = `(O animal ${animal?.nome} não é Quarto de Milha)`;
    } else {
      complemento = `(O competidor ${competidor?.nome} já tem `;
      if (limiteDeInscricoesPelaAbqm?.quantidadeDeInscricoesDeAnimaisInteiros > 0) {
        complemento += `${limiteDeInscricoesPelaAbqm?.quantidadeDeInscricoesDeAnimaisInteiros} animais inteiros `;

      }
      if (limiteDeInscricoesPelaAbqm?.quantidadeDeInscricoes > limiteDeInscricoesPelaAbqm?.quantidadeDeInscricoesDeAnimaisInteiros) {
        if (limiteDeInscricoesPelaAbqm?.quantidadeDeInscricoesDeAnimaisInteiros > 0) {
          complemento += `e `;
        }
        complemento += `${(limiteDeInscricoesPelaAbqm?.quantidadeDeInscricoes ?? 0) - (limiteDeInscricoesPelaAbqm?.quantidadeDeInscricoesDeAnimaisInteiros ?? 0)} animais machos castrados `;
      }
      complemento += `inscritos para pontuar na categoria ${dadosCategoria?.categoria.nome})`;
    }
    return <p>Não pontuar <small>{complemento}</small></p>;
  };

  let steps = [
    {
      label: 'Evento',
      caption: 'Selecione o evento para qual deseja inscrever',
      content:
        eventosComInscricoesAbertas
          ? <RHookFormRadio
            sx={{ mt: 1 }}
            fullWidth
            formHelperTextErrorClassName="formSelectErrorMessage"
            name="evento"
            rules={{ required: true }}
            options={eventosComInscricoesAbertas["hydra:member"]?.map(option => {
              return {
                value: option.id.toString(),
                label: option.nome,
              };
            })}
            helperText=" "
            errorMessages={
              [{
                type: "required",
                message: "É necessário informar o evento!",
              }]}
          />
          : <CircularProgress />
      ,
    },
    {
      label: 'Categoria',
      caption: 'Selecione a categoria em qual irá competir',
      content:
        <RHookFormRadio
          sx={{ mt: 1 }}
          fullWidth
          formHelperTextErrorClassName="formSelectErrorMessage"
          name="categoria"
          rules={{ required: true }}
          // filter is used below to stop showing extra classes that may have been returned from Api when evento was null
          options={categoriasEvento["hydra:member"]?.filter(option => option.evento?.id.toString() === evento)?.map(option => {
            return {
              value: option.categoria.id.toString(),
              label: option.categoria.nome,
            };
          })}
          helperText=" "
          errorMessages={
            [{
              type: "required",
              message: "É necessário informar a categoria!",
            }]}
        />
      ,
    },
    {
      label: 'Animal',
      caption: 'Selecione o animal que será apresentado',
      content:
        <>
          {categoriasEvento["hydra:member"]?.find(option => option.categoria.id.toString() === categoria)?.restricaoIdade?.descricao
            ? <Typography>{categoriasEvento["hydra:member"]?.find(option => option.categoria.id.toString() === categoria)?.restricaoIdade?.descricao}</Typography>
            : null}
          <RHookFormAutocompleteWithGet
            label="Digite o nome, registro ou proprietário do animal"
            name="animal"
            optionLabel="nome"
            apiSearchUrl="/animais/search?name="
            required={true}
            rules={{
              validate: {
                aConfirmarNaAberta: value => !(value.aConfirmar && dadosCategoria.categoria.aberta) || `Não é permitido fazer inscrições com animais a confirmar na categoria ${dadosCategoria.categoria.nome}. Favor, verifique o nome do animal que deseja inscrever.`,
                restricaoIdade: value =>
                  value.aConfirmar
                  || dadosCategoria === null
                  || (dadosCategoria.restricaoIdade === null && dadosEvento.restricaoIdade === null)
                  || (
                    ((dadosCategoria.restricaoIdade?.idadeMinima ?? dadosEvento.restricaoIdade?.idadeMinima) === null || (dadosCategoria.restricaoIdade?.idadeMinima ?? dadosEvento.restricaoIdade?.idadeMinima) <= idadeEmAnosHipicos(value?.nascimento ? new Date(value.nascimento) : null, dadosEvento?.temporada))
                    && ((dadosCategoria.restricaoIdade?.idadeMaxima ?? dadosEvento.restricaoIdade?.idadeMaxima) === null || (dadosCategoria.restricaoIdade?.idadeMaxima ?? dadosEvento.restricaoIdade?.idadeMaxima) >= idadeEmAnosHipicos(value?.nascimento ? new Date(value.nascimento) : null, dadosEvento?.temporada))
                  )
                  || restricaoIdadeAnimalMensagemDeErro()
                ,
                semCompetitionLicense: value => dadosCategoria?.exigirCompetitionLicense === false || (dadosCategoria?.exigirCompetitionLicense && !value.aConfirmar && value.nrhaLicense?.length > 0) || `Precisa ter licença de competição da NRHA para competir na categoria ${dadosCategoria.categoria.nome}`,
                semNominacao: value => dadosCategoria?.exigirNominacao === false || (dadosCategoria?.exigirNominacao && !value.aConfirmar && value.idNominacao > 0) || `Precisa ser nominado para competir na categoria ${dadosCategoria.categoria.nome}`,
                inscricaoExiste: async (value) => await checkIfEntryExists(value),
              }
            }}
            error={!!errors?.animal}
            filterOptions={(options, { inputValue }) => options} // don't filter out any of the options returned from the API (As it also searches by owner and registration, not just by animal name)
          />
          <FormHelperText className={errors?.animal ? "formError" : null}>
            {errors?.animal
              ? errors.animal.message
              : "Se o nome não aparecer na lista, favor, cadastre o animal."}
          </FormHelperText>
          {animal
            ? <Typography>
              {dadosAnimal(animal)}
            </Typography>
            : null
          }
        </>,
    },
    {
      label: 'Competidor',
      caption: 'Selecione o competidor que irá apresentar o animal',
      content:
        <>
          <RHookFormAutocompleteWithGet
            label="Digite o nome do competidor"
            name="competidor"
            optionLabel="nome"
            apiSearchUrl="/competidores/search?name="
            required={true}
            rules={{
              validate: {
                restricaoIdadeECompetidorNaoTemNascimentoNoCadastro: value => !(
                  (
                    dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada !== null
                    || dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada !== null
                    || dadosCategoria.categoria.competidorIdadeMinimoNoDia !== null
                  ) && !value.nascimento) || `O competidor ${value.nome} não poderá ser inscrito na categoria ${dadosCategoria.categoria.nome} ainda, porque essa categoria tem restrições de idade para participar e a data de nascimento não consta no cadastro dele. Favor, enviar um documento dele com foto para a ANCR para atualizar o cadastro antes de efetuar essa inscrição.`,
                restricaoIdade: value =>
                  value.aConfirmar
                  || dadosCategoria === null
                  || dadosCategoria.categoria === null
                  || (
                    (
                      dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada === null
                      || getAgeAtDate(value.nascimento, `${dadosEvento.temporada}-07-01T00:00:00+00:00`) >= dadosCategoria.categoria.competidorIdadeMinimoNoInicioDaTemporada
                    ) && (
                      dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada === null
                      || getAgeAtDate(value.nascimento, `${dadosEvento.temporada}-07-01T00:00:00+00:00`) <= dadosCategoria.categoria.competidorIdadeMaximoNoInicioDaTemporada
                    ) && (
                      dadosCategoria.categoria.competidorIdadeMinimoNoDia === null
                      || getAgeAtDate(value.nascimento, dadosEvento.dataInicioDateTime) >= dadosCategoria.categoria.competidorIdadeMinimoNoDia
                    )
                  )
                  || restricaoIdadeCompetidorMensagemDeErro()
                ,
                aConfirmarNaNonPro: value => !(value.aConfirmar && dadosCategoria.categoria.aberta === false) || `Não é permitido fazer inscrições com competidor a confirmar na categoria ${dadosCategoria.categoria.nome}. Favor, verifique o nome do competidor que deseja inscrever.`,
                profissionalNaNonPro: value => !(value.aberta && dadosCategoria.categoria.profissionalPodeCompetir === false) || `Não é permitido fazer inscrições de competidores profissionais na categoria ${dadosCategoria.categoria.nome}.`,
                competitorNivel1OuAcimaNaPrincipiante: value => dadosCategoria?.categoria.principiante === false
                  || (dadosCategoria.categoria.principiante === true
                    && (
                      (dadosCategoria.categoria.aberta === true && value.nivelAberta === 0)
                      || (dadosCategoria.categoria.aberta === false && value.nivelAmador === 0)
                    )
                  ) || `O competidor ${value.nome} é nível ${dadosCategoria.categoria.aberta === true ? value.nivelAberta : value.nivelAmador} e não pode competir na categoria ${dadosCategoria.categoria.nome}.`,
                limitePorCategoria: async (value) => await checkIfCompetitorHasHitTheLimitForEntriesInThisClass(value),
                conjuntoInscritoNaCategoriaPrimordial: async (value) => await checkIfCompetitorAndHorseAreEnteredInTheMainClassWhenEnteringASubordinateClass(value),
              }
            }}
            error={!!errors?.competidor}
          />
          <FormHelperText className={errors?.competidor ? "formError" : null}>
            {errors?.competidor
              ? errors.competidor.message
              : "Se o nome não aparecer na lista, favor, afilie o competidor."}
          </FormHelperText>
        </>
    },
    {
      label: 'Níveis',
      caption: 'Selecione os níveis de competição',
      content:
        <RHookFormRadio
          sx={{ mt: 1 }}
          fullWidth
          formHelperTextErrorClassName="formSelectErrorMessage"
          name="grupoSubCategoria"
          rules={{
            // required: true,
            validate: {
              // Note: This has to be done this way, as Chrome recognises 
              // the empty string for classes that don't have levels as not completing the field, 
              // and gives the 'required field' error
              required: value => value !== defaultValues.grupoSubCategoria || `É necessário informar os níveis!`,
            }
          }}
          options={niveisCompetidorCategoriaEvento["hydra:member"]?.length
            ? niveisCompetidorCategoriaEvento["hydra:member"].map(option => {
              return {
                value: option.id.toString(),
                label: option.descricao,
              };
            })
            : [{
              value: "",
              label: "Nível Único",
            }]
          }
          helperText=" "
          errorMessages={
            [{
              type: "required",
              message: "É necessário informar os níveis!",
            }]}
        />
      ,
    },
    {
      label: 'Pontuar na NRHA',
      caption: `Deseja que essa inscrição seja enviada à NRHA para possível pontuação/acumulo de ganhos?`,
      content:
        <>
          <RHookFormRadio
            sx={{ mt: 1 }}
            fullWidth
            formHelperTextErrorClassName="formSelectErrorMessage"
            name="pontuarNrha"
            rules={{ required: true }}
            options={dadosCategoria?.pontuarNrha
              ? optionsForPoints.map(option => {
                return {
                  value: option.id.toString(),
                  label: option.descricao,
                };
              })
              : [{
                value: false,
                label: <p>Não pontuar <small>(Os resultados da categoria {dadosCategoria?.categoria.nome} não serão enviados para pontuação)</small></p>,
              }]
            }
            helperText=" "
            errorMessages={
              [{
                type: "required",
                message: "É necessário escolher!",
              }]}
          />
          <Typography variant='caption'>
            Para possível pontuação é necessário que o competidor e proprietário
            sejam sócios ativos da NRHA e o animal tenha Licença de Competição.
          </Typography>
        </>
      ,
    },
    {
      label: 'Pontuar na ABQM',
      caption: `Gostaria que essa inscrição seja enviada para a ABQM para possível pontuação?`,
      content:
        <>
          <RHookFormRadio
            sx={{ mt: 1 }}
            fullWidth
            formHelperTextErrorClassName="formSelectErrorMessage"
            name="pontuarAbqm"
            rules={{ required: true }}
            options={(animal?.abqm && (
              limiteDeInscricoesPelaAbqm?.atingiuLimiteDeAnimaisInteiros === false
              || (animal?.castrado === true && limiteDeInscricoesPelaAbqm?.atingiuLimite === false))
            )
              ? optionsForPoints.map(option => {
                return {
                  value: option.id.toString(),
                  label: option.descricao,
                };
              })
              : [{
                value: false,
                label: mensagemPontuacaoAbqm(),
              }]
            }
            helperText=" "
            errorMessages={
              [{
                type: "required",
                message: "É necessário escolher!",
              }]}
          />
          <Typography variant='caption'>
            Para possível pontuação é necessário que seja sócio ativo da ABQM.
          </Typography>
        </>
      ,
    },
    {
      label: 'Baia',
      caption: `Gostaria de reservar uma baia${animal ? ' para ' + animal.nome : ''}?`,
      content:
        <RHookFormRadio
          sx={{ mt: 1 }}
          fullWidth
          formHelperTextErrorClassName="formSelectErrorMessage"
          name="baia"
          rules={{ required: true }}
          options={confirmacaoInscricaoAnimalExiste?.baia === false
            ? [...categoriasBaias["hydra:member"]?.map(option => {
              return {
                value: option.categoriaBaia.id.toString(),
                label: option.categoriaBaia?.descricao.trim() ? option.categoriaBaia.descricao : DEFAULT_STALL_TYPE_DESCRIPTION_IF_BLANK,
              };
            }),
            {
              value: 0,
              label: <p>Sem baia</p>,
            }]
            : [{
              value: 0,
              label: <p>Não <small>(Já consta uma reserva para este animal)</small></p>,
            }]
          }
          helperText=" "
          errorMessages={
            [{
              type: "required",
              message: "É necessário escolher!",
            }]}
        />
      ,
    },
  ];

  if (dadosEvento?.copaInternucleos) {
    steps.push({
      label: 'Copa Internúcleos',
      caption: `Deseja representar qual núcleo?`,
      content:
        <RHookFormRadio
          sx={{ mt: 1 }}
          fullWidth
          formHelperTextErrorClassName="formSelectErrorMessage"
          name="representa"
          rules={{ required: true }}
          options={campeonatos["hydra:member"].length
            ? campeonatos["hydra:member"]?.map(option => {
              return {
                value: option.id.toString(),
                label: option.nucleo,
              };
            })
            : [{
              value: 0,
              label: <p>Sem opções</p>,
            }]
          }
          helperText=" "
          errorMessages={
            [{
              type: "required",
              message: "É necessário escolher!",
            }]}
        />
      ,
    })
  }

  const disableContinueButton = (index) =>
    (index === 0 && evento === defaultValues.evento)
    || (index === 1 && categoria === defaultValues.categoria)
    || (index === 2 && (animal === defaultValues.animal || !!errors.animal))
    || (index === 3 && (competidor === defaultValues.competidor || !!errors.competidor))
    || (index === 4 && (grupoSubCategoria === defaultValues.grupoSubCategoria || !!errors.grupoSubCategoria))
    || (index === 5 && (pontuarNrha === defaultValues.pontuarNrha || !!errors.pontuarNrha))
    || (index === 6 && (pontuarAbqm === defaultValues.pontuarAbqm || !!errors.pontuarAbqm))
    || (index === 7 && (baia === defaultValues.baia || !!errors.baia))
    || (index === 8 && (representa === defaultValues.representa || !!errors.representa));

  const checkUserIsAuthenticated = async () => {
    try {
      await axios.get('/checkuserisauthenticated',
        {
          headers: {
            'Authorization': `Bearer ${session.token}`
          },
        });
    } catch (error) {
      // debugging:
      if (process.env.NODE_ENV !== 'production') {
        console.error(error);
      }
      if (error?.response?.status === 401) {
        // if login has expired then show modal and redirect to login page
        setModalMessage({
          type: "error",
          message: "Seu login já expirou. Efetue o login novamente.",
        });
        setModalOpen(true);
      }
    }
  };

  // check on page mount 
  useEffect(() => {
    if (!session) return;
    checkUserIsAuthenticated();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // and check again every 5 minutes 
  useInterval(async () => {
    if (!session) return;
    checkUserIsAuthenticated();
  }, CHECK_VALID_SESSION_INTERVAL);

  // if not logged in then show modal and redirect to login page
  if (!session && !modalOpen) {
    setModalMessage("Favor efetuar o login");
    setModalOpen(true);
    // return;
  }

  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 2, mb: 0 }}
      >
        <Grid item xs={12} md={9}>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>

              <EntryFormHeaderRow session={session} headerText="Adicionar inscrição" />
              <Typography component='p'>Siga os passos abaixo para efetuar sua inscrição.</Typography>

              <Box sx={{ maxWidth: 400 }}>
                <Stepper activeStep={activeStep} orientation="vertical">
                  {steps.map((step, index) => (
                    <Step key={step.label}>
                      <StepLabel
                        optional={
                          step.caption ? (
                            <Typography variant="caption">{step.caption}</Typography>
                          ) : null
                        }
                      >
                        {step.label}
                      </StepLabel>
                      <StepContent>
                        {step.content}
                        <Box sx={{ mb: 2 }}>
                          <div>
                            <Button
                              variant="contained"
                              onClick={handleNext}
                              sx={{ mt: 1, mr: 1 }}
                              disabled={disableContinueButton(index)}
                            >
                              {index === steps.length - 1 ? 'Adicionar Inscrição' : 'Continuar'}
                            </Button>
                            <Button
                              disabled={index === 0}
                              onClick={handleBack}
                              sx={{ mt: 1, mr: 1 }}
                            >
                              Voltar
                            </Button>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length && (
                  <Paper square elevation={0} sx={{ p: 3 }}>
                    <Typography>Proceder para o formulário de pagamento</Typography>
                    <Button
                      variant="outlined"
                      onClick={handleReset}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Adicionar outra inscrição
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => navigate('/efetuar')}
                      sx={{ mt: 1, mr: 1 }}
                    >
                      Pagamento
                    </Button>
                  </Paper>
                )}
              </Box>


            </form>
          </FormProvider>
          {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
        </Grid>
      </Grid>
      <AlertDialog open={modalOpen} setOpen={setModalOpen} title="Erro" message={modalMessage} clearSessionOnClose session={session} clear={clear} navigateUrlOnClose="/login" />
    </>
  )
};

export default EntryForm;