import React from 'react'
import { Box } from '@mui/material';

export default function EventLogo(props) {
  const { alt, src } = props;
  return (
    <Box
      sx={{
        float: {
          sm: "right",
        },
        width: {
          sm: "200px",
        },
        display: {
          xs: "flex",
          sm: "block"
        },
        justifyContent: "center",
        alignItems: "center",
        pt: {
          xs: 2,
          md: 1,
        }
      }}
    ><Box
        component="img"
        sx={{
          height: {
            xs: "90px",
            md: "100px",
            lg: "100px",
          },
        }}
        alt={alt}
        src={src}
      /></Box>
  )
};