import React from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

export default function EventLinks(props) {
  const { event, withHeader, sx } = props;

  if (event === undefined || event === null) {
    return <CircularProgress />;
  }

  const buttonStyles = {
    textAlign: "center",
    fontSize: {
      xs: "0.75rem",
      md: "0.9rem",
    },
    lineHeight: { md: "1rem" },
  };

  return (
    <Box sx={sx}>
      {withHeader && (
        <Box
          sx={{
            display: {
              xs: "flex",
              md: "block",
            },
            flexWrap: {
              xs: "wrap",
              md: null,
            },
            justifyContent: {
              xs: "center",
              md: null,
            },
            alignItems: {
              xs: "center",
              md: null,
            },
          }}
        >
          <Typography variant="h2">
            Links
          </Typography>
        </Box>
      )}
      <Box
        sx={{
          display: {
            xs: "flex",
            sm: "block",
          },
          flexWrap: {
            xs: "wrap",
            sm: null,
          },
          justifyContent: {
            xs: "center",
            sm: null,
          },
          alignItems: {
            xs: "center",
            sm: null,
          },
        }}
      >
        <Button
          color="primary"
          key="oeDisponivel"
          sx={buttonStyles}
          disabled={!event.oeDisponivel}
          component={RouterLink}
          to={`/eventos/${event.id}/ordementrada`}
        >
          Ordem de Entrada
        </Button>
        <Button
          color="primary"
          key="fim"
          sx={buttonStyles}
          disabled={!event.fim}
          component={RouterLink}
          to={`/eventos/${event.id}/resultados`}
        >
          Resultados
        </Button>
        <Button
          color="primary"
          key="fichasJulgamento"
          sx={buttonStyles}
          disabled={!event.fichasJulgamento}
          component={RouterLink}
          to={`/eventos/${event.id}/fichasjulgamento`}
        >
          Fichas de Julgamento
        </Button>
        <Button
          color="primary"
          key="fotosEvento"
          sx={buttonStyles}
          disabled={!event.fotosEvento}
          component={RouterLink}
          to={`/eventos/${event.id}/fotos`}
        >
          Fotos
        </Button>
        <Button
          color="primary"
          key="videosEvento"
          sx={buttonStyles}
          disabled={!event.videosEvento}
          component={RouterLink}
          to={`/eventos/${event.id}/videos`}
        >
          Vídeos
        </Button>
        {event.inscricoesAbertas
          ? <Button
            color="primary"
            key="inscricoesEvento"
            sx={buttonStyles}
            disabled={!event.inscricoesAbertas}
            component={RouterLink}
            to={`/inscricoes`}
          >
            Inscreve-se
          </Button>
          : null}
      </Box>
    </Box>
  );
}
