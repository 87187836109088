import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Endereco from './Endereco';
import { Button, Container } from '@mui/material';
import logotipoWestern from '../images/logotipo_Western.png'
import {
  NOME_ASSOCIACAO,
  API_SERVER_ROOT_ADDRESS,
} from '../constants';

const footerLinkStyles = {
  color: "#fff !important",
  m: 0,
  fontSize: {
    md: "2.5rem",
    xs: "0.85rem !important"
  }
};

export default function Footer(props) {
  const { menus, enderecoAssociacao } = props;

  return (
    <>
      <Box component={'div'} sx={{
        backgroundColor: 'primary.main',
        color: '#FFF',
        mt: 8,
        p: 4,
      }}>
        <Container maxWidth={'lg'}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="stretch"

          >
            {[...new Set(menus.map(item => item.fila))].map((fila, index) =>
              <Grid key={fila} item sx={{ display: { xs: "none", md: "block" } }} md={2}>
                {menus.map(menu =>
                  menu.fila === fila && menu.footer &&
                  <React.Fragment key={menu.titulo}>
                    <Typography
                      variant="h3"
                      sx={{
                        textTransform: 'uppercase',
                        pb: 0,
                        color: "#fff !important"
                      }}
                    >
                      {menu.titulo}
                    </Typography>

                    <List sx={{ p: 0, }} component="nav" aria-label="Principal">
                      {menu.itens.map((item, index) =>
                        <ListItem key={index} button sx={{
                          p: 0,
                          color: "#fff",

                          "&:hover": {
                            backgroundColor: "#24618E !important"
                          }
                        }}>
                          <Link href={item.url} sx={footerLinkStyles}>
                            <ListItemText inset primary={item.title} sx={{
                              pl: 1,
                              "span": {

                                color: "#fff !important"
                              }
                            }} />
                          </Link>
                        </ListItem>
                      )}
                    </List>
                  </React.Fragment>
                )}
              </Grid>
            )}




            {
          /** versão mobile */}
            <Grid item sx={{ display: { xs: "block", md: "none" }, pl: { xs: "0.5em", md: "inherit" } }} xs={6}>
              {menus.map((menu, index) =>
                index % 2 === 0 && menu.footer &&
                <React.Fragment key={menu.titulo}>
                  <Typography
                    variant="h3"
                    sx={{
                      textTransform: 'uppercase',
                      pb: 0,
                      color: "#fff !important"
                    }}
                  >
                    {menu.titulo}
                  </Typography>

                  <List sx={{ p: 0, }} component="nav" aria-label="Principal">
                    {menu.itens.map((item, index) =>
                      <ListItem key={index} button sx={{
                        p: 0,
                        color: "#fff",

                        "&:hover": {
                          backgroundColor: "#24618E !important"
                        }
                      }}>
                        <Link href={item.url} sx={footerLinkStyles}>
                          <ListItemText inset primary={item.title} sx={{
                            pl: 1,
                            "span": {

                              color: "#fff !important"
                            }
                          }} />
                        </Link>
                      </ListItem>
                    )}
                  </List>
                </React.Fragment>
              )}
            </Grid>

            <Grid item sx={{ display: { xs: "block", md: "none" }, pl: { xs: "1em", md: "inherit" } }} xs={6}>
              {menus.map((menu, index) =>
                index % 2 !== 0 && menu.footer &&
                <React.Fragment key={menu.titulo}>
                  <Typography
                    variant="h3"
                    sx={{
                      textTransform: 'uppercase',
                      pb: 0,
                      color: "#fff !important"
                    }}
                  >
                    {menu.titulo}
                  </Typography>

                  <List sx={{ p: 0, }} component="nav" aria-label="Principal">
                    {menu.itens.map((item, index) =>
                      <ListItem key={index} button sx={{
                        p: 0,
                        color: "#fff",

                        "&:hover": {
                          backgroundColor: "#24618E !important"
                        }
                      }}>
                        <Link href={item.url} sx={footerLinkStyles}>
                          <ListItemText inset primary={item.title} sx={{
                            pl: 1,
                            "span": {

                              color: "#fff !important"
                            }
                          }} />
                        </Link>
                      </ListItem>
                    )}
                  </List>
                </React.Fragment>
              )}
            </Grid>

            <Grid item xs={12}>

              <Divider
                color='secondary'
                sx={{
                  my: 8,
                  mx: 'auto',
                  backgroundColor: '#f9f9f9',
                  width: '35%'
                }}
              />

            </Grid>

            <Grid item xs={12} sm={12} md={1} lg={2} xl={2}>
              <Box textAlign="center">
                <Box
                  component="img"
                  src="/images/logotipo-ANCR-branco.svg"
                  alt='ANCR - Associação Nacional do Cavalo de Rédeas'
                  sx={{
                    width: {
                      xs: "50%",
                      sm: "20%",
                      md: "100%",
                      lg: "100%",
                    },
                    textAlign: {

                      xs: "center",
                      sm: "center",
                      md: "right",
                      lg: "right",
                    },
                    maxHeight: { xs: "80px", md: "auto" },
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={9} lg={8} xl={8}
              sx={{
                px: 3,
                textAlign: {
                  xs: "center",
                  md: "left",
                },
              }}
            >
              <Typography
                variant="h3"
                sx={{
                  textTransform: 'uppercase',
                  pb: 0,

                  color: "#fff"
                }}
              >
                {NOME_ASSOCIACAO}
              </Typography>
              <Typography variant="body1" sx={{
                color: "#fff !important"
              }}>
                <Endereco endereco={enderecoAssociacao} linear={true} />
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={1} lg={2} xl={2} sx={{
              mt: {
                md: "inherit",
                xs: 3
              }
            }}>
              <Button href='https://instagram.com/redeaslw' target='_blank'>
                <Box component={'img'} src={logotipoWestern} sx={{
                  width: {
                    md: "100%",
                    xs: "50%"
                  }, height: "auto"
                }} />
              </Button>
            </Grid>

            {(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_API_SERVER !== 'prod')
              ? <Box mt={2}>
                <small>Modo de execução: <b>{process.env.NODE_ENV}</b><br />Usando o servidor de API: <b>{process.env.REACT_APP_API_SERVER} ({API_SERVER_ROOT_ADDRESS})</b></small>
              </Box>
              : null
            }
          </Grid>
        </Container>
      </Box>
    </>
  );
}