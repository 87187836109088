import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  TextField,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const RHookFormDateField = (props) => {
  const {
    name,
    label,
    className,
    formHelperTextClassName,
    formHelperTextErrorClassName,
    fullWidth,
    rules,
    helperText,
    errorMessages,
    disabled,
    readOnly,
    sx,
  } = props;

  const {
    control,
    formState: { errors }
  } = useFormContext();

  const helperTextMessage = () => {
    if (!errorMessages) return helperText ?? '';
    if (errors?.[name]) {
      const errorMessage = errorMessages.find(errorArray => errors[name].type === errorArray.type);
      if (!errorMessage) return helperText ?? '';
      return errorMessage.message;
    }

    return helperText ?? ' ';
  }

  return (
    <React.Fragment>
      <FormControl
        sx={sx}
        className={className}
        fullWidth={fullWidth}
        error={!!errors?.[name]}
        disabled={disabled}
      >
        <InputLabel
          variant="standard"
          htmlFor={name}
          shrink
        >{label}</InputLabel>
        <Controller
          name={name}
          control={control}
          rules={rules}
          render={({ field: { ref, ...rest } }) => <DatePicker
            inputRef={ref}
            readOnly={readOnly}
            inputFormat="DD/MM/yyyy"
            {...rest}
            renderInput={(params) =>
              <TextField
                sx={{ mt: 2 }}
                variant="standard"
                {...params}
              />}
          />}
        />
      </FormControl>
      <FormHelperText className={errors?.[name] ? formHelperTextErrorClassName : formHelperTextClassName}>
        {helperTextMessage()}
      </FormHelperText>
    </React.Fragment>

  );
}

export default RHookFormDateField;
