import React from 'react';
import { Helmet } from "react-helmet-async";
import { useGetData } from "../../axiosHook/useRequestData";
import { Typography, CircularProgress, Alert } from '@mui/material';
import {
  formatDateStringWithSlashes,
  redirectRelativeImageLinks,
  checkIfValidUUID,
} from "../../functions";
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const alternatingFloatedImageStyles = {
  "fontFamily": "Foco Regular",
  "& img:nth-of-type(odd)": {
    width: {
      xs: "98%",
      sm: "65%",
    },
    height: 'auto',
    margin: '2%',
    float: {
      xs: undefined,
      sm: 'left',
    }
  },
  "& img:nth-of-type(even)": {
    width: {
      xs: "98%",
      sm: "65%",
    },
    height: 'auto',
    margin: '2%',
    float: {
      xs: undefined,
      sm: 'right',
    }
  },
};

const NewsArticle = (props) => {
  const { helmetContext, id } = props;

  const [article = '', loading, { error }] = useGetData(`/noticias/${checkIfValidUUID(id) ? id : ''}`);

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />
  if (!article?.titulo?.length) return <Alert severity="error">A notícia não foi localizada!</Alert>

  return (
    <>
      <Helmet>
        <title>{`${article?.titulo} | ${helmetContext.title}`}</title>
        <meta name="description" content={article?.chamada} />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />
      <Typography variant="h2">{article?.titulo}</Typography>
      <Typography component="p" sx={{ fontSize: "0.75rem" }}>{article ? formatDateStringWithSlashes(article.dataNoticia) : ''}</Typography>
      <div dangerouslySetInnerHTML={{ __html: redirectRelativeImageLinks(article?.texto) }} sx={alternatingFloatedImageStyles}></div>
    </>
  )
};

export default NewsArticle;