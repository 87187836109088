import React from 'react';
import ReactPlayer from "react-player";

const ResponsivePlayer = (props) => {
  // console.log(props);
  return (
    <div className='player-wrapper'>
      <ReactPlayer
        className='react-player'
        width='100%'
        height='100%'
        {...props}
      />
    </div>
  )
}

export default ResponsivePlayer