import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { useForm, FormProvider } from "react-hook-form";
import {
  DEBUG_FORM_DATA,
} from "../../constants";
import { axiosInstance as axios } from "../../axiosHook/axiosInstance";
import {
  // AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import { FormHelperText, IconButton, InputAdornment, LinearProgress } from '@mui/material';
import RHookFormTextField from '../../form/RHookFormTextField';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import {
  jwtToObject,
} from '../../functions';
import RHookFormAutocompleteWithGet from '../../form/RHookFormAutocompleteWithGet';
import { Visibility, VisibilityOff } from '@mui/icons-material';


const defaultValues = {
  afiliado: null,
  senha: "",
};


const FirstLoginForm = (props) => {
  const { save, setModalMessage, setModalTitle, setModalOpen, setModalNavigateUrlOnClose, setModalClearSessionOnClose } = props;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(showPassword => !showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmittingRHF },
    watch,
    reset,
    control,
  } = methods;

  const onSubmit = async data => {
    setIsSubmitting(true);
    const userData = {
      username: `${data.afiliado?.id}`,
      password: data.senha,
    }
    // console.log(userData);
    axios
      .post(`/login_check`, userData)
      .then((response) => {
        const responseData = response.data;
        if (responseData.token) {
          reset(); // reset form
          save({
            ...jwtToObject(responseData.token),
            nome: data.afiliado.nome,
            id: data.afiliado.id,
            token: responseData.token,
          })
          // redirect to user logged in page
        } else {
          if (process.env.NODE_ENV !== 'production') {
            console.log(responseData);
          }
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setModalTitle("Error");
          if (error.response.data?.message === "Too many failed login attempts, please try again in 1 minute.") {
            setModalMessage({
              type: "error",
              message: "Número de tentativas esgotado. Tente novamente em 1 minuto.",
            });
          } else {
            setModalMessage({
              type: "error",
              message: "A combinação de nome e senha não está correta. Tente novamente.",
            });
          }
        } else {
          console.log("error", error);
          setModalTitle("Error");
          setModalMessage({
            type: "error",
            message: error.response?.data?.detail ?? error.message,
          });
        }
        setModalNavigateUrlOnClose("");
        setModalClearSessionOnClose(false);
        setModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const afiliado = watch('afiliado');

  const enviarLembreteDeSenha = () => {
    if (!afiliado) {
      setModalTitle("Error");
      setModalMessage({
        type: "error",
        message: "Selecione seu nome na lista",
      });
      setModalNavigateUrlOnClose("");
      setModalClearSessionOnClose(false);
      setModalOpen(true);
      return;
    }
    setIsSubmitting(true);
    const userData = {
      id: afiliado?.id,
    };
    console.log(userData);
    axios
      .post(`/lembrete`, userData)
      .then((response) => {
        const responseData = response.data;
        setModalTitle("Sucesso");
        setModalMessage(responseData.message);
        setModalNavigateUrlOnClose("");
        setModalClearSessionOnClose(false);
        setModalOpen(true);
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          setModalTitle("Error");
          if (error.response.data?.message === "Too many failed login attempts, please try again in 1 minute.") {
            setModalMessage({
              type: "error",
              message: "Número de tentativas esgotado. Tente novamente em 1 minuto.",
            });
          } else {
            setModalMessage({
              type: "error",
              message: "Não foi possível enviar o lembrete no momente. Tente novamente mais tarde.",
            });
          }
        } else {
          console.log("error", error);
          setModalTitle("Error");
          setModalMessage({
            type: "error",
            message: error.response?.data?.detail ?? error.message,
          });
        }
        setModalNavigateUrlOnClose("");
        setModalClearSessionOnClose(false);
        setModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };


  return (
    <>
      <Typography variant="h2">
        Seleciona seu nome e digite sua senha de 4 dígitos
      </Typography>

      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <RHookFormAutocompleteWithGet
            label="Nome"
            name="afiliado"
            optionLabel="nome"
            apiSearchUrl="/afiliados?search="
            required={true}
            error={!!errors?.afiliado}
          />
          <FormHelperText className={errors?.afiliado ? "formError" : null}>
            {errors?.afiliado
              ? "É necessário selecionar o nome!"
              : "Se o nome não aparecer na lista, será necessário fazer um cadastro."}
          </FormHelperText>

          <Grid container columnSpacing={2}>
            <Grid item xs={12} sm={6}>
              <RHookFormTextField
                name="senha"
                label="Senha"
                type={showPassword ? 'text' : 'password'}
                sx={{ mt: 1 }}
                fullWidth
                inputProps={{
                  maxLength: 9,
                }}
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        size='small'
                      >
                        {showPassword ? <VisibilityOff fontSize="small" /> : <Visibility fontSize="small" />}
                      </IconButton>
                    </InputAdornment>
                }}
                rules={{ required: true, minLength: 4 }}
                errorMessages={
                  [{
                    type: "required",
                    message: "É necessário digitar a senha!",
                  }, {
                    type: "minLength",
                    message: "Favor informar a senha completa!",
                  }]}

              />
            </Grid>
          </Grid>

          <LinearProgress sx={{
            pt: 1,
            opacity: isSubmittingRHF ? "1" : "0",
          }} />

          <Button variant="contained" fullWidth color="secondary" sx={{ px: 2 }} type="submit" disabled={isSubmitting}>Enviar</Button><br />
          <Button variant="outlined" fullWidth sx={{ mt: 3 }} onClick={() => enviarLembreteDeSenha()}>Esqueci minha senha</Button>
          <FormHelperText>Caso não lembrar sua senha, selecione seu nome na lista e depois clicar para receber o lembrete no seu email cadastrado</FormHelperText>
        </form>
        {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
      </FormProvider>
    </>
  );
};

export default FirstLoginForm;