import React, { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { Helmet } from "react-helmet-async";
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';
import { Grid, Typography, CircularProgress } from '@mui/material';
import {
  // formatMoney,
} from '../../functions';
// import {
//   EVENTO_TEST_DATA,
//   CATEGORIAS_RESULTADOS,
// } from "../test-data";
import EventLinks from './EventLinks';
import { Alert } from '@mui/material';
import EventHeader from './EventHeader';
import { EventTotalPrizeMoney } from './EventTotalPrizeMoney';
import EventClassTabBar from './EventClassTabBar';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

export default function EventWorkingOrdersAndResults(props) {
  const { helmetContext, ordemEntrada } = props;
  const params = useParams()
  const id = parseInt(params.id);

  const [event, setEvent] = useState({});
  const [eventClassList, setEventClassList] = useState({});

  const getData = (id, ordemEntrada) => {
    axios
      .get(`/eventos/${id}`)
      .then((response) => {
        const data = response.data;
        setEvent(data);
      })
      .catch((error) => {
        setEvent({
          "error": error,
        });
        console.log("error", error);
      });

    const urlCategorias = ordemEntrada ? '/categoriasordementrada' : '/categoriasresultados';
    axios
      .get(urlCategorias + `?idEvento=${id}`)
      .then((response) => {
        const data = response.data;
        setEventClassList(data);
      })
      .catch((error) => {
        setEvent({
          "error": error,
        });
        console.log("error", error);
      });

  };

  // Puxar os dados da API quando monta o component, ou se mudar o id do evento
  useEffect(() => {
    if (id) {
      getData(id, ordemEntrada);
    }
  }, [id, ordemEntrada]);

  // console.log({id, ordemEntrada});
  // console.log(event);
  // console.log(eventClassList);

  if (!id) return <Alert severity="error">404: Evento não localizado!</Alert>

  return (
    <>
      <Helmet>
        <title>{`${event?.nome} | ${helmetContext.title}`}</title>
        <meta name="description" content={event?.descricao} />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 1, mb: 0 }}
      >
        <Grid item key="main" md={12}>
          {event.nome?.length > 0
            ? <>
              <EventHeader event={event} />
              {(event.fim && !ordemEntrada) || (ordemEntrada && event.oeDisponivel) ?
                eventClassList?.length > 0
                  ? <>
                    <Typography variant="h2" sx={{ mt: 2 }}>{ordemEntrada ? 'Ordem de Entrada' : 'Resultados'}</Typography>
                    <EventClassTabBar categorias={eventClassList} />
                    <Outlet context={[event, eventClassList]} />
                  </>
                  : <CircularProgress />

                : <Typography>{ordemEntrada ? 'A Ordem de Entrada não está disponível para este evento no momento.' : 'Resultados não disponíveis para este evento no momento.'}</Typography>
              }
              {!ordemEntrada && <EventTotalPrizeMoney event={event} />}
              {!ordemEntrada && event.soMelhoresColocados &&
                <Typography align="center">**Estes resultados não são completos e representam apenas os melhores colocados.**</Typography>
              }
            </>
            : event.error
              ? <Alert severity="error">{`${event.error}`}</Alert>
              : <CircularProgress />
          }
        </Grid>
        <Grid item key="links" md={12}>

          <EventLinks event={event} withHeader={true} />

        </Grid>
      </Grid>
    </>
  )

}