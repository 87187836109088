import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { CardMedia } from '@mui/material';

export default function VideoDialog(props) {
  const { title, open, setOpen, videoLink } = props;

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth={true}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      {title && <DialogTitle id="responsive-dialog-title">{title}</DialogTitle>}
      <DialogContent>
        <CardMedia
          component='iframe'
          title={title}
          src={videoLink}
          muted=''
          autoPlay={true}
          allowFullScreen={true}
          sx={{
            width: "100%",
            height: "100%",
            minHeight: "50vh",
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary">
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}