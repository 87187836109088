import React from 'react';
import {
  // RANKING_TABLE_TEST_DATA,
} from "../../test-data";
import { useGetData } from "../../axiosHook/useRequestData";
import {
  formatMoney,
  formatScores,
} from "../../functions";
import { Alert, Typography } from '@mui/material';
import { CircularProgress } from '@mui/material';
import Link from '@mui/material/Link';
import BasicDataTable from '../BasicDataTable';
import { Helmet } from 'react-helmet-async';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const rankingsDisponiveis = [
  {
    nome: 'competidor',
    title: 'Competidor',
    endpoint: "ranking_competidor",
    id: "idCompetidor",
    dados: "competidor",
    link: "/competidores/",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'animal',
    title: 'Animal',
    endpoint: "ranking_animal",
    id: "idAnimal",
    dados: "animal",
    link: "/animais/",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'pai',
    title: 'Reprodutor',
    endpoint: "ranking_reprodutor",
    dados: "pai",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'mae',
    title: 'Reprodutora',
    endpoint: "ranking_reprodutora",
    dados: "mae",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'criador',
    title: 'Criador',
    endpoint: "ranking_criador",
    dados: "criador",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'proprietario',
    title: 'Proprietário',
    endpoint: "ranking_proprietario",
    dados: "proprietario",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'competidorprincipiante',
    title: 'Competidor Principiante',
    endpoint: "ranking_competidor_principiante",
    id: "idCompetidor",
    dados: "competidor",
    link: "/competidores/",
    pontos: true,
    premiacao: true,
  }];

const rankingsAcumuladosDisponiveis = [
  {
    nome: 'competidor',
    title: 'Competidor',
    endpoint: "ranking_geral_competidor",
    id: "idCompetidor",
    dados: "competidor",
    link: "/competidores/",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'animal',
    title: 'Animal',
    endpoint: "ranking_geral_animal",
    id: "idAnimal",
    dados: "animal",
    link: "/animais/",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'pai',
    title: 'Reprodutor',
    endpoint: "ranking_geral_reprodutor",
    dados: "pai",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'mae',
    title: 'Reprodutora',
    endpoint: "ranking_geral_reprodutora",
    dados: "mae",
    pontos: true,
    premiacao: true,
  }, {
    nome: 'criador',
    title: 'Criador',
    endpoint: "ranking_geral_criador",
    dados: "criador",
    pontos: true,
    premiacao: true,
  }];

const EarningsText = (props) => {
  const { showEarnings, earnings, points } = props;
  return showEarnings
    ? formatMoney(earnings)
    : formatScores(points)
};

function setParams({ temporada, minTemporada, acumulado, aberta }) {
  const searchParams = new URLSearchParams();
  if (temporada !== undefined && temporada !== null) {
    searchParams.set("temporada", parseInt(temporada));
  }
  if (minTemporada !== undefined && minTemporada !== null) {
    searchParams.set("min_temporada", parseInt(minTemporada));
  }
  if (acumulado !== undefined && acumulado !== null) {
    searchParams.set("acumulado", parseInt(acumulado));
  }
  if (aberta !== undefined && aberta !== null) {
    searchParams.set("aberta", aberta);
  }
  return searchParams.toString();
}

const getRankingEndpoint = (acumulado, temporada, minTemporada, ranking) => {
  if (acumulado && minTemporada) {
    return {
      nome: 'competidor',
      title: 'Competidor',
      endpoint: "ranking_competidor_acc_2ah",
      id: "idCompetidor",
      dados: "competidor",
      link: "/competidores/",
      pontos: true,
      premiacao: true,
    };
  }

  if (acumulado && temporada) {
    return {
      nome: 'competidor',
      title: 'Competidor',
      endpoint: "ranking_competidor_acc_fah",
      id: "idCompetidor",
      dados: "competidor",
      link: "/competidores/",
      pontos: true,
      premiacao: true,
    };
  }

  return acumulado ? rankingsAcumuladosDisponiveis.find(endpoint => endpoint.nome === ranking) : rankingsDisponiveis.find(endpoint => endpoint.nome === ranking);
}

const alignColumns = [
  'left',
  'left',
];

export default function RankingTable(props) {
  const { helmetContext, temporada, minTemporada, ranking, acumulado, aberta } = props;

  const rankingEndpoint = getRankingEndpoint(acumulado, temporada, minTemporada, ranking);

  const [data = [], loading, { error }] = useGetData(`premiacao_campeonatos/${rankingEndpoint.endpoint}?${setParams({ temporada: temporada, minTemporada: minTemporada, acumulado: acumulado, aberta: aberta })}`);
  const rankingData = data["hydra:member"] ? data["hydra:member"] : data;

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  const rankingTitle = () => {
    let title = `Ranking ${rankingEndpoint.title}`;
    if (temporada) return `${title} ${temporada}/${temporada + 1}`;
    return title + ' Geral';
  };

  const rankingTableHeaders = () => [
    `Col.`,
    `${rankingEndpoint.title}${(aberta !== null && aberta !== undefined) ? aberta ? ' Aberta' : ' Amador' : ''}`,
    rankingEndpoint.premiacao ? 'Ganhos' : 'Pontos',
  ];

  const rankingTableRows = (rankingData) => {
    if (!rankingData) return [];

    return rankingData.map(data => {
      let row = [];
      row.push(
        rankingEndpoint.link
          ? <Link href={rankingEndpoint.link + data[rankingEndpoint.id]} color="primary">{`${data.colocacao}º`}</Link>
          : `${data.colocacao}º`
      );
      row.push(
        rankingEndpoint.link
          ? <Link href={rankingEndpoint.link + data[rankingEndpoint.id]} color="primary">{data[rankingEndpoint.dados]}</Link>
          : data[rankingEndpoint.dados]
      );
      row.push(
        rankingEndpoint.link
          ? <Link href={rankingEndpoint.link + data[rankingEndpoint.id]} color="primary">
            <EarningsText showEarnings={rankingEndpoint.premiacao} earnings={data.premiacao} points={data.pontos} />
          </Link>
          : <EarningsText showEarnings={rankingEndpoint.premiacao} earnings={data.premiacao} points={data.pontos} />
      )
      return row;
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${rankingTitle()} | ${helmetContext.shortTitle}`}</title>
        <meta name="description" content="Confira o Ranking" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, notícias, informativos, informações" />
      </Helmet>
      <ScrollToTopOnMount />

      {rankingData.length
        ? <BasicDataTable
          title={rankingTitle()}
          paper={true}
          headers={rankingTableHeaders()}
          rows={rankingTableRows(rankingData)}
          alignColumns={alignColumns}
          striped
        />
        : <Typography component="p" sx={{ pt: 2, mb: 0 }}>
          Nenhum registro localizado. Tente outra categoria.
        </Typography>
      }
    </>
  );
}