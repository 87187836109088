import { Alert } from '@mui/material';
import React from 'react'
import { useOutletContext, useParams } from 'react-router-dom';
import WorkingOrderAndResultTable from './WorkingOrderAndResultTable';
import { CircularProgress } from '@mui/material';

const EventClassContent = (props) => {
  const { ordemEntrada } = props;
  const [event, categorias] = useOutletContext();

  const params = useParams()
  const idCategoria = parseInt(params.idCategoria);

  // console.log(ordemEntrada, event, categorias, idCategoria)

  if (!idCategoria) return <Alert severity='error'>Categoria não localizada!</Alert>
  if (!categorias) return <CircularProgress />

  const categoria = categorias.find(categoria => categoria.id === idCategoria);
  // console.log({ categoria })

  if (!categoria) return <Alert severity='error'>Categoria não localizada!</Alert>

  return (
    <>
      {categoria.classificatorias
        ? categoria.classificatorias.map(classificatoria =>
          <React.Fragment key={classificatoria.id}>
            {/* {console.log({ ordemEntrada, classificatoria })} */}
            {ordemEntrada
              ? classificatoria.finalizado
                ? classificatoria.subCategorias
                  ? classificatoria.subCategorias.map(subCategoria =>
                    <WorkingOrderAndResultTable key={`categoria${categoria.id}-classificatoria${classificatoria.id}-subCategoria${subCategoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={classificatoria} subCategoria={subCategoria} divisao={null} />
                  )
                  : classificatoria.light
                    ? classificatoria.light.map(soLight =>
                      <WorkingOrderAndResultTable key={`categoria${categoria.id}-classificatoria${classificatoria.id}-light${soLight.light}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={classificatoria} subCategoria={null} light={soLight.light} />
                    )
                    : <WorkingOrderAndResultTable key={`categoria${categoria.id}-classificatoria${classificatoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={classificatoria} subCategoria={null} divisao={null} />
                : classificatoria.divisoes
                  ? classificatoria.divisoes.map(divisao =>
                    <WorkingOrderAndResultTable key={`categoria${categoria.id}-classificatoria${classificatoria.id}-divisao${divisao.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={classificatoria} subCategoria={null} divisao={divisao} />
                  )
                  : <WorkingOrderAndResultTable key={`categoria${categoria.id}-classificatoria${classificatoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={classificatoria} subCategoria={null} divisao={null} />
              : classificatoria.subCategorias
                ? classificatoria.subCategorias.map(subCategoria =>
                  <WorkingOrderAndResultTable key={`categoria${categoria.id}-classificatoria${classificatoria.id}-subCategoria${subCategoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={classificatoria} subCategoria={subCategoria} divisao={null} />
                )
                : classificatoria.light
                  ? classificatoria.light.map(soLight =>
                    <WorkingOrderAndResultTable key={`categoria${categoria.id}-classificatoria${classificatoria.id}-light${soLight.light}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={classificatoria} subCategoria={null} light={soLight.light} />
                  )
                  : <WorkingOrderAndResultTable key={`categoria${categoria.id}-classificatoria${classificatoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={classificatoria} subCategoria={null} divisao={null} />
            }
          </React.Fragment>
        )
        : ordemEntrada
          ? categoria.finalizado
            ? categoria.subCategorias
              ? categoria.subCategorias.map(subCategoria =>
                <WorkingOrderAndResultTable key={`categoria${categoria.id}-subCategoria${subCategoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={null} subCategoria={subCategoria} divisao={null} />
              )
              : <WorkingOrderAndResultTable key={`categoria${categoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={null} subCategoria={null} divisao={null} />
            : categoria.divisoes
              ? categoria.divisoes.map(divisao =>
                <WorkingOrderAndResultTable key={`categoria${categoria.id}-divisao${divisao.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={null} subCategoria={null} divisao={divisao} />
              )
              : <WorkingOrderAndResultTable key={`categoria${categoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={null} subCategoria={null} divisao={null} />
          : categoria.subCategorias
            ? categoria.subCategorias.map(subCategoria =>
              <WorkingOrderAndResultTable key={`categoria${categoria.id}-subCategoria${subCategoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={null} subCategoria={subCategoria} divisao={null} />
            )
            : <WorkingOrderAndResultTable key={`categoria${categoria.id}`} ordemEntrada={ordemEntrada} evento={event} categoria={categoria} classificatoria={null} subCategoria={null} divisao={null} />
      }
    </>
  )
}

export default EventClassContent;
