import { axiosInstance as axios } from '../axiosHook/axiosInstance';
import queryString from 'query-string';
import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import throttle from 'lodash/throttle';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';

export default function RHookFormAutocompleteWithGet(props) {
  const { name, label, optionLabel, apiSearchUrl, required, error, disabled, rules, filterOptions } = props;

  const { control, setValue: setReactHookFormValue, getValues } = useFormContext();

  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(null);
  const [options, setOptions] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [inputValue, setInputValue] = React.useState('');


  const getList = async (search, callback) => {
    // Add a request interceptor (fix for square brackets issue https://github.com/axios/axios/issues/3316)
    axios.interceptors.request.use(function (config) {
      // Serialize the parameteters
      config.paramsSerializer = params => queryString.stringify(params, { arrayFormat: 'brackets' })
      return config
    });
    axios
      .get(search)
      .then((response) => {
        const data = response.data;
        callback(data['hydra:member']);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const throttledGetList = React.useMemo(
    () =>
      throttle((search, callback) => {
        getList(search, callback);
      }, 200),
    [],
  );

  React.useEffect(() => {
    let active = true; // used to cancel setOptions state update on form close if an api request is in process 

    if (inputValue === '') {
      setOptions(getValues(name) ? [getValues(name)] : []);
      if (getValues(name)) {
        setValue(getValues(name));
      }
      return undefined;
    }
    setLoading(true);

    throttledGetList(apiSearchUrl + inputValue, (results) => {
      if (active) {
        let newOptions = [];

        if (value && !_.find(results, value)) {
          newOptions = [value]; // add selected option to top of list if not already there (avoids duplicates)
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);

      }
    });
    return () => {
      active = false;
    };

  }, [apiSearchUrl, inputValue, throttledGetList, value, getValues, name]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Controller
      name={name}
      control={control}
      rules={{ required: required, ...rules }}
      render={({ field: { ref, ...field } }) =>
        <Autocomplete
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          value={value}
          inputValue={inputValue}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          filterOptions={filterOptions}
          getOptionLabel={(option) => (typeof option === 'string' ? option : option[optionLabel].toString())}
          options={options}
          disabled={disabled}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onChange={(event, newValue) => {
            setOptions(newValue ? [newValue, ...options] : options);
            setReactHookFormValue(name, newValue, { shouldValidate: true });
            setValue(newValue);
          }}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              error={error}
              variant="standard"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      }
    />
  );
}