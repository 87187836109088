import React from 'react';
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = {
  dialogHeader: {
    background: "linear-gradient(90deg, rgba(2,62,124,1) 0%, rgba(0,75,153,1) 100%)",
    color: "#fff"
  },
  dialogHeaderError: {
    background: "linear-gradient(90deg, rgba(153,0,0,1) 0%, rgba(255,0,0,1) 100%)",
    color: "#fff"
  },
  modalContent: {
    padding: "1em",
    borderBottom: "solid 1px #ddd",
  },
  alignIcon:
    { verticalAlign: "middle" }

};

export default function AlertDialog(props) {
  const { title, message, open, setOpen, navigateUrlOnClose, clearSessionOnClose, session, clear } = props;

  let navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);

    if (clearSessionOnClose && session) {
      clear();
    }

    if (navigateUrlOnClose) {
      navigate(navigateUrlOnClose);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
      >
        <DialogTitle id="alert-dialog-title" style={message?.type === "error" ? styles.dialogHeaderError : styles.dialogHeader}>
          {message?.type === "error"
            ? <ErrorIcon style={styles.alignIcon} />
            : <CheckCircleIcon style={styles.alignIcon} />
          }{" "}{title}
        </DialogTitle>
        <DialogContent style={styles.modalContent}>
          <DialogContentText id="alert-dialog-description">
            {message?.message ?? message}
          </DialogContentText>
        </DialogContent>
        <DialogActions >
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}