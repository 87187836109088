import React, { useState, useEffect, /* useCallback */ } from "react";
import { useUrlSearchParams } from "use-url-search-params";
import { useGetData } from "../../axiosHook/useRequestData";
import Grid from "@mui/material/Grid";
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Pagination from '@mui/material/Pagination';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import FilterAltIcon from '@mui/icons-material/FilterList';
import FilterNoneIcon from '@mui/icons-material/FilterNone';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import { Helmet } from "react-helmet-async";
import { CircularProgress, IconButton, InputAdornment, LinearProgress, MenuItem, Select } from "@mui/material";
import {
  EVENT_ITENS_POR_PAGINA as ITENS_POR_PAGINA,
  RESET_SEARCH_START_DATE_TO,
  RESET_SEARCH_END_DATE_TO_THIS_MANY_YEARS_FROM_NOW,
} from "../../constants";
import {
  // formatDateStringWithSlashes,
} from "../../functions";
import { axiosInstance as axios } from "../../axiosHook/axiosInstance";
import queryString from 'query-string';
import moment from 'moment';
import {
  AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import EventLinks from "./EventLinks";
import { Alert } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import SearchIcon from '@mui/icons-material/Search';
import ScrollToTopOnMount from "../ScrollToTopOnMount";
import EventHeader from "./EventHeader";

function setApiParams({ pesquisa, page, somenteEventosANCR, somenteEventosNucleos, campeonato, somenteEventosFinalizados, dataIni, dataFim, order }) {
  const searchParams = new URLSearchParams();
  searchParams.set("itemsPerPage", ITENS_POR_PAGINA);
  if (pesquisa) {
    searchParams.set("search", pesquisa || "");
  }
  if (page) {
    searchParams.set("page", page);
  }
  if (somenteEventosANCR && !campeonato && !somenteEventosNucleos) {
    searchParams.set("provaAncr", true);
  }
  if (campeonato) {
    searchParams.set("campeonato.id", campeonato);
  }
  if (somenteEventosNucleos && !somenteEventosANCR) {
    searchParams.set("provaAncr", false);
  }
  if (somenteEventosFinalizados) {
    searchParams.set("fim", true);
  }
  if (dataIni) {
    searchParams.set("dataIni[gte]", moment(dataIni).format("YYYYMMDD"));
  }
  if (dataFim) {
    searchParams.set("dataFim[lte]", moment(dataFim).format("YYYYMMDD"));
  }
  searchParams.set("order[dataIni]", order);
  return searchParams.toString();
}

const initial = {
  page: 1,
  campeonato: NaN,
  ancr: true,
  nucleos: true,
  resultados: false,
  futuros: true,
  videos: false,
  fotos: false,
  dataIni: null,
  dataFim: null,
  order: "asc",
};

const types = {
  page: Number,
  campeonato: Number,
  ancr: Boolean,
  nucleos: Boolean,
  resultados: Boolean,
  futuros: Boolean,
  videos: Boolean,
  fotos: Boolean,
  dataIni: Date,
  dataFim: Date,
  order: ["asc", "desc"],
};

const opcaoNenhumCampeonatoEspecifico = {
  value: 0,
  label: "Todos",
};

export default function EventList(props) {
  const { helmetContext } = props;

  const [params, setParams] = useUrlSearchParams(initial, types);
  // console.log(params);

  const [updating, setUpdating] = useState(false);
  const [lista, setLista] = useState([]);
  const [error, setError] = useState(null);

  const totalItens = lista["hydra:totalItems"];
  const totalPaginas = Math.ceil(lista["hydra:totalItems"] / ITENS_POR_PAGINA);

  const ultimaPesquisa = params.search ?? "";
  // const campeonato = params.campeonato ?? null;

  const [campoPesquisa, setCampoPesquisa] = useState(ultimaPesquisa ?? "");
  const [dataIni, setDataIni] = useState("");
  const [dataFim, setDataFim] = useState("");
  const [campeonatos = AFFILIATE_LIST_DEFAULT_DATA] = useGetData('/campeonatos');
  const listaDeCampeonatos = campeonatos["hydra:member"]
    ? [opcaoNenhumCampeonatoEspecifico, ...campeonatos["hydra:member"].map(opcao => {
      return {
        value: opcao.id,
        label: opcao.nucleo,
      };
    })]
    : [opcaoNenhumCampeonatoEspecifico];
  // This option to add 0 as an index, is to keep params.campeonato as defined above as a Number
  const campeonatoIndex = isNaN(params.campeonato) ? 0 : params.campeonato;

  const events = lista["hydra:member"];

  const resetFilters = () => {
    setDataIni(moment(RESET_SEARCH_START_DATE_TO));
    setDataFim(moment().add(RESET_SEARCH_END_DATE_TO_THIS_MANY_YEARS_FROM_NOW, 'years'));
    setCampoPesquisa("");
    setParams({ search: "", page: 1, dataIni: moment(RESET_SEARCH_START_DATE_TO).toISOString(), dataFim: moment().add(RESET_SEARCH_END_DATE_TO_THIS_MANY_YEARS_FROM_NOW, 'years').toISOString(), campeonato: null });
    exibiFiltros(false);
  };

  // const getDefaultDates = useCallback((resultados, videos, fotos, campeonato) => {
  //   setUpdating(true);
  //   const url = `/eventosatuais?resultados=${Number(resultados)}&fotos=${Number(fotos)}&videos=${Number(videos)}&itemsPerPage=${ITENS_POR_PAGINA}&campeonato=${campeonato}`;
  //   // console.log({ url });
  //   axios.get(url)
  //     .then(response => {
  //       const data = response.data;
  //       setDataIni((resultados || videos || fotos) ? RESET_SEARCH_START_DATE_TO : moment(data.dataIni));
  //       setDataFim(moment(data.dataFim));
  //       setParams({
  //         dataIni: moment((resultados || videos || fotos) ? RESET_SEARCH_START_DATE_TO : data.dataIni).toISOString(),
  //         dataFim: moment(data.dataFim).toISOString()
  //       });
  //     }).catch(error => {
  //       setError(error);
  //       console.log("error", error);
  //     });
  // }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getList = (page, pesquisa, somenteEventosANCR, somenteEventosNucleos, campeonato, somenteEventosFinalizados, somenteEventosComVideos, somenteEventosComFotos, somenteEventosFuturos, dataIni, dataFim, order) => {
    setUpdating(true);
    // console.log('getting data for page', page);
    const urlStart = () => {
      if (somenteEventosComVideos && somenteEventosComFotos) {
        return 'eventos/com_videos_e_fotos';
      }
      if (somenteEventosComVideos) {
        return 'eventos/com_videos';
      }
      if (somenteEventosComFotos) {
        return 'eventos/com_fotos';
      }
      if (somenteEventosFuturos) {
        return 'eventos/futuros';
      }
      return 'eventos';
    };
    const url = `/${urlStart()}?${setApiParams({ pesquisa, page, somenteEventosANCR, somenteEventosNucleos, campeonato, somenteEventosFinalizados, somenteEventosComVideos, somenteEventosComFotos, dataIni, dataFim, order })}`;
    // console.log({ url });
    // Add a request interceptor (fix for square brackets issue https://github.com/axios/axios/issues/3316)
    axios.interceptors.request.use(function (config) {
      // Serialize the parameteters
      config.paramsSerializer = params => queryString.stringify(params, { arrayFormat: 'brackets' })
      return config
    });
    axios.get(url)
      .then(response => {
        const data = response.data;
        setLista(data);
        setUpdating(false);
      }).catch(error => {
        setError(error);
        console.log("error", error);
      });
  };

  // Puxar os dados da API quando monta o component, ou se mudar a pagina
  // useEffect(() => {
  //   if (events !== undefined) {
  //     return;
  //   }
  //   getDefaultDates(params.resultados, params.videos, params.fotos, params.campeonato);
  // }, [getDefaultDates, params.resultados, params.videos, params.fotos, params.campeonato]); // eslint-disable-line react-hooks/exhaustive-deps 

  useEffect(() => {
    getList(params.page, params.search, params.ancr, params.nucleos, params.campeonato, params.resultados, params.videos, params.fotos, params.futuros, params.dataIni, params.dataFim, params.order);
  }, [params.page, params.search, params.ancr, params.nucleos, params.campeonato, params.resultados, params.videos, params.fotos, params.futuros, params.dataIni, params.dataFim, params.order]);

  const [exibiFiltro, exibiFiltros] = useState(false);

  const handlePageChange = (event, value) => {
    setParams({ page: value });
  };

  return (<>
    <Helmet>
      <title>{`Eventos | ${helmetContext.title}`}</title>
      <meta name="description" content="Confira os eventos da modalidade de Rédeas" />
      <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, notícias, informativos, informações" />
    </Helmet>
    <ScrollToTopOnMount />

    <Typography variant="h2" component="h1">
      {params.resultados ? 'Resultados' : 'Eventos'}
    </Typography>
    <Typography component="p" sx={{ pt: 2, mb: 0 }} >
      {params.resultados ? 'Confira os resultados disponíveis.' : 'Confira o calendário de eventos.'}
    </Typography>


    {exibiFiltro ?
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        sx={{
          backgroundColor: '#f4f4f4',
          p: 2,
        }}
      >
        <Grid item xs={12} sm={4}>
          <TextField
            id="textoBusca"
            label="Pesquisa"
            type="text"
            variant="standard"
            placeholder="Digite o nome ou local do evento que procura"
            fullWidth
            value={campoPesquisa}
            onChange={(event) => setCampoPesquisa(event.target.value)}
            onKeyPress={(e) => { return e.key === 'Enter' ? setParams({ search: e.target.value, page: 1 }) : null }}
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Pesquisa"
                    onClick={() => setParams({ search: campoPesquisa, page: 1 })}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
            }}
          />
          <FormControlLabel
            value="check"
            control={<Checkbox color="primary"
              checked={params.ancr}
            />}
            label="Incluir Eventos Oficias da ANCR"
            onChange={() => {
              if (params.ancr && !params.nucleos) {
                setParams({ nucleos: true, ancr: !params.ancr });
              } else {
                setParams({ ancr: !params.ancr });
              }
              if (params.page > 1) {
                setParams({ page: 1 });
              }
            }}
          />
          <FormControlLabel
            value="check"
            control={<Checkbox color="primary"
              checked={params.nucleos}
            />}
            label="Incluir Eventos de Núcleos"
            onChange={() => {
              if (params.nucleos) {
                setParams({ campeonato: null, page: 1 });
              }
              if (!params.ancr && params.nucleos) {
                setParams({ ancr: true, nucleos: !params.nucleos, page: 1 });
              } else {
                setParams({ nucleos: !params.nucleos, page: 1 });
              }
            }}
          />
          <FormControlLabel
            value="check"
            control={<Checkbox color="primary"
              checked={params.futuros}
            />}
            label="Apenas Eventos Futuros"
            onChange={() => {
              if (!params.futuros) {
                setParams({ futuros: !params.futuros, resultados: false, videos: false, fotos: false, page: 1 });
              } else {
                setParams({ futuros: !params.futuros, page: 1 });
              }
            }}
          />
          <FormControlLabel
            value="check"
            control={<Checkbox color="primary"
              checked={params.resultados}
            />}
            label="Apenas Eventos com Resultados"
            onChange={() => {
              if (!params.resultados) {
                setParams({ resultados: !params.resultados, futuros: false, page: 1 });
              } else {
                setParams({ resultados: !params.resultados, page: 1 });
              }
            }}
          />
          <FormControlLabel
            value="check"
            control={<Checkbox color="primary"
              checked={params.videos}
            />}
            label="Apenas Eventos com Vídeos"
            onChange={() => {
              if (!params.videos) {
                setParams({ videos: !params.videos, futuros: false, page: 1 });
              } else {
                setParams({ videos: !params.videos, page: 1 });
              }
            }}
          />
          <FormControlLabel
            value="check"
            control={<Checkbox color="primary"
              checked={params.fotos}
            />}
            label="Apenas Eventos com Fotos"
            onChange={() => {
              if (!params.fotos) {
                setParams({ fotos: !params.fotos, futuros: false, page: 1 });
              } else {
                setParams({ fotos: !params.fotos, page: 1 });
              }
            }}
          />

        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePicker
            id="dataIni"
            label="Data Inicial"
            type="date"
            inputFormat="DD/MM/yyyy"
            value={dataIni}
            onChange={(newValue) => {
              setDataIni(newValue);
              if (newValue && newValue.isAfter("1900-01-01")) {
                setParams({ dataIni: newValue.toISOString(), page: 1 })
              }
            }}
            renderInput={(params) =>
              <TextField
                // fullWidth
                variant="standard"
                sx={{ mt: 2 }}
                {...params}
              />}
            sx={{ m: 1 }}
          />

          <DatePicker
            id="dataFim"
            label="Data Final"
            type="date"
            inputFormat="DD/MM/yyyy"
            value={dataFim}
            onChange={(newValue) => {
              setDataFim(newValue);
              if (newValue && newValue.isAfter("1900-01-01")) {
                setParams({ dataFim: newValue.toISOString(), page: 1 })
              }
            }}
            renderInput={(params) =>
              <TextField
                variant="standard"
                sx={{ mt: 2 }}
                {...params}
              />}
            sx={{ m: 1 }}
          /><br />

          <FormControl variant="standard" sx={{ mt: 1 }}>
            <InputLabel shrink htmlFor="ordenacao">
              Ordenação:
            </InputLabel>
            <NativeSelect
              inputProps={{
                name: 'ordenacao',
                id: 'ordenacao',
              }}
              value={params.order}
              onChange={(e) => setParams({ order: e.target.value })}
            >
              <option value='asc'>CRESCENTE</option>
              <option value='desc'>DECRESCENTE</option>
            </NativeSelect>
          </FormControl>

          <FormControl fullWidth variant="standard" sx={{ mt: 1 }}>
            <InputLabel shrink htmlFor="ordenacao">
              Núcelo:
            </InputLabel>
            <Select
              inputProps={{
                name: 'campeonatos',
                id: 'campeonatos',
              }}
              value={campeonatoIndex}
              onChange={(e) => {
                setParams({ campeonato: e.target.value, page: 1 });
                if (e.target.value) {
                  setParams({ nucleos: true });
                }
              }}
            >
              {listaDeCampeonatos.map(opcao => <MenuItem
                key={opcao.value}
                value={opcao.value}
              >{opcao.label}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Grid>

        <Grid item xs={12} sm={2} sx={{ m: 1 }}>
          <Button
            variant="contained"
            color="grey"
            startIcon={<FilterNoneIcon />}
            sx={{ m: 1 }}
            onClick={() => resetFilters()}>
            Limpar filtros
          </Button>
        </Grid>
      </Grid>
      :
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
        sx={{
          backgroundColor: '#f4f4f4',
          p: 2,
        }}
      >
        <Grid item xs={12} sm={12}>
          <Button variant="contained" color="primary" startIcon={<FilterAltIcon />} sx={{ m: 1 }} onClick={() => exibiFiltros(prev => !prev)}>
            Exibir opções de filtragem
          </Button>
        </Grid>
      </Grid>
    }

    <Divider sx={{ pt: 2, mb: 0 }} />{" "}
    {
      events?.length > 0
        ? (
          <>
            {
              events.map((event, index) => (
                <React.Fragment key={index}>
                  <EventHeader event={event} />
                  <EventLinks event={event} withHeader={false} />
                  <Divider variant="middle" sx={{ pt: 2, mb: 1 }} />
                </React.Fragment>))
            }
            <LinearProgress className={
              updating
                ? ""
                : "hidden"
            } />
            <Typography component="p" sx={{ pt: 2, mb: 0 }}>
              {
                `Você está visualizando os eventos de ${params.page * ITENS_POR_PAGINA - (ITENS_POR_PAGINA - 1)} até ${params.page * ITENS_POR_PAGINA - (
                  ITENS_POR_PAGINA - events
                    ?.length)} em um total de ${totalItens}.`
              }
            </Typography>
            <Pagination
              variant="outlined"
              shape="rounded"
              sx={{ pt: 2 }}
              page={params.page}
              count={totalPaginas}
              onChange={handlePageChange}
            />
          </>
        )
        : lista['hydra:totalItems'] === 0 && (params.search || !isNaN(params.campeonato) || params.resultados || params.videos || params.fotos || params.dataIni || params.dataFim) ?
          <Typography component="p" sx={{ pt: 2, mb: 0 }} >
            Nenhum evento localizado. Tente outra pesquisa.
          </Typography>
          :
          error
            ? <Alert severity="error">{`${error}`}</Alert>
            : <CircularProgress />
    }
  </>);
}