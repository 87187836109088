import React from 'react'
import { useNavigate } from "react-router-dom";
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button';
import { Helmet } from "react-helmet-async";
import {
  NOME_ASSOCIACAO,
} from "../../constants";
import { useGetData } from "../../axiosHook/useRequestData";
import Endereco from '../Endereco';
import { Alert, CircularProgress, IconButton } from '@mui/material';
import {
  // urlImagemCampanha,
} from "../../functions";
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import UpdateMemberForm from './UpdateMemberForm';
import CloseIcon from '@mui/icons-material/Close';

const UpdateMember = (props) => {
  const { helmetContext, enderecoAssociacao, session, clear } = props;

  let navigate = useNavigate();

  const [defaultValues, loading, { error }] = useGetData({
    url: `/afiliados/${session.id}`,
    headers: {
      'Authorization': `Bearer ${session.token}`
    },
  });

  if (error?.request?.status === 401) return (<>
    <Alert
      severity="error"
      action={
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={() => { clear(); navigate('/login'); }}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
      }
    >
      Seu login expirou.
    </Alert>
    <Button variant="contained" color="secondary" sx={{ px: 2, mt: 2 }} onClick={() => { clear(); navigate('/login'); }}>Efetuar Log In novamente</Button>
  </>)

  return <>
    <Helmet>
      <title>{`Dados Associado | ${helmetContext.title}`}</title>
      <meta name="description" content="Associado da ANCR" />
      <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, contato, fale com, telefone, fale com a gente" />
    </Helmet>
    <ScrollToTopOnMount />

    <Grid
      container
      direction="row"
      alignItems="stretch"
      spacing={5}
      sx={{ pt: 1, mb: 0 }}
    >
      <Grid item xs={12} md={7}>

        <Typography variant="h2">
          {session.nome}
        </Typography>
        <Typography component='p'>
          Verifique que seus dados estão atualizados
        </Typography>

        {loading
          ? <CircularProgress />
          : error
            ? <Alert severity="error">{`${error}`}</Alert>
            : <UpdateMemberForm defaultValues={defaultValues} session={session} />}
      </Grid>
      <Grid item md={3} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Typography variant="h2" component='h2'>{NOME_ASSOCIACAO}</Typography>
        <Typography component='p'>
          <Endereco endereco={enderecoAssociacao} linear={false} narrow={true} />
        </Typography>
      </Grid>
    </Grid>
  </>;

};

export default UpdateMember;