import React from 'react';
import { useLocation } from 'react-router-dom';
import {
  RANKING_SEASONS_DEFAULT_DATA,
} from "../../default-data";
import { useGetData } from "../../axiosHook/useRequestData";
import {
  getBoolSearchParameterOrNull,
} from "../../functions";
import { Alert } from '@mui/material';
import { CircularProgress } from '@mui/material';
import RankingSeasonSelector from './RankingSeasonSelector';
import RankingTypeForSeasonSelector from './RankingTypeForSeasonSelector';
import RankingTable from './RankingTable';
import { Helmet } from 'react-helmet-async';


const rankingsDisponiveis = ['competidor', 'animal', 'pai', 'mae', 'criador', 'proprietario', 'competidorprincipiante'];
const rankingOptionsAvailable = ['acumulado', 'aberta'];

export default function Ranking(props) {
  const { helmetContext } = props;

  let location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const temporada = params.has('temporada') ? parseInt(params.get('temporada')) : null;
  const minTemporada = params.has('mintemporada') ? parseInt(params.get('mintemporada')) : null;

  const rankingOptions = rankingOptionsAvailable.reduce((obj, nome) => {
    return {
      ...obj,
      [nome]: getBoolSearchParameterOrNull(params, nome),
    }
  }, {});

  const ranking = rankingsDisponiveis.find(nome => getBoolSearchParameterOrNull(params, nome));
  const [data = RANKING_SEASONS_DEFAULT_DATA, loading, { error }] = useGetData(`eventos/temporadas_ranking`);
  const seasons = data["hydra:member"] ? data["hydra:member"] : data;

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  return (
    <>
      <Helmet>
        <title>{`Ranking | ${helmetContext.title}`}</title>
        <meta name="description" content="Confira a campanha" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, notícias, informativos, informações" />
      </Helmet>

      {!ranking && rankingOptions.acumulado === null && temporada === null
        ? <RankingSeasonSelector seasons={seasons} acumulado={rankingOptions.acumulado} />
        : temporada >= seasons[seasons.length - 1].temporada
          ? !ranking && rankingOptions.acumulado === null
            ? <RankingTypeForSeasonSelector temporada={temporada} />
            : ranking
              ? <RankingTable helmetContext={helmetContext} ranking={ranking} temporada={temporada} minTemporada={minTemporada} acumulado={rankingOptions.acumulado} aberta={rankingOptions.aberta} />
              : <Alert severity="error">Error Message</Alert>
          : !rankingOptions.acumulado && (temporada <= seasons[0].temporada || temporada >= seasons[seasons.length].temporada)
            ? <Alert severity="error">A temporada selecionada não está disponível!</Alert>
            : <RankingTable helmetContext={helmetContext} ranking={ranking} temporada={temporada} minTemporada={minTemporada} acumulado={rankingOptions.acumulado} aberta={rankingOptions.aberta} />
      }
    </>
  );
}