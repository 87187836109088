import React, { useEffect } from 'react';
import { Alert, AppBar, Box, Tab, Tabs } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { useParams, useNavigate } from 'react-router-dom';

const styles = {
  tab: {
    maxWidth: {
      xs: "70px",
      sm: "200px",
    }
  }
}

const EventClassTabBar = (props) => {
  const { categorias } = props;

  const params = useParams()

  const navigate = useNavigate();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down('md'));

  // if no class is selected, then redirect to the first class in the list
  useEffect(() => {
    if (!params.idCategoria && categorias && Array.isArray(categorias) && categorias[0].id) {
      navigate({ pathname: `${categorias[0].id}` });
    }
    if (params.idCategoria && categorias && Array.isArray(categorias) && !categorias.find(categoria => categoria.id === parseInt(params.idCategoria))) {
      // if class id given doesn't match any available classes, then navigate to the first available one
      navigate({ pathname: `${categorias[0].id}` });
    }
  }, [navigate, categorias, params]);

  if (!categorias || !Array.isArray(categorias)) return <Alert severity='error' >Erro: Nenhuma categoria localizada!</Alert>

  const idCategoria = () => {
    if (!params.idCategoria) return categorias[0].id;
    if (!categorias.find(categoria => categoria.id === parseInt(params.idCategoria))) return categorias[0].id;
    return parseInt(params.idCategoria);
  }

  const StyledTab = (props) => {
    return (
      <Tab
        wrapped
        sx={styles.tab}
        onClick={(e) => {
          // console.log(e.target.getAttribute("data-idcategoria"));
          navigate({ pathname: e.target.getAttribute("data-idcategoria") })
        }}
        {...props}
      />
    );
  }

  // console.log(params.idCategoria);

  return (
    <Box sx={{ bgcolor: '#f9f9f9' }}>
      <AppBar position="static">
        <Tabs
          value={idCategoria()}
          indicatorColor="secondary"
          textColor="inherit"
          variant={md ? "scrollable" : "fullWidth"}
          aria-label="Categorias"
          sx={{ maxWidth: "100vw" }}
        >
          {categorias.map(categoria =>
            <StyledTab key={categoria.id} value={categoria.id} label={categoria.nome} data-idcategoria={categoria.id} />
          )}
        </Tabs>
      </AppBar>
    </Box>
  )
};

export default EventClassTabBar;