// API
const API_PRODUCTION_SERVER_ROOT_ADDRESS = "https://api.ancr.org.br"; // AWS EC2 Prod
const API_DEVELOPMENT_SERVER_ROOT_ADDRESS = "https://api-ancr.org.br"; // AWS EC2 Dev
const API_SERVER_ROOT_ADDRESS = process.env.REACT_APP_API_SERVER === 'prod' ? API_PRODUCTION_SERVER_ROOT_ADDRESS : API_DEVELOPMENT_SERVER_ROOT_ADDRESS;
const GET_INSTAGRAM_POSTS_VIA_DEV_SERVER = false;
// const API_SERVER_ROOT_ADDRESS = "http://localhost:8000";

// Header
const LINK_WHATSAPP = "https://wa.me/5519991999279";
const LINK_INSTAGRAM = "https://www.instagram.com/ancroficial/";
const LINK_FACEBOOK = "https://www.facebook.com/ancr.redeas";

// Página inicial
const NEWS_ITENS_POR_PAGINA = 8;
const NEWS_ITENS_NA_PAGINA_INICIAL = 4;
const NEWS_ITENS_NA_LISTA_A_DIREITA = 6;
const NOMINACOES_NA_PAGINA_INICIAL = 3;

const VIDEO_INSTITUCIONAL_DO_YOUTUBE = 'giU5B3cdBxI';

// Dados SEO
const NOME_ASSOCIACAO = "Associação Nacional do Cavalo de Rédeas";
const NOME_ASSOCIACAO_CABECALHO = "Associação Nacional\n do Cavalo de Rédeas";
const NOME_ASSOCIACAO_COM_SIGLA = "ANCR - Associação Nacional do Cavalo de Rédeas";
const SIGLA_ASSOCIACAO = "ANCR";
const HELMET_CONTEXT = {
  title: NOME_ASSOCIACAO_COM_SIGLA,
  shortTitle: SIGLA_ASSOCIACAO,
};

const NUMBER_FORMAT_LOCALE = 'pt-BR';
const CURRENCY_FORMAT = 'BRL';

const NOMINACOES_ITENS_POR_PAGINA = 10;

// Static content
const CONTEUDO_ESTATICO_ITENS_POR_PAGINA = 1;

const EVENT_ITENS_POR_PAGINA = 6;

const YOUTUBE_VIDEO_ADDRESS_ROOT = 'https://www.youtube.com/embed/';

const RESET_SEARCH_START_DATE_TO = '2005-01-01T00:00:00.000Z';
const RESET_SEARCH_END_DATE_TO_THIS_MANY_YEARS_FROM_NOW = 2;

const ANO_INICIO_PROGRAMA_DE_NOMINACAO = 2009;

// forms
const DEBUG_FORM_DATA = process.env.NODE_ENV !== 'production';
const MAX_LENGTH_REGISTRO = 15;
const MIN_LENGTH_REGISTRO = 4;
const MAX_LENGTH_NOME_ANIMAL = 40;
const MIN_LENGTH_NOME_ANIMAL = 4;
const MAX_LENGTH_NOME_ASSOCIADO = 100;
const MIN_LENGTH_NOME_ASSOCIADO = 6;
const MAX_LENGTH_NOME_USUARIO = 14;
const MIN_LENGTH_NOME_USUARIO = 5;
const MIN_LENGTH_SENHA_USUARIO = 4;
const MAX_LENGTH_SENHA_USUARIO = 16;
const PERMITIR_FORMA_DE_PAGAMENTO_CARTAO_DE_CREDITO_PARA_INSCRICOES = true; // Eventos ANCR
// const PERMITIR_FORMA_DE_PAGAMENTO_CARTAO_DE_CREDITO_PARA_INSCRICOES = false; // IV Copa Haras Sacramento
const PERMITIR_FORMA_DE_PAGAMENTO_PIX_PARA_INSCRICOES = true; // Eventos ANCR
// const PERMITIR_FORMA_DE_PAGAMENTO_PIX_PARA_INSCRICOES = false; // IV Copa Haras Sacramento
const DEFAULT_STALL_TYPE_DESCRIPTION_IF_BLANK = 'Normal';

// partners
const PARTNER_START_NUMBER = 0;
const NUMBER_OF_PARTNERS_ON_SMALL_SCREENS = 1;
const NUMBER_OF_PARTNERS_ON_LARGER_SCREENS = 5;
const PARTNER_SWAP_INTERVAL = 5000;
const PARTNER_MOVE_STEP = 1;

// magazine
const MAGAZINE_SITE_LINK = 'https://revistaredeas.com.br';

// competition
const NIVEIS = [...Array(5).keys()].reverse();

// campaigns
const PLACEHOLDER_IMAGE_URL = '/images/thumbPlaceholderANCR.png';

// analytics
const GA4_PROPERTY_ID = "298018452";
const GA4_MEASUREMENT_ID = "G-CHKWCH7FEL";

// session
const CHECK_VALID_SESSION_INTERVAL = 5 * 60 * 1000; // every 5 minutes

export {
  API_SERVER_ROOT_ADDRESS,
  API_DEVELOPMENT_SERVER_ROOT_ADDRESS,
  GET_INSTAGRAM_POSTS_VIA_DEV_SERVER,
  LINK_WHATSAPP,
  LINK_INSTAGRAM,
  LINK_FACEBOOK,
  NEWS_ITENS_POR_PAGINA,
  NEWS_ITENS_NA_PAGINA_INICIAL,
  NEWS_ITENS_NA_LISTA_A_DIREITA,
  NOMINACOES_NA_PAGINA_INICIAL,
  VIDEO_INSTITUCIONAL_DO_YOUTUBE,
  NUMBER_FORMAT_LOCALE,
  CURRENCY_FORMAT,
  NOME_ASSOCIACAO,
  NOME_ASSOCIACAO_CABECALHO,
  NOME_ASSOCIACAO_COM_SIGLA,
  SIGLA_ASSOCIACAO,
  HELMET_CONTEXT,
  NOMINACOES_ITENS_POR_PAGINA,
  CONTEUDO_ESTATICO_ITENS_POR_PAGINA,
  EVENT_ITENS_POR_PAGINA,
  YOUTUBE_VIDEO_ADDRESS_ROOT,
  RESET_SEARCH_START_DATE_TO,
  RESET_SEARCH_END_DATE_TO_THIS_MANY_YEARS_FROM_NOW,
  ANO_INICIO_PROGRAMA_DE_NOMINACAO,
  DEBUG_FORM_DATA,
  MAX_LENGTH_REGISTRO,
  MIN_LENGTH_REGISTRO,
  MAX_LENGTH_NOME_ANIMAL,
  MIN_LENGTH_NOME_ANIMAL,
  MAX_LENGTH_NOME_ASSOCIADO,
  MIN_LENGTH_NOME_ASSOCIADO,
  MAX_LENGTH_NOME_USUARIO,
  MIN_LENGTH_NOME_USUARIO,
  MIN_LENGTH_SENHA_USUARIO,
  MAX_LENGTH_SENHA_USUARIO,
  PERMITIR_FORMA_DE_PAGAMENTO_CARTAO_DE_CREDITO_PARA_INSCRICOES,
  PERMITIR_FORMA_DE_PAGAMENTO_PIX_PARA_INSCRICOES,
  DEFAULT_STALL_TYPE_DESCRIPTION_IF_BLANK,
  PARTNER_START_NUMBER,
  NUMBER_OF_PARTNERS_ON_SMALL_SCREENS,
  NUMBER_OF_PARTNERS_ON_LARGER_SCREENS,
  PARTNER_SWAP_INTERVAL,
  PARTNER_MOVE_STEP,
  MAGAZINE_SITE_LINK,
  NIVEIS,
  PLACEHOLDER_IMAGE_URL,
  GA4_PROPERTY_ID,
  GA4_MEASUREMENT_ID,
  CHECK_VALID_SESSION_INTERVAL,
};