import React from 'react';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import MenuIcon from '@mui/icons-material/Menu';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import MenuDrawer from "./DrawerMenu";
import { Typography, Link } from '@mui/material';
import {
  NOME_ASSOCIACAO_CABECALHO as NOME_ASSOCIACAO,
  NOME_ASSOCIACAO_COM_SIGLA,
  LINK_WHATSAPP,
  LINK_INSTAGRAM,
  LINK_FACEBOOK,
} from '../constants';
import '../styles/general.css';

export default function Header(props) {
  const { session, menus } = props;


  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('md'));
  // const md = useMediaQuery(theme.breakpoints.down('lg'));
  let navigate = useNavigate();

  const [drawer, handleDrawer] = React.useState(false);

  const changeChildState = (estado) => {
    handleDrawer(estado);
  };

  useEffect(() => {
    handleDrawer(drawer);
  }, [drawer]);


  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent={sm ? "center" : "space-between"}
        alignItems="center"

      >
        <Grid item xs={12}
          sx={{
            textAlign: 'right',
            mb: '1rem'
          }}>
          <AppBar position="static" sx={{ textAlign: 'right', background: '#b5b5b5', boxShadow: "none" }}>
            <Toolbar sx={{ minHeight: "auto  !important" }}>
              <Box sx={{
                display: {
                  xl: "none",
                  lg: "none",
                  md: "none"

                }
              }}><IconButton aria-label="Menu" color='primary' size="large" variant="outlined" onClick={() => { handleDrawer(!drawer) }}>
                  <MenuIcon fontSize="inherit" />
                </IconButton></Box>
              <Box sx={{ flexGrow: 1 }} />
              <Stack direction="row" alignItems="right" spacing={0}>

                <IconButton aria-label="Whatsapp" size="small" color='primary' onClick={() => window.open(LINK_WHATSAPP, '_blank', 'noopener')} rel="noreferrer">
                  <WhatsAppIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="Instagram" size="small" color='primary' onClick={() => window.open(LINK_INSTAGRAM, '_blank', 'noopener')} rel="noreferrer">
                  <InstagramIcon fontSize="inherit" />
                </IconButton>
                <IconButton aria-label="Facebook" size="small" color='primary' onClick={() => window.open(LINK_FACEBOOK, '_blank', 'noopener')} rel="noreferrer">
                  <FacebookIcon fontSize="inherit" />
                </IconButton>

                <Divider orientation="vertical" flexItem sx={{ mt: "0.3rem", mb: "0.3rem" }} />
                <Button
                  color="primary"
                  sx={{ fontFamily: "Foco Regular" }}
                  size='small'
                  onClick={() => navigate('/login')}
                >
                  {session ? session.nome : 'ENTRAR'}
                </Button>
                {session
                  ? null
                  : <>
                    <Divider orientation="vertical" flexItem sx={{ mt: "0.3rem", mb: "0.3rem" }} />
                    <Button
                      color="primary"
                      sx={{ fontFamily: "Foco Regular" }}
                      size='small'
                      onClick={() => navigate('/afiliar')}
                    >
                      AFILIAR
                    </Button>
                  </>
                }
              </Stack>



            </Toolbar>
          </AppBar>
        </Grid>
        <Grid item md={6} xs={3} sx={{
          mb: { xs: '0.6rem', md: '1rem' }, textAlign: "right",
        }}>
          <Link href="/">
            <Box
              component="img"
              src="/images/logotipo-ANCR-2023.svg"
              alt={NOME_ASSOCIACAO_COM_SIGLA}
              sx={{
                height: 'auto',
                maxWidth: {
                  xs: '25vw',
                  sm: '21vw',
                  md: '18vw',
                  lg: '14vw',
                  xl: '12vw',
                }
              }}
            />
          </Link>
        </Grid>
        <Grid item md={6} sx={{
          mb: { xs: '0.6rem', md: '1rem' }
        }}>
          <Typography
            sx={{
              whiteSpace: 'pre-line',
              fontWeight: '900',
              my: 0,
              ml: 1,
              lineHeight: {
                md: "1.6rem",
                xs: "0.8"
              },
              fontSize: {
                sm: '1.6rem',
              },
              textAlign: "left",
            }}
            component={"h2"}
            variant="h3"
          ><Link href="/" sx={{ ":hover": { textDecoration: "none" } }}>
              {NOME_ASSOCIACAO}
            </Link>
          </Typography>

        </Grid>

      </Grid >
      <MenuDrawer menus={menus} active={drawer} toggle={changeChildState} />
    </>
  );
}


