import React from 'react';
import {
  formatYesNo,
} from "../../functions";
import { Alert, Link } from '@mui/material';
import { CircularProgress } from '@mui/material';
import { useGetData } from "../../axiosHook/useRequestData";
import { Helmet } from 'react-helmet-async';
import BasicDataTable from '../BasicDataTable';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const MemberServices = (props) => {
  const { helmetContext, juizes } = props;

  const url = `/afiliados/${juizes ? 'juizes' : 'treinadores'}`;
  const [data = {}, loading, { error }] = useGetData(url);

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  const title = juizes ? "Juízes" : "Treinadores";

  const tableHeaders = () => {
    let headers = [
      'Nome',
      'Cidade, UF',
      "Telefone",
      "E-mail",
    ];
    juizes && headers.push('NRHA');
    // juizes && headers.push('FEI');
    return headers;
  };

  const tableRows = (tableData) => {
    if (!tableData?.["hydra:member"]) return [];

    return tableData["hydra:member"].map(rowData => {
      let row = [];
      row.push(<Link href={'/competidores/' + rowData.id} color="primary">{rowData.nome}</Link>);
      row.push(`${rowData.cidade ? rowData.cidade + ', ' : ''}${rowData.estado}`);
      row.push(rowData.celular);
      row.push(rowData.email);
      juizes && row.push(formatYesNo(rowData.juiz));
      // juizes && row.push(rowData.juizFei?.descricao);
      return row;
    });
  };

  return (
    <>
      <Helmet>
        <title>{`${title} | ${helmetContext.title}`}</title>
        <meta name="description" content={"Lista de " + title} />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />
      <BasicDataTable
        title={title}
        paper={true}
        headers={tableHeaders()}
        rows={tableRows(data)}
      />
    </>
  );
}

export default MemberServices;