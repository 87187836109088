import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useForm, FormProvider } from "react-hook-form";
import { Helmet } from "react-helmet-async";
import { FormGroup, FormHelperText } from "@mui/material";
import { DEBUG_FORM_DATA } from "../../constants";
import RHookFormAutocompleteWithGet from "../../form/RHookFormAutocompleteWithGet";
import RHookFormRadio from "../../form/RHookFormRadio";
import MostViewedHorseAndRiderTable from "./MostViewedHorseAndRiderTable";
import { RHookFormDataDebugger } from "../../form/RHookFormDataDebugger";
import ScrollToTopOnMount from "../ScrollToTopOnMount";

const searchOptions = [
  {
    value: true,
    description: "Animais",
  },
  {
    value: false,
    description: "Competidores",
  },
];

export default function EarningsSearchForm(props) {
  const { helmetContext } = props;

  let navigate = useNavigate();

  let location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);

  const animais = params.has("animais")
    ? parseInt(params.get("animais"))
    : true; // Default to horses

  const defaultValues = {
    horses: animais ? "true" : "false", // Note: Has to be a text value to work correctly with React Hook Form
    nome: "",
  };

  const methods = useForm({
    defaultValues,
    mode: "onTouched",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { isValid, isDirty, errors },
    watch,
    setValue,
    control,
  } = methods;

  const onSubmit = (data) => {
    // console.log(data);
    // alert(JSON.stringify(data));
  };

  const horses = watch("horses");
  const nome = watch("nome");

  const resetSearchField = () => {
    setValue("nome", defaultValues.nome);
  };

  const campaignUrl = () =>
    horses === "true" ? "/animais/" : "/competidores/";

  return (
    <>
      <Helmet>
        <title>{`Pesquisar Campanhas | ${helmetContext.title}`}</title>
        <meta
          name="description"
          content="Pesquisar campanhas de animais e competidores"
        />
        <meta
          name="keywords"
          content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, campanhas, pesquisar campanhas"
        />
      </Helmet>
      <ScrollToTopOnMount />

      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={5}
        sx={{
          pt: 1,
          mb: 0,
        }}
      >
        <Grid item xs={12} md={9}>
          <Typography variant="h2">
            Pesquisar Campanhas
          </Typography>

          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container direction="row" alignContent="center">
                <Grid item xs={12} md={6}>
                  <FormGroup>
                    <RHookFormRadio
                      sx={{ mt: 1 }}
                      fullWidth
                      formHelperTextErrorStyles={{
                        color: "#f44336",
                        pl: 2,
                      }}
                      id="horses"
                      name="horses"
                      label=""
                      rules={{ required: true }}
                      options={searchOptions.map((option) => {
                        return {
                          value: option.value.toString(),
                          label: option.description,
                        };
                      })}
                      helperText=" "
                      errorMessages={[
                        {
                          type: "required",
                          message: "É necessário selecionar!",
                        },
                      ]}
                      onChange={resetSearchField}
                    />

                    {horses === "true" ? (
                      <RHookFormAutocompleteWithGet
                        label="Nome"
                        name="nome"
                        optionLabel="nome"
                        required={true}
                        apiSearchUrl={"/animais/search_top?name="}
                      />
                    ) : (
                      <RHookFormAutocompleteWithGet
                        label="Nome"
                        name="nome"
                        required={true}
                        optionLabel="nome"
                        apiSearchUrl={"/competidores/search_top?name="}
                      />
                    )}
                    <FormHelperText>Digite o nome.</FormHelperText>
                  </FormGroup>

                  <div>
                    <Button
                      key="pesquisar"
                      type="button"
                      color="primary"
                      variant="contained"
                      disabled={!isValid || nome?.id === undefined}
                      sx={{ mt: 1 }}
                      onClick={() => {
                        navigate(campaignUrl() + nome?.id);
                      }}
                    >
                      {"Visualizar"}
                    </Button>
                  </div>
                </Grid>

                <Grid item xs={12} sx={{ mt: 6 }}>
                  <MostViewedHorseAndRiderTable horses={horses === "true"} />
                </Grid>
              </Grid>
              {DEBUG_FORM_DATA && (
                <RHookFormDataDebugger
                  watch={watch()}
                  errors={errors}
                  isDirty={isDirty}
                  isValid={isValid}
                  control={control}
                />
              )}
            </form>
          </FormProvider>
        </Grid>
      </Grid>
    </>
  );
}
