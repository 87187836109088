import React, { useState } from 'react'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button';
import { useForm, FormProvider } from "react-hook-form";
import {
  DEBUG_FORM_DATA,
} from "../../constants";
import { useGetData } from "../../axiosHook/useRequestData";
import { axiosInstance as axios } from "../../axiosHook/axiosInstance";
import {
  ESTADOS_DEFAULT_DATA,
  COUNTRIES_DEFAULT_DATA,
  AFFILIATE_LIST_DEFAULT_DATA,
} from '../../default-data';
import { LinearProgress } from '@mui/material';
import AlertDialog from '../AlertDialog';
import RHookFormTextField from '../../form/RHookFormTextField';
import RHookFormSelect from '../../form/RHookFormSelect';
import { RHookFormDataDebugger } from '../../form/RHookFormDataDebugger';
import {
  // isValidDateAndNotFuture,
} from '../../functions';



const keysToSend = {
  endereco: "",
  bairro: "",
  cidade: "",
  estado: "",
  cep: "",
  pais: 1,
  telefone: "",
  celular: "",
  email: "",
  representa: "",
};

const styles = {
  formHelperTextError: {
    color: "#f44336",
  },

};

const UpdateMemberForm = (props) => {
  const { defaultValues, session } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalNavigateUrlOnClose, setModalNavigateUrlOnClose] = useState("/login");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm({
    defaultValues,
    mode: "onBlur",
    reValidateMode: "onChange",
  });

  const {
    handleSubmit,
    formState: { errors, isDirty, isValid, isSubmittingRHF },
    watch,
    control,
  } = methods;

  const onSubmit = async data => {
    setIsSubmitting(true);
    // alert(JSON.stringify(data, null, 2));
    let dataToSend = {};
    Object.keys(keysToSend).forEach(key => dataToSend[key] = data[key]);
    dataToSend.pais = '/paises/' + data.pais.id; // send just the id, no need for the full object and info
    dataToSend.representa = '/campeonatos/' + data.representa.id; // send just the id, no need for the full object and info

    axios
      .patch(
        `/afiliados/${session.id}`,
        dataToSend,
        {
          headers: {
            'Authorization': `Bearer ${session.token}`,
            'Content-Type': 'application/merge-patch+json',
          },
        })
      .then((response) => {
        if (200 === response.status) {
          setModalTitle("Cadastro atualizado com sucesso");
          setModalMessage("Você receberá um email de confirmação.");
          setModalNavigateUrlOnClose("/login");
          setModalOpen(true);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log("error", error);
        setModalTitle("Error");
        setModalMessage({
          type: "error",
          message: error.response?.data?.detail ?? error.message,
        });
        setModalNavigateUrlOnClose("");
        setModalOpen(true);
      })
      .finally(() => setIsSubmitting(false));
  };

  const [estados = ESTADOS_DEFAULT_DATA] = useGetData('/estados');
  const [paises = COUNTRIES_DEFAULT_DATA] = useGetData('/paises');
  const [campeonatos = AFFILIATE_LIST_DEFAULT_DATA] = useGetData('/campeonatos');

  return <>

    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <RHookFormTextField
          name="endereco"
          label="Endereço"
          sx={{ mt: 1 }}
          fullWidth
          inputProps={{ maxLength: 100 }}
          rules={{ required: true, minLength: 3, pattern: /^[\s0-9A-Za-z\u00C0-\u017F,./-]+$/i }}
          errorMessages={
            [{
              type: "required",
              message: "É necessário informar seu endereço!",
            }, {
              type: "minLength",
              message: "Favor informar seu endereço completo!",
            }, {
              type: "pattern",
              message: "O endereço não pode incluir characteres especiais!",
            }]}
        />

        <RHookFormTextField
          name="bairro"
          label="Bairro"
          sx={{ mt: 1 }}
          fullWidth
          inputProps={{ maxLength: 50 }}
          rules={{ required: false, pattern: /^[\s0-9A-Za-z\u00C0-\u017F,.-]+$/i }}
          errorMessages={
            [{
              type: "pattern",
              message: "O endereço não pode incluir characteres especiais!",
            }]}
        />

        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <RHookFormTextField
              name="cidade"
              label="Cidade"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 50 }}
              rules={{ required: true, minLength: 3, pattern: /^[\s0-9A-Za-z\u00C0-\u017F,.-]+$/i }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar a cidade!",
                }, {
                  type: "minLength",
                  message: "Favor informar o nome completo da cidade!",
                }, {
                  type: "pattern",
                  message: "O nome da cidade não pode incluir characteres especiais!",
                }]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHookFormSelect
              sx={{ mt: 1 }}
              fullWidth
              formHelperTextErrorStyles={styles.formHelperTextError}
              name="estado"
              label="Estado"
              rules={{ required: true }}
              options={estados["hydra:member"]?.map(estado => {
                return {
                  value: estado.uf,
                  label: `${estado.uf} - ${estado.nome}`,
                };
              })}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário selecionar um estado!",
                }]}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <RHookFormTextField
              name="cep"
              label="CEP"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 9 }}
              rules={{ required: true, minLength: 3, pattern: /^[\s0-9A-Za-z\u00C0-\u017F.-]+$/i }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar o CEP!",
                }, {
                  type: "minLength",
                  message: "Favor informar o CEP completo!",
                }, {
                  type: "pattern",
                  message: "O CEP não pode incluir characteres especiais!",
                }]}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHookFormSelect
              sx={{ mt: 1 }}
              fullWidth
              formHelperTextErrorStyles={styles.formHelperTextError}
              name="pais.id"
              label="País"
              rules={{ required: true }}
              options={paises["hydra:member"]?.map(pais => {
                return {
                  value: pais.id,
                  label: pais.nome,
                };
              })}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário selecionar um país!",
                }]}
            />
          </Grid>
        </Grid>

        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={6}>
            <RHookFormTextField
              name="telefone"
              label="Telefone"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 50 }}
              rules={{ required: false, pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/ }}
              errorMessages={
                [{
                  type: "pattern",
                  message: "Favor, digite seu telefone no formato (12) 98765-4321!",
                }]}
              helperText=" "
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <RHookFormTextField
              name="celular"
              label="Celular"
              sx={{ mt: 1 }}
              fullWidth
              inputProps={{ maxLength: 50 }}
              rules={{ required: true, minLength: 3, pattern: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/ }}
              errorMessages={
                [{
                  type: "required",
                  message: "É necessário informar um celular válido!",
                }, {
                  type: "minLength",
                  message: "Favor informar um celular válido!",
                }, {
                  type: "pattern",
                  message: "Favor, digite seu celular no formato (12) 98765-4321!",
                }]}
              helperText=" "
            />
          </Grid>
        </Grid>

        <RHookFormTextField
          name="email"
          label="Email"
          sx={{ mt: 1 }}
          fullWidth
          inputProps={{ maxLength: 80 }}
          rules={{ required: true, minLength: 3, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
          errorMessages={
            [{
              type: "required",
              message: "É necessário informar um email válido!",
            }, {
              type: "minLength",
              message: "É necessário informar um email válido!",
            }, {
              type: "pattern",
              message: "É necessário informar um email válido!",
            }]}
        />

        <RHookFormSelect
          sx={{ mt: 1 }}
          fullWidth
          formHelperTextErrorStyles={styles.formHelperTextError}
          name="representa.id"
          label="Representa"
          rules={{ required: true }}
          options={campeonatos["hydra:member"]?.map(opcao => {
            return {
              value: opcao.id,
              label: opcao.nucleo,
            };
          })}
          errorMessages={
            [{
              type: "required",
              message: "É necessário selecionar um núcleo!",
            }]}
        />

        <LinearProgress sx={{
          pt: 1,
          opacity: isSubmittingRHF ? "1" : "0",
        }} />

        <Button variant="contained" color="secondary" sx={{ px: 2 }} type="submit" disabled={isSubmitting || !isDirty}>Atualizar</Button>

      </form>
      {DEBUG_FORM_DATA && <RHookFormDataDebugger watch={watch()} errors={errors} isValid={isValid} isDirty={isDirty} control={control} />}
    </FormProvider>


    <AlertDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} message={modalMessage} navigateUrlOnClose={modalNavigateUrlOnClose} />
  </>;

};

export default UpdateMemberForm;