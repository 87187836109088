import { React, useState } from 'react';
import { Helmet } from "react-helmet-async";
import { useGetData } from "../../axiosHook/useRequestData";
import {
  Grid,
  Typography,
  CircularProgress,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  SvgIcon,
} from '@mui/material';
import {
  // PATTERN_TEST_DATA,
} from "../../test-data";
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import PatternCard from './PatternCard';
import { US, BR } from 'country-flag-icons/react/3x2'

export default function PatternList(props) {
  const { helmetContext } = props;

  // const data = PATTERN_TEST_DATA;
  const [language, setLanguage] = useState('pt-BR');
  const [data = {}, loading, { error }] = useGetData('/percursos/percursos_com_manobras');
  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  const title = "Percursos";

  return (
    <>
      <Helmet>
        <title>{`${title} | ${helmetContext.title}`}</title>
        <meta name="description" content={title} />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />

      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
      >
        <Grid item key="main" xs={12}><Typography variant="h2">{title}</Typography>
          <ToggleButtonGroup
            exclusive
            value={language}
            title='Idioma'
          >
            <ToggleButton value='pt-BR' onClick={() => { setLanguage('pt-BR') }} children={<SvgIcon><BR title="Brasil" /></SvgIcon>} />
            <ToggleButton value='en-US' onClick={() => { setLanguage('en-US') }} children={<SvgIcon><US title="United States" /></SvgIcon>} />
          </ToggleButtonGroup>
        </Grid>

        {data["hydra:member"]?.length > 0
          ? <>

            {data["hydra:member"].map(pattern =>

              <Grid key={pattern.id} item xs={12} md={4}>
                <PatternCard pattern={pattern} language={language} />
              </Grid>

            )}

          </>
          : <CircularProgress />
        }

      </Grid>
    </>
  );
}