import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Stack,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useGetData } from "../../axiosHook/useRequestData";
import { formatMoney } from "../../functions";
import {
  // INSCRICOES_TEST_DATA,
  // INSCRICOES_TEST_DATA2,
  INSCRICOES_TEST_DATA3,
  EXTRA_STALLS_TEST_DATA,
} from "../../test-data";
import PaymentForm from "./PaymentForm";
import AlertDialog from "../AlertDialog";

const cellStyles = {
  py: 1,
  px: {
    xs: 0.3,
    sm: 0.5,
    md: 1,
  },
  fontSize: {
    xs: "0.8rem",
    sm: "0.9rem",
    md: "1.0em",
  },
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: 16,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const EntryList = (props) => {
  const { session, save, clear } = props;

  let navigate = useNavigate();

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalClearSessionOnClose, setModalClearSessionOnClose] = useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    // debugging:
    if (process.env.NODE_ENV !== "production") {
      if (session) {
        save({
          ...session,
          inscricoes: session.inscricoes ? session.inscricoes : INSCRICOES_TEST_DATA3,
          baiasExtras: session.baiasExtras ? session.baiasExtras : EXTRA_STALLS_TEST_DATA
        });
      }
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [valoresInscricoes = {}] = useGetData("/eventos/valores");

  if (!session) return <Button variant="contained" color="secondary" sx={{ mt: 4, px: 2 }} type="button" onClick={() => navigate('/login')}>Efetuar login</Button>

  const inscricoes = session?.inscricoes?.map((inscricao) => {
    return {
      ...inscricao,
      valor: valoresInscricoes["hydra:member"]
        ?.find((valores) => valores.evento.id.toString() === inscricao.evento)
        ?.valoresInscricoes?.find(
          (valor) => valor.categoria.id.toString() === inscricao.categoria
        )?.valor,
    };
  });

  let baias = session?.inscricoes?.reduce(
    (previous, current) =>
      previous.filter(animal => animal?.id === current.animal.id).length
        ? previous
        : [...previous, current.animal],
    []
  );
  // add stall type and cost
  baias = baias?.map((animal) => {
    return {
      ...animal,
      categoriaBaia: session?.inscricoes?.filter(
        (inscricao) =>
          inscricao.animal.id === animal.id && inscricao.baia !== "0"
      )[0]?.categoriasBaias[0]?.categoriaBaia,
      evento: session?.inscricoes?.filter(
        (inscricao) =>
          inscricao.animal.id === animal.id && inscricao.baia !== "0"
      )[0]?.evento,
      valor: valoresInscricoes["hydra:member"]
        ?.find(
          (valores) =>
            valores.evento.id.toString() ===
            session?.inscricoes?.filter(
              (inscricao) =>
                inscricao.animal.id === animal.id && inscricao.baia !== "0"
            )[0]?.evento
        )
        ?.valoresBaias?.find(
          (valor) =>
            valor.categoriaBaia.id ===
            session?.inscricoes?.filter(
              (inscricao) =>
                inscricao.animal.id === animal.id && inscricao.baia !== "0"
            )[0]?.categoriasBaias[0]?.categoriaBaia?.id
        )?.valor,
    };
  });

  const baiasExtras = session?.baiasExtras?.map(baiaExtra => {
    return {
      ...baiaExtra,
      categoriasBaias: undefined, // remove unnecessary data
      categoriaBaia: baiaExtra.categoriasBaias?.find(
        (x) =>
          x.categoriaBaia.id.toString() === baiaExtra.baia
      )?.categoriaBaia,
      valor: valoresInscricoes["hydra:member"]
        ?.find(
          (valores) =>
            valores.evento.id.toString() === baiaExtra.evento
        )
        ?.valoresBaias?.find(
          (valor) =>
            valor.categoriaBaia.id.toString() === baiaExtra.baia
        )?.valorBaiaExtra,
    }
  });

  const handleClear = () => {
    save({ ...session, inscricoes: [] });
  };

  const INSCICAO = 1;
  const BAIA_DE_COMPETICAO = 2;
  const BAIA_EXTRA = 3;

  const deleteRow = (itemToDelete, index) => {
    if (itemToDelete === BAIA_EXTRA) {
      session.baiasExtras.splice(index, 1);
      save({ ...session, baiasExtras: session.baiasExtras });
      return;
    }

    if (itemToDelete === BAIA_DE_COMPETICAO) {
      session.inscricoes.find(
        (inscricao) =>
          inscricao.animal.id === baias[index].id && inscricao.baia !== "0"
      ).baia = "0";
      save({ ...session, inscricoes: session.inscricoes });
      return;
    }

    session.inscricoes.splice(index, 1);
    save({ ...session, inscricoes: session.inscricoes });
  };

  const addStall = (index) => {
    // add a stall of the first available type from that entry
    session.inscricoes.find(
      (inscricao) =>
        inscricao.animal.id === baias[index].id && inscricao.baia === "0"
    ).baia = session.inscricoes
      .find(
        (inscricao) =>
          inscricao.animal.id === baias[index].id && inscricao.baia === "0"
      )
      ?.categoriasBaias[0]?.categoriaBaia.id.toString()
        ? session.inscricoes
          .find(
            (inscricao) =>
              inscricao.animal.id === baias[index].id && inscricao.baia === "0"
          )
          ?.categoriasBaias[0]?.categoriaBaia.id.toString()
        : "0";
    save({ ...session, inscricoes: session.inscricoes });
  };

  const totalInscricoes = inscricoes?.reduce(
    (sum, inscricao) =>
      (sum += inscricao.valor ? parseFloat(inscricao.valor) : 0),
    0
  ) ?? 0;

  const totalBaias = baias?.reduce(
    (sum, baia) => (sum += baia.valor ? parseFloat(baia.valor) : 0),
    0
  ) ?? 0;

  const totalBaiasExtras = baiasExtras?.reduce(
    (sum, baia) => (sum += baia.valor ? parseFloat(baia.valor) : 0),
    0
  ) ?? 0;

  const total = totalInscricoes + totalBaias + totalBaiasExtras;

  const calcularMaxParcelas = () => {
    // Go through the entries to be made and check if the entry fees for each include installments 
    // Note: This is done only by event at the moment and not by class
    const maxParcelasPelasInscricoesAEfetuar = session?.inscricoes?.reduce((min, inscricao) => {
      const maxParcelasInscricao = valoresInscricoes["hydra:member"]
        ?.find((valores) => valores.evento.id.toString() === inscricao.evento)
        ?.maxParcelas;
      return maxParcelasInscricao < min ? maxParcelasInscricao : min;
    }, 99);

    if (maxParcelasPelasInscricoesAEfetuar < 99) return maxParcelasPelasInscricoesAEfetuar;

    // If we didn't get any info on installments from the entry fees, then just get the lowest number for all events 
    // that have entries open at the moment
    return valoresInscricoes["hydra:member"]?.reduce(
      (min, evento) => (evento.maxParcelas < min ? evento.maxParcelas : min),
      99
    ) ?? 1;
  };

  const maxParcelas = calcularMaxParcelas();

  // debugging:
  if (process.env.NODE_ENV !== "production") {
    console.log("inscricoes", inscricoes);
    console.log("baias", baias);
    console.log("baiasExtras", baiasExtras);
    console.log("valoresInscricoes", valoresInscricoes);
    console.log("maxParcelas", maxParcelas);
  }

  const colunasIncricoes = isMobile ? 4 : 6;
  const colunasBaias = isMobile ? 4 : 6;
  const colunasNomeBaia = isMobile ? 1 : 3;

  const StallList = ({ listOfStalls, listIsOfExtraStalls }) => {
    return (
      <>
        {
          listOfStalls?.length ? (
            <StyledTableRow key={listIsOfExtraStalls ? "ExtraStallTtile" : "StallTitle"}>
              <TableCell align="center" colSpan={colunasBaias}>
                <strong>{listIsOfExtraStalls ? "Baias Extras" : "Baias de Competição"}</strong>
              </TableCell>
            </StyledTableRow >
          ) : null}
        {
          listOfStalls?.map((row, index) => (
            <StyledTableRow key={listIsOfExtraStalls ? `ExtraStall${index}` : `Stall${row.id}`}>
              <StyledTableCell align="center" sx={cellStyles}>
                {index + 1 + (
                  session?.inscricoes
                    ? session?.inscricoes?.length
                    : 0
                ) + (
                    listIsOfExtraStalls
                      ? baias
                        ? baias.length
                        : 0
                      : 0)}
              </StyledTableCell>
              <StyledTableCell
                align="left"
                sx={cellStyles}
                colSpan={colunasNomeBaia}
              >
                {listIsOfExtraStalls ?
                  row.apoio === "true" ? "Apoio" : row.nomeAnimalPasseio
                  : row.nome
                }
                <br />
                {row.categoriaBaia
                  ? `Baia ${row.categoriaBaia.descricao}`
                  : "Sem baia"}
              </StyledTableCell>
              <StyledTableCell align="right" sx={cellStyles}>
                {valoresInscricoes ? (
                  row.valor ? (
                    formatMoney(row.valor)
                  ) : (
                    <span>-</span>
                  )
                ) : (
                  <CircularProgress size={16} />
                )}
              </StyledTableCell>
              <StyledTableCell align="center" sx={{ p: 0 }}>
                {row.categoriaBaia ? (
                  <IconButton onClick={() => deleteRow(listIsOfExtraStalls ? BAIA_EXTRA : BAIA_DE_COMPETICAO, index)}>
                    <Delete />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => addStall(index)}>
                    <AddCircleIcon />
                  </IconButton>
                )}
              </StyledTableCell>
            </StyledTableRow>
          ))
        }
      </>
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="spanning table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell
                align="center"
                colSpan={colunasIncricoes}
                sx={cellStyles}
              >
                Inscrições
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow>
              <StyledTableCell align="center" sx={cellStyles}>
                No.
              </StyledTableCell>
              <StyledTableCell align="center" sx={cellStyles}>
                Animal
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={(cellStyles, { display: { xs: "none", md: "table-cell" } })}
              >
                Categoria
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={(cellStyles, { display: { xs: "none", md: "table-cell" } })}
              >
                Competidor
              </StyledTableCell>
              <StyledTableCell align="center" sx={cellStyles}>
                Valor
              </StyledTableCell>
              <StyledTableCell align="center" sx={cellStyles}></StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {inscricoes?.length ? (
              inscricoes.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell align="center" sx={cellStyles}>
                    {index + 1}
                  </StyledTableCell>
                  <StyledTableCell align="left" sx={cellStyles}>
                    {row.animal.nome}
                    {isMobile ? (
                      <>
                        <br />
                        <span>Categoria:</span>
                        {row.dadosCategoria.categoria.nome}
                      </>
                    ) : (
                      ""
                    )}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={
                      (cellStyles,
                        { display: { xs: "none", md: "table-cell" } })
                    }
                  >
                    {row.dadosCategoria.categoria.nome}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={
                      (cellStyles,
                        { display: { xs: "none", md: "table-cell" } })
                    }
                  >
                    {row.competidor.nome}
                  </StyledTableCell>
                  <StyledTableCell align="right" sx={cellStyles}>
                    {valoresInscricoes ? (
                      row.valor ? (
                        formatMoney(row.valor)
                      ) : (
                        <span>-</span>
                      )
                    ) : (
                      <CircularProgress size={16} />
                    )}
                  </StyledTableCell>
                  <StyledTableCell align="center" sx={{ p: 0 }}>
                    <IconButton onClick={() => deleteRow(INSCICAO, index)}>
                      <Delete />
                    </IconButton>
                  </StyledTableCell>
                </StyledTableRow>
              ))
            ) : (
              <StyledTableRow>
                <StyledTableCell align="center" sx={cellStyles} colSpan={6}>
                  Adicione uma inscrição
                </StyledTableCell>
              </StyledTableRow>
            )}

            <StallList listOfStalls={baias} listIsOfExtraStalls={false} />
            <StallList listOfStalls={baiasExtras} listIsOfExtraStalls={true} />

            <StyledTableRow>
              <StyledTableCell
                colSpan={colunasBaias - 2}
                sx={cellStyles}
                align="right"
              >
                Total
              </StyledTableCell>
              <StyledTableCell align="right" sx={cellStyles}>
                {formatMoney(total)}
              </StyledTableCell>
              <StyledTableCell align="center" sx={cellStyles}></StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>

      {total ? (
        <PaymentForm maxParcelas={maxParcelas} session={session} save={save} setModalOpen={setModalOpen} setModalTitle={setModalTitle} setModalMessage={setModalMessage} setModalClearSessionOnClose={setModalClearSessionOnClose} />
      ) : null}

      <Stack
        direction={{ xs: "column", md: "row" }}
        justifyContent="space-around"
        divider={<Divider sx={{ display: { xs: 'none', sm: 'block' } }} orientation="vertical" flexItem />}
        spacing={2}
        sx={{ mt: 2 }}
      >
        <Button
          variant="outlined"
          onClick={handleClear}
          startIcon={<Delete />}
          disabled={!inscricoes?.length}
        >
          Limpar
        </Button>
        <Button
          variant="outlined"
          href="/inscricoes"
          startIcon={<AddCircleIcon />}
        >
          Adicionar Inscrição
        </Button>
        <Button
          variant="outlined"
          href="/baiaextra"
          startIcon={<AddCircleIcon />}
        >
          Adicionar Baia Extra
        </Button>
      </Stack>
      <AlertDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} message={modalMessage} clearSessionOnClose={modalClearSessionOnClose} session={session} clear={clear} />
    </>
  );
};

export default EntryList;
