import React from 'react'
import Grid from '@mui/material/Grid'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  // Pagination,
  // Stack,
  // Box,
} from '@mui/material';
import Typography from '@mui/material/Typography'
import { Helmet } from "react-helmet-async";
import ScrollToTopOnMount from '../ScrollToTopOnMount';
// import Capa17 from "../../images/capaRevista.jpg";
import {
  CAPAS_REVISTAS_DEFAULT_DATA,
} from '../../default-data';

const MagazineList = (props) => {
  const { helmetContext } = props;

  return (
    <>
      <Helmet>
        <title>{`Revista Rédeas | ${helmetContext.title}`}</title>
        <meta name="description" content="Revista Rédeas" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, contato, fale com, telefone, fale com a gente" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={3}
        sx={{ mt: 1, mb: 0, }}
      >

        <Grid item xs={12}>
          <Typography component='h1' variant='h1'>Revista Rédeas</Typography>
          <Typography component='p'>
            A revista oficial da modalidade de Rédeas.
          </Typography>


        </Grid>

        {CAPAS_REVISTAS_DEFAULT_DATA.map(revista =>
          <Grid key={revista.edicao} item xs={6} md={3}>
            <Card>
              <CardActionArea onClick={() => revista.url ? window.open(revista.url, "_blank", 'noopener') : null} disabled={!revista.url}>
                <CardMedia
                  component="img"
                  sx={{
                    height: 'auto',
                    width: '100%',
                    margin: "0 auto"
                  }}
                  alt={`Capa ${revista.edicao}ª Edição`}
                  src={revista.imagemUrl}
                />
                <CardContent>
                  <Typography component='p' align='center'>
                    {`${revista.edicao}ª Edição`}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )}

        {/* <Grid item xs={'12'}>
        <Stack spacing={2} alignItems='center'><Pagination count={1} shape="rounded" /></Stack>
      </Grid> */}
      </Grid>


    </>
  );

}

export default MagazineList;