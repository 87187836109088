import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
  // HORSE_BASIC_CAMPAIGN_TEST_DATA,
  // HORSE_CAMPAIGN_TEST_DATA,
  // HORSE_AFFILIATE_CAMPAIGN_TEST_DATA,
  // HORSE_VIDEO_CAMPAIGN_TEST_DATA,
} from "../../test-data";
import { axiosInstance as axios } from '../../axiosHook/axiosInstance';
import {
  YOUTUBE_VIDEO_ADDRESS_ROOT,
} from '../../constants';
import {
  formatMoney,
  urlImagemCampanha,
} from "../../functions";
import { CircularProgress, IconButton, Link } from '@mui/material';
import moment from 'moment';
import BasicDataTable from '../BasicDataTable';
import { Alert } from '@mui/material';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import VideoDialog from '../VideoDialog';
import { Box } from '@mui/material';
import { Helmet } from 'react-helmet-async';
import ScrollToTopOnMount from '../ScrollToTopOnMount';

const loadingDefaultState = {
  baseProfileData: true,
  pointConversionData: true,
  openCampaignData: true,
  amateurCampaignData: true,
  openAffiliateCampaignData: true,
  amateurAffiliateCampaignData: true,
  videoCampaignData: true,
  offspringEarningsCampaignData: true,
}

export default function Campaign(props) {
  const { helmetContext, horse } = props;
  const params = useParams()
  const id = params.id;

  const [loading, setLoading] = useState(loadingDefaultState);
  const [baseProfileData, setBaseProfileData] = useState({});
  const [pointConversionData, setPointConversionData] = useState({});
  const [openCampaignData, setOpenCampaignData] = useState(null);
  const [amateurCampaignData, setAmateurCampaignData] = useState(null);
  const [openAffiliateCampaignData, setOpenAffiliateCampaignData] = useState(null);
  const [amateurAffiliateCampaignData, setAmateurAffiliateCampaignData] = useState(null);
  const [videoCampaignData, setVideoCampaignData] = useState(null);
  const [offspringEarningsCampaignData, setOffspringEarningsCampaignData] = useState(null);

  const getData = useCallback(
    ({ url, set, setLoadingKey }) => {
      axios
        .get(url)
        .then((response) => {
          const data = response.data;
          set(data);
        })
        .catch((error) => {
          set({
            "error": error,
          });
          console.log("error", error);
        })
        .finally(() => setLoading(loading => {
          return {
            ...loading,
            [setLoadingKey]: false
          }
        }));
    },
    [],
  );

  const saveAccessRecord = useCallback(
    (horse, id) => {
      axios
        .post(`/acessocampanha?${horse ? 'idAnimal=' : 'idAfiliado='}${id}`)
        .then((response) => {
          const data = response.data;
          if (!data.id) {
            console.log(data);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    },
    [],
  );

  useEffect(() => {
    setLoading(loadingDefaultState);
    getData({
      url: `/${horse ? 'animais' : 'afiliados'}/${id}`,
      set: setBaseProfileData,
      setLoadingKey: 'baseProfileData',
    });
    getData({
      url: `/conversoes_pontos`,
      set: setPointConversionData,
      setLoadingKey: 'pointConversionData',
    });
    getData({
      url: `/${horse ? 'animais/campanha?idAnimal=' : 'afiliados/campanha?idAfiliado='}${id}&aberta=true`,
      set: setOpenCampaignData,
      setLoadingKey: 'openCampaignData',
    });
    getData({
      url: `/${horse ? 'animais/campanha?idAnimal=' : 'afiliados/campanha?idAfiliado='}${id}&aberta=false`,
      set: setAmateurCampaignData,
      setLoadingKey: 'amateurCampaignData',
    });
    getData({
      url: `/${horse ? 'animais/campanha_campeonato?idAnimal=' : 'afiliados/campanha_campeonato?idAfiliado='}${id}&aberta=true`,
      set: setOpenAffiliateCampaignData,
      setLoadingKey: 'openAffiliateCampaignData',
    });
    getData({
      url: `/${horse ? 'animais/campanha_campeonato?idAnimal=' : 'afiliados/campanha_campeonato?idAfiliado='}${id}&aberta=false`,
      set: setAmateurAffiliateCampaignData,
      setLoadingKey: 'amateurAffiliateCampaignData',
    });
    getData({
      url: `/${horse ? 'animais/campanha_video?idAnimal=' : 'afiliados/campanha_video?idAfiliado='}${id}`,
      set: setVideoCampaignData,
      setLoadingKey: 'videoCampaignData',
    });
    saveAccessRecord(horse, id);
  }, [getData, saveAccessRecord, horse, id]);

  useEffect(() => {
    if (horse && baseProfileData) {
      getData({
        url: `/animais/producao?nomePaiOuMae=${baseProfileData.nome}`,
        set: setOffspringEarningsCampaignData,
        setLoadingKey: 'offspringEarningsCampaignData',
      });
    } else {
      setOffspringEarningsCampaignData({});
      setLoading(loading => {
        return {
          ...loading,
          offspringEarningsCampaignData: false
        }
      })
    }
  }, [getData, horse, baseProfileData]);

  // basic competitor or horse data
  const nome = baseProfileData ? baseProfileData.nome : `Campanha ${horse ? "animal" : "competidor"}`;

  let baseProfileTableRows = [];
  baseProfileData.nascimento && baseProfileTableRows.push(['Nascimento', moment(baseProfileData.nascimento).format("DD/MM/YYYY")],);
  baseProfileData.sexo?.descricao && baseProfileTableRows.push(['Sexo', baseProfileData.sexo.descricao],);
  baseProfileData.filiacao && baseProfileTableRows.push(['Filiação', baseProfileData.filiacao],);
  baseProfileData.pelagem && baseProfileTableRows.push(['Pelagem', baseProfileData.pelagem],);
  baseProfileData.raca?.descricao && baseProfileTableRows.push(['Raça', baseProfileData.raca.descricao],);
  baseProfileData.webRegistro && baseProfileTableRows.push(['Registro', baseProfileData.webRegistro
    ? <a href={baseProfileData.webRegistro} target="_blank" rel="noreferrer" title="Clique aqui para ver o registro">{baseProfileData.registro}</a>
    : baseProfileData.registro]);
  baseProfileData.cidade && baseProfileTableRows.push(['Cidade, UF', `${baseProfileData.cidade}${baseProfileData.estado ? ', ' + baseProfileData.estado : ''}`],);

  // format main campaign data for table
  const mainCampaignTableHeaders = [
    'Evento',
    'Categoria',
    horse ? 'Colocação' : 'Animal',
    'Ganhos',
  ];

  const mainCampaignTableRows = (mainCampaignData, open) => {
    if (!mainCampaignData || !Object.keys(baseProfileData).length) return [];

    let rows = [];
    if ((open && parseFloat(baseProfileData.pontuacaoHistoricaCompetidorAberta) && Array.isArray(pointConversionData["hydra:member"]))) {
      rows.push([
        "Ranking Acumulado 2005/2006",
        "Aberta",
        "",
        formatMoney(baseProfileData.pontuacaoHistoricaCompetidorAberta * parseFloat(pointConversionData["hydra:member"][0]?.valorAberta))
      ])
    }

    if ((!open && parseFloat(baseProfileData.pontuacaoHistoricaCompetidorAmador) && Array.isArray(pointConversionData["hydra:member"]))) {
      rows.push([
        "Ranking Acumulado 2005/2006",
        "Amador",
        "",
        formatMoney(baseProfileData.pontuacaoHistoricaCompetidorAmador * parseFloat(pointConversionData["hydra:member"][0]?.valorAmador))
      ])
    }

    mainCampaignData["hydra:member"].forEach(data => {
      let eventLink = `/eventos/${data.idEvento}/resultados/${data.idCategoria}`;
      if (data.idClassificatoria !== undefined && data.idClassificatoria !== null) {
        eventLink += '#IDClassificatoria' + data.idClassificatoria;
        if (data.idSubCategoria !== undefined && data.idSubCategoria !== null && data.final) {
          eventLink += 'IDSubCategoria' + data.idSubCategoria;
        }
      } else {
        if (data.idSubCategoria !== undefined && data.idSubCategoria !== null && (data.final || data.idClassificatoria === undefined || data.idClassificatoria === null)) {
          eventLink += '#IDSubCategoria' + data.idSubCategoria;
        }
      }

      let row = [];
      row.push(data.eventoCadastradoSomenteParaIncluirGanhos ? data.evento : <Link href={eventLink} color="primary">{data.evento}</Link>);
      row.push(data.categoria);
      if (horse) {
        let placing = "";
        if (data.colocacao) {
          placing = data.colocacao + 'º';
          if (data.idSubCategoria) {
            placing += " Nível " + data.subCategoria;
            if (data.idClassificatoria !== undefined && data.idClassificatoria !== null && data.desempate === false && data.final === false) {
              placing += " (Cl.)";
            }
          }
        }
        row.push(placing);
      } else {
        row.push(data.aConfirmar
          ? data.animal
          : <Link href={`/animais/${data.idAnimal}`} color="primary">{data.animal}</Link>
        );
      }
      row.push(formatMoney(data.premiacao));
      rows.push(row);
    });
    return rows;
  };

  const mainCampaignTableFooter = ({ mainCampaignData, oldPoints, pointConversion }) => {
    if (!mainCampaignData || !Object.keys(baseProfileData).length) return [];

    let total = 0;
    if (oldPoints && pointConversion) {
      total += parseFloat(oldPoints) * parseFloat(pointConversion);
    }

    mainCampaignData["hydra:member"].forEach(data => {
      total += data.premiacao;
    });

    if (total === 0) return [];

    return [
      "Total",
      "",
      "",
      formatMoney(total),
    ];
  };

  // format affiliate campaign data for table
  const affiliateCampaignTableHeaders = [
    'Temporada',
    'Campeonato',
    'Categoria',
    'Nível',
    'Col.',
    horse ? 'Competidor' : 'Animal',
    'Ganhos',
  ];

  const affiliateCampaignTableRows = (affiliateCampaignData) => {
    if (!affiliateCampaignData?.["hydra:member"]) return [];

    return affiliateCampaignData["hydra:member"].map(data => {
      let row = [];
      row.push(`${data.temporada}/${data.temporada + 1}`);
      row.push(data.campeonato);
      row.push(data.categoria);
      row.push(data.subCategoria);
      row.push(data.colocacao + 'º');
      row.push(horse
        ? <Link href={'/competidores/' + data.idCompetidor} color="primary">{data.competidor}</Link>
        : data.aConfirmar
          ? data.animal
          : <Link href={'/animais/' + data.idAnimal} color="primary">{data.animal}</Link>
      );
      row.push(formatMoney(data.premiacao));
      return row;
    });
  };

  const affiliateCampaignTableFooter = (affiliateCampaignData) => {
    if (!affiliateCampaignData?.["hydra:member"]) return [];

    let total = 0;
    affiliateCampaignData["hydra:member"].forEach(data => {
      total += data.premiacao;
    });

    if (total === 0) return [];

    return [
      "Total",
      "",
      "",
      "",
      "",
      "",
      formatMoney(total),
    ];
  };

  // format video campaign data for table
  const videoCampaignTableHeaders = [
    'Evento',
    'Categoria',
    horse ? 'Competidor' : 'Animal',
    '',
  ];

  const [modalOpen, setModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalVideoLink, setModalVideoLink] = useState("");

  const mostrarVideo = (title, videoLink) => {
    setModalTitle(title);
    setModalVideoLink(videoLink);
    setModalOpen(true);
  };

  const videoCampaignTableRows = (videoCampaignData) => {
    if (!videoCampaignData?.["hydra:member"]) return [];

    return videoCampaignData["hydra:member"].map(data => {
      let eventLink = `/eventos/${data.idEvento}/resultados/${data.idCategoria}`;
      if (data.idClassificatoria !== undefined && data.idClassificatoria !== null) {
        eventLink += '#IDClassificatoria' + data.idClassificatoria;
      }

      let row = [];
      row.push(data.eventoCadastradoSomenteParaIncluirGanhos ? data.evento : <Link href={eventLink} color="primary">{data.evento}</Link>);
      row.push(data.eventoCadastradoSomenteParaIncluirGanhos ? data.evento : <Link href={eventLink} color="primary">{`${data.categoria}${data.idClassificatoria !== null ? ' - ' + data.classificatoria : ""}`}</Link>);
      row.push(horse
        ? <Link href={'/competidores/' + data.idCompetidor} color="primary">{data.competidor}</Link>
        : <Link href={'/animais/' + data.idAnimal} color="primary">{data.animal}</Link>
      );
      row.push(<IconButton key={data.idYouTube} size="small" onClick={() => mostrarVideo(data.animal, YOUTUBE_VIDEO_ADDRESS_ROOT + data.idYouTube)}>
        <PlayCircleOutlineIcon sx={{ fontSize: { xs: '0.9em', sm: '1.0em', md: '1.2em' } }} />
      </IconButton>);
      return row;
    });
  };

  // format offspring earnings campaign data for table
  const offspringEarningsCampaignTableHeaders = [
    'Nome',
    'Sexo',
    'Idade',
    'Ganhos',
  ];

  const offspringEarningsCampaignTableRows = (offspringEarningsCampaignData) => {
    if (!offspringEarningsCampaignData?.["hydra:member"]) return [];

    return offspringEarningsCampaignData["hydra:member"].map(data => {
      let row = [];
      row.push(<Link href={'/animais/' + data.idAnimal} color="primary">{data.animal}</Link>);
      row.push(data.sexo);
      row.push(data.idadeAnimal);
      row.push(formatMoney(data.premiacao));
      return row;
    });
  };

  const offspringEarningsCampaignTableFooter = (offspringEarningsCampaignData) => {
    if (!offspringEarningsCampaignData?.["hydra:member"]) return [];

    let total = 0;
    offspringEarningsCampaignData["hydra:member"].forEach(data => {
      total += data.premiacao;
    });

    if (total === 0) return [];

    return [
      "Total",
      "",
      "",
      formatMoney(total),
    ];
  };

  return (
    <>
      <Helmet>
        <title>{`${nome ? nome + ' | ' + helmetContext.shortTitle : helmetContext.title}`}</title>
        <meta name="description" content="Confira a campanha" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, notícias, informativos, informações" />
      </Helmet>
      <ScrollToTopOnMount />
      {loading.baseProfileData
        ? <CircularProgress />
        : baseProfileData.error
          ? <Alert severity="error">{`${baseProfileData.error}`}</Alert>
          : <>
            <Typography variant="h2" align="center">{nome}</Typography>
            {urlImagemCampanha(baseProfileData) && <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}><Box component="img" alt={nome} src={urlImagemCampanha(baseProfileData)} sx={{ maxWidth: "100vw", maxHeight: "450px" }} /></Box>}
            <BasicDataTable rows={baseProfileTableRows} sx={{ maxWidth: { md: "465px" }, mx: "auto" }} />
          </>
      }
      {loading.openCampaignData
        ? <CircularProgress />
        : openCampaignData.error
          ? <Alert severity="error">{`${openCampaignData.error}`}</Alert>
          : mainCampaignTableRows(openCampaignData, true).length > 0 &&
          <>
            <br />
            <BasicDataTable
              title="Campanha Aberta"
              paper={true}
              headers={mainCampaignTableHeaders}
              rows={mainCampaignTableRows(openCampaignData, true)}
              footers={mainCampaignTableFooter({
                mainCampaignData: openCampaignData,
                oldPoints: baseProfileData.pontuacaoHistoricaCompetidorAberta,
                pointConversion: pointConversionData["hydra:member"] && pointConversionData["hydra:member"][0]?.valorAberta
              })}
            />
          </>
      }
      {loading.amateurCampaignData
        ? <CircularProgress />
        : amateurCampaignData.error
          ? <Alert severity="error">{`${amateurCampaignData.error}`}</Alert>
          : mainCampaignTableRows(amateurCampaignData, false).length > 0 &&
          <>
            <br />
            <BasicDataTable
              title="Campanha Amador"
              paper={true}
              headers={mainCampaignTableHeaders}
              rows={mainCampaignTableRows(amateurCampaignData, false)}
              footers={mainCampaignTableFooter({
                mainCampaignData: amateurCampaignData,
                oldPoints: baseProfileData.pontuacaoHistoricaCompetidorAmador,
                pointConversion: pointConversionData["hydra:member"] && pointConversionData["hydra:member"][0]?.valorAmador
              })}
            />
          </>
      }
      {loading.openAffiliateCampaignData
        ? <CircularProgress />
        : openAffiliateCampaignData.error
          ? <Alert severity="error">{`${openAffiliateCampaignData.error}`}</Alert>
          : affiliateCampaignTableRows(openAffiliateCampaignData).length > 0 &&
          <>
            <br />
            <BasicDataTable
              title="Campeonatos Premiados (Aberta)"
              paper={true}
              headers={affiliateCampaignTableHeaders}
              rows={affiliateCampaignTableRows(openAffiliateCampaignData)}
              footers={affiliateCampaignTableFooter(openAffiliateCampaignData)}
            />
          </>
      }
      {loading.amateurAffiliateCampaignData
        ? <CircularProgress />
        : amateurAffiliateCampaignData.error
          ? <Alert severity="error">{`${amateurAffiliateCampaignData.error}`}</Alert>
          : affiliateCampaignTableRows(amateurAffiliateCampaignData).length > 0 &&
          <>
            <br />
            <BasicDataTable
              title="Campeonatos Premiados (Amador)"
              paper={true}
              headers={affiliateCampaignTableHeaders}
              rows={affiliateCampaignTableRows(amateurAffiliateCampaignData)}
              footers={affiliateCampaignTableFooter(amateurAffiliateCampaignData)}
            />
          </>
      }
      {loading.videoCampaignData
        ? <CircularProgress />
        : videoCampaignData.error
          ? <Alert severity="error">{`${videoCampaignData.error}`}</Alert>
          : videoCampaignTableRows(videoCampaignData).length > 0 &&
          <>
            <br />
            <BasicDataTable
              title="Vídeos"
              paper={true}
              headers={videoCampaignTableHeaders}
              rows={videoCampaignTableRows(videoCampaignData)}
            />
          </>
      }
      {loading.offspringEarningsCampaignData
        ? <CircularProgress />
        : offspringEarningsCampaignData.error
          ? <Alert severity="error">{`${offspringEarningsCampaignData.error}`}</Alert>
          : offspringEarningsCampaignTableRows(offspringEarningsCampaignData).length > 0 &&
          <>
            <br />
            <BasicDataTable
              title="Produção"
              paper={true}
              headers={offspringEarningsCampaignTableHeaders}
              rows={offspringEarningsCampaignTableRows(offspringEarningsCampaignData)}
              footers={offspringEarningsCampaignTableFooter(offspringEarningsCampaignData)}
            />
          </>
      }
      <VideoDialog open={modalOpen} setOpen={setModalOpen} title={modalTitle} videoLink={modalVideoLink} />
    </>

  );
}