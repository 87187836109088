import React from 'react'
import Grid from '@mui/material/Grid'
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from '@mui/material';
import Typography from '@mui/material/Typography'
import { Helmet } from "react-helmet-async";
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import {
  LINKS_REGULAMENTO_DEFAULT_DATA,
} from '../../default-data';

const RulebookLinks = (props) => {
  const { helmetContext } = props;

  return (
    <>
      <Helmet>
        <title>{`Livro de Regras | ${helmetContext.title}`}</title>
        <meta name="description" content="Livro de Regras" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, rulebook, livro de regras" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        alignItems="stretch"
        spacing={3}
        sx={{ mt: 1, mb: 0, }}
      >

        <Grid item xs={12}>
          <Typography component='h1' variant='h1'>Regulamento</Typography>
          <Typography component='p'>
            As regras de competição da modalidade de Rédeas.
          </Typography>


        </Grid>

        {LINKS_REGULAMENTO_DEFAULT_DATA.map((link, index) =>
          <Grid key={index} item xs={6} md={3}>
            <Card>
              <CardActionArea onClick={() => link.url ? window.open(link.url, "_blank", 'noopener') : null} disabled={!link.url}>
                <CardMedia
                  component="img"
                  sx={{
                    height: 'auto',
                    width: '100%',
                    margin: "0 auto"
                  }}
                  alt={link.titulo}
                  src={link.imagemUrl}
                />
                <CardContent>
                  <Typography component='p' align='center'>
                    {link.titulo}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        )}
      </Grid>


    </>
  );

}

export default RulebookLinks;