import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from "react-responsive-carousel";
import "../../styles/carousel.css"
import {
  DESTAQUES_DEFAULT_DATA,
} from "../../default-data";
import {
  VIDEO_INSTITUCIONAL_DO_YOUTUBE,
} from '../../constants';
import {
  redirectRelativeImageLinks,
  extractDestaqueSource,
} from '../../functions';
import { useGetData } from "../../axiosHook/useRequestData";

const extractDestaques = (data) => {
  if (!data) return [];
  let dataToProcess = data["hydra:member"] ? data["hydra:member"] : data;
  return dataToProcess.map(destaque => extractDestaqueSource(destaque));
  // include test destaques after whatever comes from the API
  // return [...dataToProcess.map(destaque => extractDestaqueSource(destaque)), ...DESTAQUES_TEST_DATA];
};

const HomeCarousel = () => {

  const [data = DESTAQUES_DEFAULT_DATA] = useGetData('/destaques?page=1');
  const destaques = extractDestaques(data);

  return (
    <>
      {(destaques.length > 0) ?

        <Carousel showThumbs={false} autoPlay={false} width='99.5%' showStatus={false} infiniteLoop={true} dynamicHeight={false}>

          {destaques.map((slide, index) =>
            slide.tipo === 'img' ?
              <div key={index}>
                <img src={redirectRelativeImageLinks(slide.src)} alt={slide.titulo} />

                {!slide.titulo === '' ? <p className='legend'>{slide.titulo}</p> : ''}

              </div>
              :
              slide.tipo === 'iframe' ?
                <div key={index}>
                  <iframe src={slide.src} title={slide.titulo} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  {/* evite colocar tag de titulo, pois é um player de vídeo*/}
                </div>
                :
                <div key={index}>
                  <div className='sliderHtml'>

                    <div className='innerHtml' dangerouslySetInnerHTML={{ __html: redirectRelativeImageLinks(slide.html) }}></div>

                  </div>
                  {/* evite colocar tag de titulo, inserir informações no HTML*/}
                </div>
          )}
        </Carousel>
        :
        <div className='videoInstitucional'>
          <iframe src={`https://www.youtube.com/embed/${VIDEO_INSTITUCIONAL_DO_YOUTUBE}`} title="Institucional" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </div>

      }
    </>
  );
}

export default HomeCarousel;