import React from 'react';
import RankingOptionsTable from './RankingOptionsTable';
import {
  RANKING_SEASONS_DEFAULT_DATA,
} from "../../default-data";
import { useGetData } from "../../axiosHook/useRequestData";
import {
  temporadaAtual,
} from "../../functions";
import { Alert } from '@mui/material';
import { CircularProgress } from '@mui/material';

const title1 = 'Ranking Geral';
const rows1 = [
  {
    name: 'Competidor',
    parametro: 'competidor=1',
    geral: true,
    aberta: true,
    amador: true,
  }, {
    name: 'Animal',
    parametro: 'animal=1',
    geral: true,
    aberta: true,
    amador: true,
  }, {
    name: 'Criador',
    parametro: 'criador=1',
    geral: true,
    aberta: false,
    amador: false,
  }, {
    name: 'Reprodutor',
    parametro: 'pai=1',
    geral: true,
    aberta: false,
    amador: false,
  }, {
    name: 'Reprodutora',
    parametro: 'mae=1',
    geral: true,
    aberta: false,
    amador: false,
  }
];

export default function RankingOverallSelector() {

  const [data = RANKING_SEASONS_DEFAULT_DATA, loading, { error }] = useGetData(`eventos/temporadas_ranking`);
  const seasons = data["hydra:member"] ? data["hydra:member"] : data;

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  const ultimaTemporada = Array.isArray(seasons) ? seasons[seasons.length - 1].temporada : temporadaAtual();
  const title2 = `Ranking Acumulado - Fim do Ano Hípico ${temporadaAtual() - 1}/${temporadaAtual()}`;
  const rows2 = [
    {
      name: 'Competidor',
      parametro: `temporada=${temporadaAtual() - 1}&competidor=1`,
      geral: false,
      aberta: true,
      amador: true,
    }
  ];

  const title3 = `Ranking dos Últimos 2 Anos Hípicos ${ultimaTemporada - 1}/${ultimaTemporada} e ${ultimaTemporada}/${ultimaTemporada + 1}`;
  const rows3 = [
    {
      name: 'Competidor',
      parametro: `temporada=${ultimaTemporada}&mintemporada=${ultimaTemporada - 1}&competidor=1`,
      geral: false,
      aberta: true,
      amador: true,
    }
  ];

  const dados = [
    {
      title: title1,
      rows: rows1,
    }, {
      title: title2,
      rows: rows2,
    }, {
      title: title3,
      rows: rows3,
    }
  ];

  return (
    <>
      {dados.map(dado =>
        <RankingOptionsTable key={dado.title} title={dado.title} rows={dado.rows} acumulado={true} />
      )}
    </>
  );
}