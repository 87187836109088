import React, { useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Divider from "@mui/material/Divider";
import { Helmet } from "react-helmet-async";
import { CircularProgress, LinearProgress, Stack } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  NOMINACOES_ITENS_POR_PAGINA,
} from "../../constants";
import {
  formatDateStringWithSlashes,
  formatarDadosNominacao
} from "../../functions";
import {
  // NOMINACAO_POTRO_TEST_DATA as NOMINACAO
} from "../../test-data";
import { useGetData } from "../../axiosHook/useRequestData";
import Pagination from '@mui/material/Pagination';
import PaginationItem from "@mui/material/PaginationItem";
import SearchField from "../SearchField";
import { Alert, Button } from '@mui/material';
import _ from "lodash";
import ScrollToTopOnMount from "../ScrollToTopOnMount";

const headerCellStyles = {
  fontWeight: "bold",
};

const cellSpacingStyles = {
  py: 1,
  px: {
    xs: 0.7,
    sm: 1,
    md: 4,
  },
};
const cellFontStyles = {
  fontSize: {
    xs: '0.8rem',
    sm: '0.9rem',
    md: '1.0em',
  },
};

const iconFontSizeStyles = {
  fontSize: {
    xs: "0.9rem",
    sm: "1.0rem",
    md: '1.2em',
  },
};

const collapsibleDataHeaderStyle = {
  fontWeight: "bold",
};


/* Monta Tabela */



const columns = [
  {
    id: 'numero',
    label: 'No.',
    minWidth: '5%'
  },
  {
    id: 'nome',
    label: 'Nome',
    minWidth: 'auto',
    align: 'left',

  },
  {
    id: 'filiacao',
    label: 'Filiação',
    minWidth: 'auto',
    align: 'left',
    display: {
      sm: 'table-cell',
      xs: 'none',
    },
  },
  {
    id: 'nascimento',
    label: 'Nascimento',
    minWidth: 'auto',
    align: 'center',
    display: {
      lg: 'table-cell',
      xs: 'none',
    },
    function: formatDateStringWithSlashes,
  },
  {
    id: 'sexo',
    label: 'Sexo',
    minWidth: 'auto',
    align: 'center',
    display: {
      xl: 'table-cell',
      xs: 'none',
    },
  },
  {
    id: 'nominador',
    label: 'Nominador',
    minWidth: 'auto',
    align: 'left',
  },

];


/** Função para criar a linha da tabela como React Fragment */
function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>

      <TableRow
        tabIndex={-1}
        key={row.no}
        sx={{
          '&:nth-of-type(4n+1)': {
            backgroundColor: 'action.hover',
          },
        }}
      >
        {columns.map((column) => {
          return (
            <TableCell key={column.id} align={column.align} sx={_.assign({}, cellFontStyles, cellSpacingStyles, { display: { ...column.display } })}>
              {column.function ? column.function(row[column.id]) : row[column.id]}
            </TableCell>
          );
        })}

        <TableCell sx={cellSpacingStyles}>
          <IconButton aria-label="Expandir" size="small" onClick={() => setOpen(!open)} key={row.no}>
            {open ? <KeyboardArrowUpIcon sx={iconFontSizeStyles} /> : <KeyboardArrowDownIcon sx={iconFontSizeStyles} />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow style={{ backgroundColor: "#F9F9F9" }}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={cellSpacingStyles}>
              <Grid
                container
                direction="row"
                justifyContent="space-evenly"
                alignItems="flex-start"
              >
                <Grid item xs={12} sx={_.assign({}, cellFontStyles, { display: { xs: 'block', sm: 'none' } })}>
                  <Box sx={collapsibleDataHeaderStyle}>Filiação:</Box> {row.filiacao}
                </Grid>

                <Grid item xs={12} sm={3} sx={_.assign({}, cellFontStyles, { display: { xs: 'block', lg: 'none' } })}>
                  <Box sx={collapsibleDataHeaderStyle}>Nascimento:</Box> {formatDateStringWithSlashes(row.nascimento)}
                </Grid>

                <Grid item xs={12} sm={3} lg={4} sx={cellFontStyles}>
                  <Box sx={collapsibleDataHeaderStyle}>Raça:</Box> {row.raca}
                </Grid>

                <Grid item xs={12} sm={3} lg={4} sx={_.assign({}, cellFontStyles, { display: { xs: 'block', xl: 'none' } })}>
                  <Box sx={collapsibleDataHeaderStyle}>Sexo:</Box> {row.sexo}
                </Grid>

                <Grid item xs={12} sm={3} lg={4} sx={cellFontStyles}>
                  <Box sx={collapsibleDataHeaderStyle}>Ano nominado:</Box> {row.anoNominacao}
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

    </React.Fragment>
  )
}

function setParams({ pesquisa, page }) {
  const searchParams = new URLSearchParams();
  searchParams.set("search", pesquisa || "");
  if (page) {
    searchParams.set("page", page);
  }
  return searchParams.toString();
}

const NominationList = (props) => {
  const { helmetContext } = props;

  let location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const page = parseInt(params.get('page') ?? 1); // Default to page 1
  const ultimaPesquisa = params.get('search') ?? "";

  const [campoPesquisa, setCampoPesquisa] = useState(ultimaPesquisa ?? "");

  const [listaNominacoes = [], updating, { error }] = useGetData({ url: `/nominacao_potros`, params: { page: page, itemsPerPage: NOMINACOES_ITENS_POR_PAGINA, pago: true, search: ultimaPesquisa } });

  if (error) return <Alert severity="error">{`${error}`}</Alert>

  const totalItens = listaNominacoes["hydra:totalItems"]
  const totalPaginas = Math.ceil(totalItens / NOMINACOES_ITENS_POR_PAGINA);
  const nominacoes = listaNominacoes["hydra:member"] ? listaNominacoes["hydra:member"].map(dados => formatarDadosNominacao(dados)) : [];

  const getNextUrl = (pesquisa, page) => `/nominacoes?${setParams({ pesquisa: pesquisa, page: page })}`;

  return (
    <>
      <Helmet>
        <title>{`Nominações | ${helmetContext.title}`}</title>
        <meta name="description" content="Confira todas as Nominações na ANCR" />
        <meta name="keywords" content="Nominações, Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas" />
      </Helmet>
      <ScrollToTopOnMount />

      <Grid container direction="row" alignItems="stretch" spacing={5} sx={{ pt: 2, mb: 0 }}>
        <Grid item md={7}>
          <Typography variant="h2">
            Nominações
          </Typography>
        </Grid>
        <Grid item md={5}><Stack direction="row" justifyContent="flex-end"
          alignItems="flex-start"
          divider={<Divider orientation="vertical" flexItem />}
          spacing={2}><Button variant="contained" href="/formularionominacao">Faça sua nominação</Button><Button variant="outlined" href="/normasnominacao">Normas da nominação</Button></Stack></Grid>
        <Grid item md={12}>
          <Grid
            container
            direction="row"
            justifyContent="space-evenly"
            alignItems="flex-start"
          >

            <Grid item xs={12} sm={6}>
              <Typography component="p" sx={{ pt: 2, mb: 0 }}>
                Confira as nominações recentes.
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <SearchField searchField={campoPesquisa} setSearchField={setCampoPesquisa} getNextUrl={getNextUrl} />
            </Grid>
          </Grid>



          <Divider sx={{ pt: 2, mb: 0 }} />
          {

            (nominacoes.length > 0) ?
              <Paper>
                <TableContainer>
                  <Table stickyHeader aria-label="data table" sx={{ width: "100%" }}>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                            sx={_.assign({}, headerCellStyles, cellFontStyles, cellSpacingStyles, { display: { ...column.display } })}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                        <TableCell />

                      </TableRow>
                    </TableHead>


                    <TableBody>
                      {
                        nominacoes.map((row, i) => {
                          return (
                            <Row key={row.numero} row={row} />
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <LinearProgress className={
                  updating
                    ? ""
                    : "hidden"
                } />
                <Box sx={{
                  pt: 2,
                  textAlign: "center",
                }}
                >
                  <Typography component="p" sx={{ pt: 2, mb: 0 }}>
                    {
                      `Você está visualizando as nominações de ${page * NOMINACOES_ITENS_POR_PAGINA - (NOMINACOES_ITENS_POR_PAGINA - 1)} até ${page * NOMINACOES_ITENS_POR_PAGINA - (
                        NOMINACOES_ITENS_POR_PAGINA - nominacoes?.length)} em um total de ${totalItens}.`
                    }
                  </Typography>
                  <Pagination
                    sx={{
                      pt: 1,
                      pb: 2,
                      justifyContent: "center",
                      display: 'flex',
                      my: 0,
                      mx: "auto",
                    }}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    count={totalPaginas}
                    renderItem={item => (
                      <PaginationItem
                        component={RouterLink}
                        to={getNextUrl(ultimaPesquisa, item.page)}
                        {...item}
                      />
                    )}
                  />
                </Box>
              </Paper>
              : listaNominacoes['hydra:totalItems'] === 0 && ultimaPesquisa !== ""
                ? <Typography component="p" sx={{ pt: 2, mb: 0 }}>
                  Nenhuma nominação localizada. Tente outra pesquisa.
                </Typography>
                : <CircularProgress />
          }

        </Grid>
      </Grid>
    </>);
}

export default NominationList;