import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Link,
  Divider,
  Button,
  Alert,
  Card,
  CardMedia,
  Grid,
  Box
} from '@mui/material';
import {
  formatDateStringWithSlashes,
  getAttrFromString,
  redirectRelativeImageLinks,
} from "../../functions";
import {
  NEWS_ITENS_NA_LISTA_A_DIREITA,
} from '../../constants';
import { useGetData } from "../../axiosHook/useRequestData";

import DefaultThumb from "../../images/newsDefaultThumb.jpg";

function findThumb(text) {
  const listOfImages = getAttrFromString(text, "img", "src");
  const thumbUrl =
    listOfImages.length > 0
      ? redirectRelativeImageLinks(listOfImages[0])
      : DefaultThumb;

  return thumbUrl;
}

const NewsSideList = (props) => {
  const { id } = props;

  let navigate = useNavigate();

  //listar noticias
  const [data = [], loading, { error }] = useGetData({ url: `/noticias`, params: { page: 1, itemsPerPage: NEWS_ITENS_NA_LISTA_A_DIREITA } });

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-evenly"
        alignItems="flex-start"
      >
        {data['hydra:member']?.map((article, index) => {
          return (id !== article.id)
            ? <React.Fragment key={index}>
              <Grid item xs={5} md={12}>
                <Link href={`/noticias/${article.id}`}>
                  <Card
                    key={index}
                    sx={{
                      width: {
                        xs: "80%",
                        md: "100%",
                      },
                      aspectRatio: "4/3",
                      mt: '5%',
                      mb: "5%"
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: "100%",
                        aspectRatio: "4/3",
                      }}
                      component="img"
                      alt={article?.title}
                      image={findThumb(article?.texto)}
                      title={article?.title}
                    />
                  </Card>
                </Link>
              </Grid>
              <Grid item xs={7} md={12}>
                <Link href={`/noticias/${article.id}`}>
                  <Typography
                    variant="h3"
                    sx={{
                      pt: {
                        xs: 0.75,
                        sm: 2,
                      },
                      pb: {
                        xs: 0.75,
                        sm: 1,
                      }
                    }}
                  >
                    {article.titulo}
                  </Typography>
                  <Box
                    component="span"
                    sx={{
                      fontSize: "0.75em",
                      color: "#b5b5b5",
                    }}
                  >
                    {formatDateStringWithSlashes(article.dataNoticia)}
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "block",
                      },
                    }}
                    textAlign="justify"
                  >
                    <Box component="span">{article.chamada}<br /></Box>
                  </Typography>
                </Link>
              </Grid>
              <Grid item xs={12}>
                <Divider variant="middle" />
              </Grid>
            </React.Fragment>
            : null;
        }
        )}
      </Grid>
      <Button variant="contained" color="secondary" fullWidth sx={{ mt: 1 }} onClick={() => navigate('/noticias/')}>+ notícias</Button>
    </>
  )
}

export default NewsSideList;