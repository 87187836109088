
import React from 'react'
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import SvgIcon from '@mui/material/SvgIcon';
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded';
import EmojiEventsRoundedIcon from '@mui/icons-material/EmojiEventsRounded';
import AttachMoneyRoundedIcon from '@mui/icons-material/AttachMoneyRounded';
import FormatListNumberedRoundedIcon from '@mui/icons-material/FormatListNumberedRounded';
import EventRoundedIcon from '@mui/icons-material/EventRounded';
import BedroomBabyRoundedIcon from '@mui/icons-material/BedroomBabyRounded';
import ImportContactsRoundedIcon from '@mui/icons-material/ImportContactsRounded';
import LiveTvRoundedIcon from '@mui/icons-material/LiveTvRounded';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';

const styles = {
  mainButton: {
    width: {
      xs: '4rem', md: "5.5rem"

    },
    height: {
      xs: '4rem', md: "5.5rem"

    },
    background: "radial-gradient(circle, rgba(41,84,170,0.8) 0%, rgba(36, 97, 142,1) 85%);",
    color: "#fff",
    flexDirection: 'column',
    textTransform: "capitalize",
    borderRadius: "100%", aspectRatio: "1",
    mb: 0.5,
    mr: 0,
    lineHeight: "1",
    fontFamily: "Foco Regular",
    border: "5px double #fff !important",
    scrollSnapAlign: 'center',

    "&:hover": {
      background: "radial-gradient(circle, rgba(36, 97, 142,0.3) 0%, rgba(36, 97, 142,0.5) 85%);",
      color: "rgba(36, 97, 142,1)",
      borderColor: "rgba(36, 97, 142,0.8) !important"
    },

  },
}

const CustomButton = (props) => (
  <Box alignItems={'center'} >
    <Button
      {...props}
      variant="outlined"
      sx={styles.mainButton}
    >
      <SvgIcon
        component={props.icon}
        fontSize="large"
        sx={{ mb: { xs: 0, md: 1 }, fontSize: { xs: "2", md: "3.4em" }, mt: { xs: 0.25, md: 1 } }}
      />

    </Button>
    <Typography variant="h3" component="p" textAlign={'center'} sx={{ pt: 0, fontFamily: "Foco  Light", fontSize: { xs: "0.7em", md: "inherit" } }}  >{props.label}</Typography>
  </Box>
)

const mainButtonList = [
  {

    label: "Sócios",
    icon: PermContactCalendarRoundedIcon,
    url: "/login",
  }, {

    label: "Resultados",
    icon: EmojiEventsRoundedIcon,
    url: "/eventos?futuros=false&resultados=true&ancr=true&nucleos=true&order=desc",
  }, {

    label: "Campanhas",
    icon: AttachMoneyRoundedIcon,
    url: "/campanhas?animais=1",
  }, {

    label: "Ranking",
    icon: FormatListNumberedRoundedIcon,
    url: "/ranking",
  }, {

    label: "Calendário",
    icon: EventRoundedIcon,
    url: "/eventos?futuros=true&resultados=false&ancr=true&nucleos=true&order=asc",
  }, {

    label: "Nominações",
    icon: BedroomBabyRoundedIcon,
    url: "/nominacoes",
  }, {

    label: "Revista",
    icon: ImportContactsRoundedIcon,
    url: "/revistaredeas",
    external: false,
  }, {

    label: "Mundo Rédeas",
    shortLabel: "M. Rédeas",
    icon: LiveTvRoundedIcon,
    url: "/programamundoredeas",
    external: false,
  },
];

const MainButtons = () => {
  const navigate = useNavigate();

  const handleClick = (button) => {
    if (button.external) {
      window.open(button.url);
    } else {
      navigate(button.url);
    }
  };



  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        sx={{ display: { xs: 'none', md: 'flex' } }}
      >
        {mainButtonList.map(button =>
          <Grid item key={button.label} xs={6} sx={{ textAlign: 'center' }}><CustomButton label={button.label} icon={button.icon} onClick={() => handleClick(button)} /></Grid>
        )}
      </Grid>


      {/** Versão Mobile */

        ((window.location.pathname) === "/") ?
          <Box
            component="ul"
            sx={{
              display: {
                xs: 'grid',
                md: 'none',
              },
              listStyle: 'none',
              overflowX: 'scroll',
              scrollSnapType: 'x proximity',
              gridGap: {
                xs: '5px',
                sm: '4px',
              },
              gridTemplateColumns: '65px repeat(7, 65px) 0px',
              gridTemplateRows: 'minmax(0px, 1fr)',
              p: 0,
              pb: '5px',
              mb: '-5px',
              pl: {
                xs: 2,
                sm: 0,
              },
              mt: 0,
              margin: { sm: "0 10%" }

            }}
          >
            {mainButtonList.map(button =>
              <li key={button.label} >
                <CustomButton icon={button.icon} label={button.shortLabel ? button.shortLabel : button.label} onClick={() => handleClick(button)} />
              </li>
            )}
          </Box>
          : ""}
    </>
  );

}

export default MainButtons;

