import { Button, Typography } from '@mui/material'
import React from 'react'
import { Link as RouterLink } from "react-router-dom";
import {
  nivelCompetidorEmTexto,
} from "../../functions";
import {
  NIVEIS,
} from "../../constants"

export default function LevelLinks(props) {
  const { abertaAtual, nivelAtual } = props;
  const opcoesAberta = [1, 0];

  return (
    <div>
      {opcoesAberta.map(aberta =>
        <React.Fragment key={aberta}>
          <Typography>{aberta ? "Aberta" : "Amador"}</Typography>
          {NIVEIS.map(nivel =>
            <Button color="primary" key={nivel} disabled={abertaAtual === aberta && nivelAtual === nivel} component={RouterLink} to={`/niveis?aberta=${aberta}&nivel=${nivel}`}>{nivelCompetidorEmTexto(nivel)}</Button>
          )}
        </React.Fragment>
      )}
    </div>
  )
}
