import React from 'react';
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import {
  Grid,
  CircularProgress,
  Alert,
} from '@mui/material';
import Link from '@mui/material/Link';
import { useGetData } from "../../axiosHook/useRequestData";
import {
  nivelCompetidorEmTexto,
} from "../../functions";
// import {
//   COMPETIDORES_ABERTA_NIVEL_4_TEST_DATA,
// } from "../test-data";
import LevelLinks from './LevelLinks';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import BasicDataTable from '../BasicDataTable';

const alignColumns = [
  'left',
  'center',
];

const tableHeaders = [
  `Nome`,
  `NRHA ID`,
];

export default function CompetitorLevelList(props) {
  const { helmetContext } = props;

  let location = useLocation();
  const search = location.search;
  const params = new URLSearchParams(search);
  const aberta = parseInt(params.get('aberta') ?? 1); // Default to Open/Aberta
  const nivel = parseInt(params.get('nivel') ?? 4); // Default to Level 4

  const [data = {}, loading, { error }] = useGetData(`/afiliados/${aberta ? 'nivel_aberta?nivelAberta' : 'nivel_amador?nivelAmador'}=${nivel}`);

  if (error) return <Alert severity="error">{`${error}`}</Alert>

  const title = `${aberta ? "Aberta" : "Amador"} ${nivelCompetidorEmTexto(nivel)}`;

  const tableRows = () => {
    if (!data["hydra:member"]) return [];

    return data["hydra:member"].map(competidor => {
      let row = [];
      row.push(<Link href={`/competidores/${competidor.id}`} color="primary">{competidor.nome}</Link>);
      row.push(
        competidor.numeroNrha
          ? <Link href={`/competidores/${competidor.id}`} color="primary">{competidor.numeroNrha}</Link>
          : ""
      );
      return row;
    });
  };


  return (
    <>
      <Helmet>
        <title>{`${title} | ${helmetContext.title}`}</title>
        <meta name="description" content="Níveis de competição" />
        <meta name="keywords" content="" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={5}
        sx={{ pt: 1, mb: 0 }}
      >
        <Grid item key="main" md={7}>
          {loading
            ? <CircularProgress />
            : <BasicDataTable
              title={title}
              paper={true}
              headers={tableHeaders}
              rows={tableRows()}
              alignColumns={alignColumns}
              striped
            />
          }
        </Grid>
        <Grid item key="links" md={3} >

          <LevelLinks abertaAtual={aberta} nivelAtual={nivel} />

        </Grid>
      </Grid>
    </>
  )

}