import React from 'react';
import {
  urlImagemCampanha,
} from '../../functions';
import { useGetData } from "../../axiosHook/useRequestData";
import { Alert, Avatar, IconButton, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const AvatarAssociado = (props) => {
  const { session, clear } = props;

  const [baseProfileData, loading, { error }] = useGetData({
    url: `/afiliados/${session.id}`,
    headers: {
      'Authorization': `Bearer ${session.token}`
    },
  });

  const avatarSize = {
    height: 80,
    width: 80,
  };

  if (error) return (
    <Alert
      severity="error"
      action={error?.response?.status === 401 ?
        <IconButton
          aria-label="fechar"
          color="inherit"
          size="small"
          onClick={clear}
        >
          <CloseIcon fontSize="inherit" />
        </IconButton>
        : null
      }
    >
      {`${error?.response?.status === 401 ? 'Seu login expirou.' : error}`}
    </Alert>
  );

  return (
    <>

      {session
        ? loading
          ? <Skeleton variant="circular" width={avatarSize.width} height={avatarSize.height} />
          : <Avatar
            alt={session.nome}
            src={urlImagemCampanha(baseProfileData, false)}
            sx={{ width: avatarSize.width, height: avatarSize.height }}
          />
        : (
          <>
            Sem imagem
          </>
        )
      }
    </>
  );
};

export default AvatarAssociado;