import React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import {
  Alert,
  Box,
  CardMedia,
  CircularProgress,
  IconButton,
  CardActionArea,
} from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { Helmet } from "react-helmet-async";
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import LogoHallOfFame from '../../images/Hall_da_Fama_2021.png';
import Moldura from "../../images/borda.png";
// import Photo from '../../images/person.jpg'
import { useGetData } from "../../axiosHook/useRequestData";
import {
  // HALL_DA_FAMA_TEST_DATA,
} from '../../test-data';
import ResponsivePlayer from '../ResponsivePlayer';
import {
  YOUTUBE_VIDEO_ADDRESS_ROOT,
} from '../../constants';
import { useUrlSearchParams } from "use-url-search-params";
import Logo from "../../images/newsDefaultThumb.jpg";

const classes = {
  yearTitle: {
    fontSize: "2rem",
    fontWeight: "bold",
    textAlign: "center",
    color: "#bb980f",
    mt: 1,
    mb: 3
  },

  paragraph: {
    color: "#FFF",
    textAlign: 'center'
  },

  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 850,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    pb: 12
  }
}

const initial = {
  ano: NaN,
};

const types = {
  ano: Number,
};

const VideoArea = (props) => {
  const { modalPremiado } = props;
  // console.log("videoArea:", modalPremiado);

  // give preference to YouTube videos, if available
  if (modalPremiado.codigoYouTube !== null) return (
    <CardMedia
      component='iframe'
      title={modalPremiado.nome}
      src={YOUTUBE_VIDEO_ADDRESS_ROOT + modalPremiado.codigoYouTube}
      muted=''
      autoPlay={true}
      allowFullScreen={true}
      sx={{
        width: "100%",
        height: "100%",
        // minHeight: "50vh",
      }}
    />
  );

  if (modalPremiado['urlFacebookVideo'] !== null) return (
    <ResponsivePlayer
      url={modalPremiado['urlFacebookVideo']}
      controls
    />
  );

  return null;
};

export default function HallOfFame(props) {
  const { helmetContext } = props;
  const [open, setOpen] = React.useState(false);
  const [modalPremiado, setModalPremiado] = React.useState({});
  const handleOpen = (premiado) => {
    setOpen(true);
    setModalPremiado(premiado);
  };
  const handleClose = () => setOpen(false);

  const [params, setParams] = useUrlSearchParams(initial, types);

  const [data = {}, loading, { error }] = useGetData('/hall_da_famas');

  if (error) return <Alert severity="error">{`${error}`}</Alert>
  if (loading) return <CircularProgress />


  const premiados = data["hydra:member"] ?? [];
  const anos = [...new Set(premiados.map(premiado => premiado.ano))];

  return (
    <>
      <Helmet>
        <title>{`Hall da Fama ANCR | ${helmetContext.title}`}</title>
        <meta name="description" content="Hall da Fama ANCR" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas, contato, fale com, telefone, fale com a gente" />
      </Helmet>
      <ScrollToTopOnMount params={params.ano} />
      <div style={{ width: { xs: '100%' }, backgroundColor: "#000", padding: "3%", borderRadius: "2%" }}>
        <Grid
          container
          direction="row"
          alignItems="stretch"
          spacing={5}
          sx={{ mt: 1, mb: 0, color: "#fff !important", borderRadius: "2%", }}
        >
          <Grid item xs={12} sx={{ pb: 4 }}>
            <Typography component="h2" align='center'>
              <Box
                component="img"
                sx={{
                  height: 'auto',
                  width: { xs: '85%', md: "40%" },
                  margin: "0 auto"
                }}
                alt="Hall da Fama"
                src={LogoHallOfFame}
              />
            </Typography>
            <Typography component='p' sx={classes.paragraph}>
              As pessoas homenageados aqui contribuiram incansavelmente de uma maneira excepional para o conhecimento e crescimento de nossa Associação e os animais reconhecidos foram grandes destaques na evolução genética e nas competições da modalidade.
            </Typography>

          </Grid>
          {
            params.ano
              ? <React.Fragment key={params.ano}>
                <Grid item xs={12} sx={{ pb: 4 }}>
                  <Typography component='h2' element='h2' sx={classes.yearTitle}>Premiados em {params.ano}</Typography>
                </Grid>
                {
                  premiados.filter(premiado => premiado.ano === params.ano).map(premiado =>
                    <Grid key={premiado.id} item xs={6} md={3} sx={{ pb: 4 }}>
                      <Box
                        component="img"
                        sx={{
                          height: 'auto',
                          width: '95%',
                          margin: "0 auto",
                          borderColor: "#FFF",
                          borderWidth: "0.5rem",
                          borderStyle: 'solid',
                          cursor: "pointer"
                        }}
                        alt={premiado.nome}
                        src={premiado.imagemUrl ?? Logo}
                        onClick={() => handleOpen(premiado)}
                      />
                      <Typography component='p' sx={classes.paragraph}>{premiado.nome}</Typography>
                    </Grid>
                  )
                }
              </React.Fragment>
              : null
          }
          {
            anos.map(year =>
              <Grid key={year} item xs={6} md={3}>
                <CardActionArea
                  onClick={() => setParams({ ano: year })}
                  sx={{ mb: 0 }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      aspectRatio: "5/4",
                      height: 'auto',
                      backgroundImage: `url(${Moldura})`,
                      backgroundSize: 'cover',
                      pt: '25%'
                    }}
                  >
                    <Typography component='h2' element='h1' sx={classes.yearTitle}>{year}</Typography>
                  </Box>
                </CardActionArea>
              </Grid>
            )
          }
        </Grid>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box sx={classes.modal}>
            <Grid
              container
              direction="row"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item xs={10}>
                <Typography id="transition-modal-title" variant="h6" component="h2">
                  {modalPremiado.nome}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2}>
                <Typography component='p' textAlign={'right'}><IconButton aria-label="delete" onClick={handleClose}>
                  <CloseOutlinedIcon />
                </IconButton>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Box
                  component="img"
                  sx={{
                    height: 'auto',
                    width: '95%',
                    margin: "0 auto",
                  }}
                  alt={modalPremiado.nome}
                  src={modalPremiado.imagemUrl ?? Logo}
                />
              </Grid>
              <Grid item xs={12} sm={8}>

                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  {modalPremiado.descricao}
                </Typography>
                <VideoArea modalPremiado={modalPremiado} />
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>

    </>
  );
};