import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import {
  Alert,
  Box,
  CardActionArea,
  Grid,
  Skeleton,
  Divider,
} from '@mui/material';


const MiddleBanners = (props) => {
  const { bannersGrandes, loadingBannersGrandes, errorBannersGrandes } = props;

  let navigate = useNavigate();

  const handleClick = (data) => {
    if (!data) return;
    if (!data.linkTo) return;
    if (data.linkTo.startsWith('http')) {
      window.open(data.linkTo);
    }
    navigate(data.linkTo);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Divider light sx={{ width: '30%', margin: "0 auto" }} />
      </Grid>


      <Grid item xs={12}>
        {errorBannersGrandes
          ? <Alert severity='error'>{`${errorBannersGrandes}`}</Alert>
          : <Box sx={{
            width: '100%',
            aspectRatio: '8/1',
          }}>
            {loadingBannersGrandes
              ? <Skeleton variant='rectangular'
                sx={{ height: "100%", m: 0 }}
              />
              : <Card sx={{
                width: '100%',
                aspectRatio: '8/1',
                background: "#999"
              }}
              >
                <CardActionArea onClick={() => handleClick(bannersGrandes["hydra:member"][2])}>
                  <CardMedia
                    sx={{
                      width: '100%',
                      aspectRatio: '8/1'
                    }}
                    component="img"
                    alt={bannersGrandes["hydra:member"][2]?.descricao}
                    src={bannersGrandes["hydra:member"][2]?.urlImagem}
                  />
                </CardActionArea>
              </Card>
            }
          </Box>
        }
      </Grid>

    </Grid>
  )
};

export default MiddleBanners;