import React from 'react'
import { Divider, Grid } from '@mui/material';
import HomeCarousel from './HomeCarousel';
import Partners from './Partners';
import { Helmet } from 'react-helmet-async';
import ScrollToTopOnMount from '../ScrollToTopOnMount';
import MainButtons from './MainButtons'
import MiddleBanners from './MiddleBanners';
import FooterBanner from './FooterBanner';
import LatestInstagramPosts from './LatestInstagramPosts';
import { useGetData } from "../../axiosHook/useRequestData";
import HomeNews from './HomeNews';

const Home = (props) => {
  const { helmetContext } = props;

  const [bannersGrandes = {}, loadingBannersGrandes, { error: errorBannersGrandes }] = useGetData('/banners/grandes');

  return (
    <div>
      <Helmet>
        <title>{helmetContext.title}</title>
        <meta name="description" content="Associação que regulamenta e promove competições da modalidade de Rédeas" />
        <meta name="keywords" content="Associação, competições, Rédeas, cavalos, ANCR, Cavalo de rédeas" />
      </Helmet>
      <ScrollToTopOnMount />
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        spacing={4}
      >
        <Grid item xs={12} md={9} >
          <HomeCarousel />
        </Grid>
        <Grid item xs={12} md={3} sx={{
          display: {
            xl: 'block',
            lg: 'block',
            md: 'block',
            sm: 'none',
            xs: 'none',
          }
        }} >
          <MainButtons />
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" />
        </Grid>
        <HomeNews />
      </Grid>
      <MiddleBanners bannersGrandes={bannersGrandes} loadingBannersGrandes={loadingBannersGrandes} errorBannersGrandes={errorBannersGrandes} />
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: '85px' }}
      >

        <Partners />

        <LatestInstagramPosts />

      </Grid>
      <FooterBanner bannersGrandes={bannersGrandes} loadingBannersGrandes={loadingBannersGrandes} errorBannersGrandes={errorBannersGrandes} />
    </div >
  )
}

export default Home